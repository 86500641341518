import moment from "moment";
import { DatePicker, Input, Select, Checkbox, message } from "antd";
import Header from "components/header";
import React from "react";
import styled from "styled-components";
import OriginalSunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import * as api from "services/api";
import { STATUS } from "app/constants";
import { useHistory, useParams } from "react-router";
import { PATH } from "app/config";
import DeleteButton from "./deleteButton";
import Attachments from "./attachments";
import TextEditor from "components/new/textEditor";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Edit = ({ initialData }) => {
  const textEditorRef = React.useRef();
  const history = useHistory();
  const { forumID } = useParams();
  const [isNewArticle] = React.useState(forumID?.toLowerCase() === "new");
  const [title, setTitle] = React.useState(initialData.title);
  const [participants, setParticipants] = React.useState(
    initialData.participants
  );
  const [category, setCategory] = React.useState(initialData.category);
  const [isPinned, setIsPinned] = React.useState(initialData.isPinned);

  const [editorState, setEditorState] = React.useState(initialData.body);
  const [isSingleDate, setIsSingleDate] = React.useState(
    initialData.isSingleDate
  );

  // Single Date Selector:
  const [singleDate, setSingleDate] = React.useState(); // moment Object

  const [attachments, _setAttachments] = React.useState({
    첨부파일1: initialData.file1,
    첨부파일2: initialData.file2,
    첨부파일3: initialData.file3,
    첨부파일4: initialData.file4,
    첨부파일5: initialData.file5,
  });
  const setAttachments = (obj) =>
    _setAttachments((prev) => ({ ...prev, ...obj }));

  // Range Selector:
  const [_dates, _setDates] = React.useState([]);
  const [_hackValue, _setHackValue] = React.useState();
  const [rangeDate, setRangeDate] = React.useState(); // moment Objects
  const disabledDate = (current) => {
    if (!_dates || _dates.length === 0) {
      return false;
    }
    const tooEarly = _dates[1] && _dates[1].diff(current, "days") > 7;
    return tooEarly;
  };

  const onOpenChange = (open) => {
    if (open) {
      _setHackValue([]);
      _setDates([]);
    } else {
      _setHackValue(undefined);
    }
  };

  React.useEffect(() => {
    // 이벤트 날짜 데이터를  moment 형식으로 변경해서 저장합니다.
    if (initialData.isSingleDate) {
      setIsSingleDate(true);
      if (moment(initialData.singleDate).isValid()) {
        setSingleDate(moment(initialData.singleDate));
      }
    } else {
      setIsSingleDate(false);
      if (
        moment(initialData.rangeBeginDate).isValid() &&
        moment(initialData.rangeEndDate).isValid()
      ) {
        setRangeDate([
          ...[
            moment(initialData.rangeBeginDate),
            moment(initialData.rangeEndDate),
          ],
        ]);
      }
    }
  }, []);

  const onSave = () => {
    let dateData = {};
    if (isSingleDate) {
      dateData["isSingleDate"] = true;
      dateData["singleDate"] = singleDate?.format("YYYY-MM-DD HH:mmZ") || "";
    } else {
      dateData["isSingleDate"] = false;
      dateData["rangeBeginDate"] =
        rangeDate[0].format("YYYY-MM-DD HH:mmZ") || "";
      dateData["rangeEndDate"] = rangeDate[1].format("YYYY-MM-DD HH:mmZ") || "";
    }

    const finalData = {
      ...{
        title,
        isPinned,
        body: editorState,
        category,
        participants,
        file1: attachments["첨부파일1"],
        file2: attachments["첨부파일2"],
        file3: attachments["첨부파일3"],
        file4: attachments["첨부파일4"],
        file5: attachments["첨부파일5"],
        isLegacy: initialData.isLegacy,
      },
      ...dateData,
    };

    if (isNewArticle) {
      api.forum__create(finalData).then((response) => {
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 생성했습니다.");
          history.push(
            PATH.FORUM_ARTICLE.replace(
              ":category",
              response.data.category.toLowerCase()
            ).replace(":forumID", response.data.id)
          );
        } else {
          message.error("생성에 실패했습니다.");
        }
      });
    } else {
      api.forum__update({ forumID, ...finalData }).then((response) => {
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 수정했습니다.");
          history.push(
            PATH.FORUM_ARTICLE.replace(
              ":category",
              response.data.category.toLowerCase()
            ).replace(":forumID", response.data.id)
          );
        } else {
          message.error("데이터 수정에 실패했습니다.");
        }
      });
    }
  };

  return (
    <Container>
      <Header
        title={isNewArticle ? "새 아티클 작성" : "아티클 수정"}
        onSave={onSave}
        onSaveText={isNewArticle ? "생성" : "저장"}
      />
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="아티클 제목"
        maxLength={200}
        style={{ fontSize: "18px", padding: 8, marginBottom: 10 }}
      />
      <Input.Group compact style={{ marginBottom: 50 }}>
        <Select
          value={category}
          onChange={(value) => setCategory(value)}
          style={{ width: "200px" }}
        >
          <Option value="CONFERENCE">학회</Option>
          <Option value="SEMINAR">세미나</Option>
          <Option value="EXTERNAL">외부행사</Option>
          <Option value="ANNOUNCEMENT">새소식</Option>
        </Select>
        <Input
          maxLength={100}
          value={participants}
          onChange={(e) => setParticipants(e.target.value)}
          style={{ width: "240px", marginLeft: 20 }}
          placeholder="참가자"
        />
        {isSingleDate && (
          <>
            <DatePicker
              value={singleDate}
              onChange={(momentObject) => {
                setSingleDate(momentObject);
              }}
              style={{ marginLeft: 20 }}
              format="YYYY-MM-DD HH:mm"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              placeholder={"행사 일자 및 시각"}
            />
            <span
              onClick={() => {
                setIsSingleDate(false);
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                verticalAlign: "bottom",
                cursor: "pointer",
                color: "#1890ff",
              }}
            >
              복수 날짜 선택
            </span>
          </>
        )}
        {!isSingleDate && (
          <>
            <RangePicker
              style={{ marginLeft: 20 }}
              value={_hackValue || rangeDate}
              disabledDate={disabledDate}
              onCalendarChange={(val) => _setDates(val)}
              onChange={(val) => setRangeDate(val)}
              onOpenChange={onOpenChange}
              placeholder={["행사 시작 일자", "행사 종료 일자"]}
            />
            <span
              onClick={() => {
                setIsSingleDate(true);
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                verticalAlign: "bottom",
                cursor: "pointer",
                color: "#1890ff",
              }}
            >
              단일 날짜 선택
            </span>
          </>
        )}
        <Checkbox.Group
          style={{
            float: "right",
            userSelect: "none",
            marginTop: 10,
          }}
        >
          <label style={{ color: "#444", fontSize: 14 }}>
            <input
              type="checkbox"
              checked={isPinned}
              onChange={(e) => setIsPinned(e.target.checked)}
            />
            &nbsp;&nbsp;아티클 상단 고정
          </label>
        </Checkbox.Group>
      </Input.Group>
      <TextEditor editorRef={textEditorRef} initialValue={initialData.body} />
      {/* <OriginalSunEditor
        width="100%"
        height="500px"
        defaultValue={editorState}
        onChange={(content) => setEditorState(content)}
        setOptions={{
          buttonList: buttonList.complex,
          templates: [],
          callBackSave: onSave,
        }}
        setDefaultStyle="font-size:16px; line-height: 1.5"
      /> */}

      <UploadArea>
        <Attachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </UploadArea>
      {!isNewArticle && (
        <>
          <div style={{ height: 100 }} />
          <DeleteButton forumID={forumID} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 120px;
`;

const UploadArea = styled.div`
  margin-top: 100px;
`;

export default Edit;
