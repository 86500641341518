import React from "react";
import { LayoutForm2 } from "components/layout";
import Spinner from "components/spinner";
import * as api from "services/api";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import AdminMember from "components/adminMember";
import { useHistory, useParams } from "react-router";
import { STATUS } from "app/constants";
import { message } from "antd";
import useStore from "services/store";

const AdminMemberEditView = observer(() => {
  const history = useHistory();
  const { action } = useStore();
  const { memberID } = useParams();
  const [state, setState] = React.useState("PENDING");

  /** Variable Settings */
  const [image, setImage] = React.useState("");
  const [category, setCategory] = React.useState(null);
  const [priority, setPriority] = React.useState(0);
  const [koreanName, setKoreanName] = React.useState("");
  const [englishName, setEnglishName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [koreanFieldsKey, _setKoreanFieldsKey] = React.useState({});
  const setKoreanFieldsKey = (obj) =>
    _setKoreanFieldsKey((prevValue) => ({ ...prevValue, ...obj }));
  const [koreanFieldsValue, _setKoreanFieldsValue] = React.useState({});
  const setKoreanFieldsValue = (obj) =>
    _setKoreanFieldsValue((prevValue) => ({ ...prevValue, ...obj }));
  const [englishFieldsKey, _setEnglishFieldsKey] = React.useState({});
  const setEnglishFieldsKey = (obj) =>
    _setEnglishFieldsKey((prevValue) => ({ ...prevValue, ...obj }));
  const [englishFieldsValue, _setEnglishFieldsValue] = React.useState({});
  const setEnglishFieldsValue = (obj) =>
    _setEnglishFieldsValue((prevValue) => ({ ...prevValue, ...obj }));

  const [initialKoreanFields, setInitialKoreanFields] = React.useState(null);
  const [initialEnglishFields, setInitialEnglishFields] = React.useState(null);

  React.useEffect(() => {
    action
      .validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history)
      .then(() => {
        // CASE 1 : New Data
        if (memberID === "new") {
          setState("SUCCESS");
        } else {
          api.member__detail(memberID).then((response) => {
            switch (response.status) {
              case "SUCCESS":
                setImage(response.data.image);
                setCategory(response.data.category);
                setPriority(response.data.priority);
                setKoreanName(response.data.name_KOREAN);
                setEnglishName(response.data.name_ENGLISH);
                setEmail(response.data.email);

                const koreanFields = JSON.parse(response.data.fields_KOREAN);
                let _initialKoreanFields = [];
                let _koreanFieldsKey = {};
                let _koreanFieldsValue = {};
                Object.keys(koreanFields).forEach((item, idx) => {
                  _initialKoreanFields.push({
                    key: item,
                    value: koreanFields[item],
                  });
                  _koreanFieldsKey[idx] = item;
                  _koreanFieldsValue[idx] = koreanFields[item];
                });
                const englishFields = JSON.parse(response.data.fields_ENGLISH);
                let _initialEnglishFields = [];
                let _englishFieldsKey = {};
                let _englishFieldsValue = {};
                Object.keys(englishFields).forEach((item, idx) => {
                  _initialEnglishFields.push({
                    key: item,
                    value: englishFields[item],
                  });
                  _englishFieldsKey[idx] = item;
                  _englishFieldsValue[idx] = englishFields[item];
                });
                setKoreanFieldsKey(_koreanFieldsKey);
                setKoreanFieldsValue(_koreanFieldsValue);
                setInitialKoreanFields(_initialKoreanFields);
                setEnglishFieldsKey(_englishFieldsKey);
                setEnglishFieldsValue(_englishFieldsValue);
                setInitialEnglishFields(_initialEnglishFields);

                setState("SUCCESS");
                break;
              case "FAILURE":
                setState("ERROR");
                break;
            }
          });
        }
      });
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {state === "PENDING" ? <Spinner /> : null}
      {state === "SUCCESS" ? (
        <MemberComponent
          memberID={memberID === "new" ? null : memberID}
          image={image}
          setImage={setImage}
          category={category}
          setCategory={setCategory}
          koreanName={koreanName}
          setKoreanName={setKoreanName}
          englishName={englishName}
          setEnglishName={setEnglishName}
          priority={priority}
          setPriority={setPriority}
          email={email}
          setEmail={setEmail}
          koreanFieldsKey={koreanFieldsKey}
          setKoreanFieldsKey={setKoreanFieldsKey}
          koreanFieldsValue={koreanFieldsValue}
          setKoreanFieldsValue={setKoreanFieldsValue}
          englishFieldsKey={englishFieldsKey}
          setEnglishFieldsKey={setEnglishFieldsKey}
          englishFieldsValue={englishFieldsValue}
          setEnglishFieldsValue={setEnglishFieldsValue}
          initialEnglishFields={initialEnglishFields}
          initialKoreanFields={initialKoreanFields}
        />
      ) : null}
      {state === "FAILURE" ? <></> : null}
    </LayoutForm2>
  );
});

const MemberComponent = (props) => {
  const history = useHistory();
  const onSave = async () => {
    const dataFields = {
      priority: props.priority,
      // image: props.image?.length === 0 ? null : props.image,
      category: props.category,
      name_KOREAN: props.koreanName,
      name_ENGLISH: props.englishName,
      email: props.email,
      fields_KOREAN: stringifyFields(
        props.koreanFieldsKey,
        props.koreanFieldsValue
      ),
      fields_ENGLISH: stringifyFields(
        props.englishFieldsKey,
        props.englishFieldsValue
      ),
    };

    // case 1: image was removed:
    if (props.image === "") {
      dataFields["image"] = null;
    }
    // case 1: new image was added:
    else if (props.image.startsWith("data:")) {
      dataFields["image"] = props.image;
    }

    if (props.memberID === null) {
      try {
        const response = await api.member__create(dataFields);
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 데이터를 생성했습니다.");
          history.push(PATH.ADMIN_MEMBER_MEMBER_LIST);
        }
      } catch (err) {}
    } else {
      try {
        const response = await api.member__update({
          memberID: props.memberID,
          ...dataFields,
        });
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 데이터를 수정했습니다.");
          history.push(PATH.ADMIN_MEMBER_MEMBER_LIST);
        }
      } catch (err) {}
    }
  };
  return (
    <>
      <AdminMember.Header
        title="연구진 관리"
        onSaveText="저장"
        onSave={onSave}
      />
      <AdminMember.MemberEditor
        image={props.image}
        setImage={props.setImage}
        category={props.category}
        setCategory={props.setCategory}
        koreanName={props.koreanName}
        setKoreanName={props.setKoreanName}
        englishName={props.englishName}
        setEnglishName={props.setEnglishName}
        priority={props.priority}
        setPriority={props.setPriority}
        email={props.email}
        setEmail={props.setEmail}
        koreanFieldsKey={props.koreanFieldsKey}
        setKoreanFieldsKey={props.setKoreanFieldsKey}
        koreanFieldsValue={props.koreanFieldsValue}
        setKoreanFieldsValue={props.setKoreanFieldsValue}
        englishFieldsKey={props.englishFieldsKey}
        setEnglishFieldsKey={props.setEnglishFieldsKey}
        englishFieldsValue={props.englishFieldsValue}
        setEnglishFieldsValue={props.setEnglishFieldsValue}
        initialEnglishFields={props.initialEnglishFields}
        initialKoreanFields={props.initialKoreanFields}
      />
      <div style={{ height: 100 }} />
      {props.memberID ? (
        <AdminMember.DeleteData memberID={props.memberID} />
      ) : null}
      <div style={{ height: 200 }} />
    </>
  );
};

const stringifyFields = (keys, values) => {
  const fields = {};
  for (const keyItem of Object.keys(keys)) {
    fields[keys[keyItem]] = values[keyItem];
  }
  return JSON.stringify(fields);
};

export default AdminMemberEditView;
