import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
const Spinner = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      lineHeight: "100vh",
      zIndex: 1,
      fontSize: 42,
      color: blue.primary,
      textAlign: "center",
    }}
  >
    <LoadingOutlined />
  </div>
);

export default Spinner;
