import Header from "./header";
import React from "react";
import styled from "styled-components";
import SunEditor from "./sunEditor";
import * as api from "services/api";
import { SINGLE_PAGE_ADMIN_NAME_ID_MAP } from "app/config";
import { message } from "antd";
import { useParams } from "react-router";
import TextEditor from "components/new/textEditor";

const Editor = () => {
  const editorRef = React.useRef(null);
  const [editorState, setEditorState] = React.useState(null);
  const [pageState, setPageState] = React.useState("PENDING");

  const { singlePageID, language } = useParams();

  React.useEffect(() => {
    api
      .singlePage__detail({ id: singlePageID, language: language })
      .then((response) => {
        if (response.status === "SUCCESS") {
          setEditorState(response.data.data);
          setPageState("NORMAL");
        }
      });
  }, []);

  const onSave = () => {
    api
      .singlePage__update({
        id: singlePageID,
        dataKorean:
          language.toUpperCase() === "KOREAN"
            ? editorRef.current?.getContent()
            : undefined,
        dataEnglish:
          language.toUpperCase() === "ENGLISH"
            ? editorRef.current?.getContent()
            : undefined,
      })
      .then((response) => {
        if (response.status === "SUCCESS") {
          message.success("성공적으로 업데이트했습니다.");
        }
      });
  };

  return (
    <Container>
      {pageState === "NORMAL" && (
        <>
          <Header
            title={`[${language.toUpperCase() === "KOREAN" ? "국문" : ""}${
              language.toUpperCase() === "ENGLISH" ? "영문" : ""
            }] ${SINGLE_PAGE_ADMIN_NAME_ID_MAP[singlePageID]}`}
            onSaveText={"저장"}
            onSave={onSave}
          />
          {/* <SunEditor
            state={editorState}
            setState={setEditorState}
            onSave={() => {}}
          /> */}
          <TextEditor editorRef={editorRef} initialValue={editorState} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div``;

export default Editor;
