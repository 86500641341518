import React from "react";
import { LayoutForm2 } from "components/layout";
import Landing from "components/landing";
import Spinner from "components/spinner";
import Forum from "components/forum";
import useStore from "services/store";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";
import { Link } from "react-router-dom";
import { PATH } from "app/config";
import styled from "styled-components";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";

const LandingView = observer(() => {
  const { action, language } = useStore();
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [list1, setList1] = React.useState([]);
  const [list2, setList2] = React.useState([]);

  React.useEffect(() => {
    initializeList();
  }, []);

  /**
   * 포럼 아티클 리스트 데이터를 가져오는 함수입니다.
   */
  const initializeList = async () => {
    // 1. 데이터를 가져옵니다.
    const responses = await Promise.all([
      action.getForumList("CONFERENCE,SEMINAR", 1),
      action.getPinnedForumList("CONFERENCE,SEMINAR", 1),
      action.getForumList("EXTERNAL,ANNOUNCEMENT", 1),
      action.getPinnedForumList("EXTERNAL,ANNOUNCEMENT", 1),
    ]);

    // 2. Request 중 에러가 발생 시, 에러 상태로 변경하고 종료합니다.
    if (!responses.every((item) => item.status === REQUEST_STATUS.SUCCESS)) {
      return setPageState(PAGE_STATE.ERROR);
    }

    const [response1, pinnedResponse1, response2, pinnedResponse2] = responses;

    // 3. 핀 리스트와 기존 리스트를 병합 후, 최대 5개까지 list1에 저장합니다.
    setList1(
      pinnedResponse1.data.concat(
        response1.data.results.slice(0, 5 - pinnedResponse1.data.length)
      )
    );

    // 4. 핀 리스트와 기존 리스트를 병합 후, 최대 5개까지 list2에 저장합니다.
    setList2(
      pinnedResponse2.data.concat(
        response2.data.results.slice(0, 5 - pinnedResponse2.data.length)
      )
    );

    // 5. 페이지 상태를 NORMAL로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };
  return (
    <LayoutForm2 CustomSidePanel={Landing.Panel}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Landing.Slider />
          <Header>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              <Link to={PATH.FORUM2} style={{ color: "inherit" }}>
                <HoverableText>
                  {language === "KOREAN" && "학회"}
                  {language === "ENGLISH" && "CONFERENCE"}
                </HoverableText>
              </Link>{" "}
              /{" "}
              <Link to={PATH.FORUM3} style={{ color: "inherit" }}>
                <HoverableText>
                  {language === "KOREAN" && "세미나"}
                  {language === "ENGLISH" && "SEMINAR"}
                </HoverableText>
              </Link>{" "}
            </div>
            <Link to={PATH.FORUM1} style={{ color: "#808080", fontSize: 20 }}>
              <HoverableText>
                <ArrowsAltOutlined />
              </HoverableText>
            </Link>
          </Header>
          <Forum.List data={list1} hidePagination />
          <div style={{ height: 50 }} />
          <div
            style={{
              fontSize: 24,
              marginTop: 10,
              marginBottom: 24,
              fontWeight: "bold",
            }}
          >
            <Header>
              <div
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                <Link to={PATH.FORUM4} style={{ color: "inherit" }}>
                  <HoverableText>
                    {language === "KOREAN" && "외부 행사"}
                    {language === "ENGLISH" && "EXTERNAL EVENTS"}
                  </HoverableText>
                </Link>{" "}
                /{" "}
                <Link to={PATH.FORUM5} style={{ color: "inherit" }}>
                  <HoverableText>
                    {language === "KOREAN" && "새소식 "}
                    {language === "ENGLISH" && "ANNOUNCEMENTS"}
                  </HoverableText>
                </Link>{" "}
              </div>
              <Link to={PATH.FORUM1} style={{ color: "#808080", fontSize: 20 }}>
                <HoverableText>
                  <ArrowsAltOutlined />
                </HoverableText>
              </Link>
            </Header>
          </div>
          <Forum.List data={list2} hidePagination />
          <div style={{ height: 200 }} />
        </>
      )}
    </LayoutForm2>
  );
});

const HoverableText = styled.span`
  color: inherit;
  border-bottom: 1px solid #fff;
  transition: color 0.2s ease-in-out;

  &&:hover {
    color: #1a91ff;
  }
`;

const Header = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export default LandingView;
