import React from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableContainer from "./dragableContainer";

const Editor = ({ data, setData }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DraggableContainer data={data} setData={setData} />
    </DndProvider>
  );
};

export default Editor;
