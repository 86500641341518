import { WarningOutlined } from "@ant-design/icons";
import React from "react";
import { Button, message } from "antd";
import * as api from "services/api";
import { STATUS } from "app/constants";
import { PATH } from "app/config";
import { useHistory } from "react-router";
import useStore from "services/store";

const DeleteButton = ({ forumID }) => {
  const { action } = useStore();
  const history = useHistory();
  const deleteItem = async () => {
    if (window.confirm("아티클을 삭제하시겠습니까?")) {
      const response = await action.deleteForum(forumID);
      if (response.status === STATUS.SUCCESS) {
        history.replace(PATH.ADMIN_FORUM_LIST);
        message.success("성공적으로 삭제되었습니다. ");
      } else message.error("삭제에 실패했습니다.");
    }
  };
  return (
    <Button
      type="default"
      danger={true}
      style={{ fontSize: 14 }}
      onClick={deleteItem}
    >
      <WarningOutlined />
      페이지 삭제
    </Button>
  );
};

export default DeleteButton;
