import React from "react";
import { LayoutForm2 } from "components/layout";
import Member from "components/member";
import Spinner from "components/spinner";
import { MEMBER_CATEGORY, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import useStore from "services/store";
import { PAGE_STATE, STATUS } from "app/constants";

/** 각각의 멤버 데이터를 정렬하는 방식을 기재한 함수입니다. */
const sortingMethod = (a, b) => {
  if (a.priority < b.priority) return 1;
  if (a.priority > b.priority) return -1;
};

const Members1View = observer(() => {
  const { language, action } = useStore();
  const [state, setState] = React.useState(PAGE_STATE.PENDING);
  const [nonTenuredResearchers, setNonTenuredResearchers] = React.useState([]);

  const initializeMemberList = async () => {
    // 1. 데이터를 요청합니다.
    const response = await action.getMemberList(
      MEMBER_CATEGORY.RESEARCH_PROFESSOR.key,
      language
    );

    // 2. 정상적인 반응이 오지 않을 시, 에러 상태로 변경하고 함수를 종료합니다.
    if (response.status !== STATUS.SUCCESS) {
      return setState(PAGE_STATE.ERROR);
    }

    // 3. 데이터를 priority 순으로 정렬하고 저장합니다.
    setNonTenuredResearchers(response.data.sort(sortingMethod));

    // 4. 페이지 상태를 '정상'으로 업데이트 합니다.
    setState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeMemberList();
  }, [language]);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.members}>
      {state === PAGE_STATE.PENDING ? <Spinner /> : null}
      {state === PAGE_STATE.NORMAL ? (
        <MemberComponent memberItems={nonTenuredResearchers} />
      ) : null}
    </LayoutForm2>
  );
});

const MemberComponent = ({ memberItems }) => (
  <>
    <Member.Title1
      textMap={{
        KOREAN: "비전임연구인력",
        ENGLISH: "NON-TENURED RESEARCHERS",
      }}
    />
    <Member.CardList items={memberItems} />
  </>
);

export default Members1View;
