import React from "react";
import { Layout, Row, Col } from "antd";
import Header from "./header";
import Footer from "./footer";
import SidePanel from "./sidePanel";
import ForumMenu from "./forumMenu";

const { Sider, Content } = Layout;

export const LayoutForm1 = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Content
        style={{
          padding: "0 50px",
          minHeight: "100vh",
          background: "#fff",
          marginTop: "100px",
        }}
      >
        {children}
      </Content>
      <Footer />
    </Layout>
  );
};

export const LayoutForm2 = ({ sidePanelMenu, children, CustomSidePanel }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Header />
      <Layout>
        <Row style={{ background: "#fff" }}>
          <Col xs={0} lg={24}>
            <div style={{ width: 300, background: "#fff" }} />
            <Sider
              width={300}
              style={{ background: "#fff", position: "fixed" }}
            >
              {CustomSidePanel ? (
                <CustomSidePanel />
              ) : (
                <SidePanel sidePanelMenu={sidePanelMenu} />
              )}
            </Sider>
          </Col>
        </Row>

        <Layout>
          <Content
            style={{
              padding: "0 50px",
              marginTop: 100,
              paddingTop: 40,
              minHeight: "100vh",
              background: "#fff",
            }}
          >
            <Row>
              <Col xs={24}>{children}</Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
};

export const ForumLayout = ({ children }) => {
  return (
    <Layout>
      <Header />
      <ForumMenu />
      <Layout>
        <Content
          style={{
            padding: "0 50px",
            marginTop: 100 + 60,
            paddingTop: 40,
            minHeight: "100vh",
            background: "#fff",
          }}
        >
          <Row>
            <Col xs={24}>{children}</Col>
          </Row>
        </Content>
      </Layout>
      <Footer />
    </Layout>
  );
};
