import {
  ADMIN_PERMISSION,
  MEMBER_CATEGORY,
  PATH,
  SIDE_PANEL_MENU,
} from "app/config";
import { LayoutForm2 } from "components/layout";
import Spinner from "components/spinner";
import React from "react";
import * as api from "services/api";
import Member from "components/member";
import useStore from "services/store";
import Header from "components/header";
import { useHistory } from "react-router";

const AdminMemberListView = () => {
  const history = useHistory();
  const { language, action } = useStore();
  const [pageState, setPageState] = React.useState("PENDING");
  const [keyMembers, setKeyMembers] = React.useState([]);
  const [researchProfessors, setResearchProfessors] = React.useState([]);
  const [researchers, setResearchers] = React.useState([]);
  const [externals, setExternals] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [staffs, setStaffs] = React.useState([]);
  const [completions, setCompletions] = React.useState([]);

  React.useEffect(() => {
    action
      .validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history)
      .then(() => {
        api
          .member__list(
            [
              MEMBER_CATEGORY.KEY_MEMBER.key,
              MEMBER_CATEGORY.RESEARCH_PROFESSOR.key,
              MEMBER_CATEGORY.RESEARCHER.key,
              MEMBER_CATEGORY.EXTERNAL.key,
              MEMBER_CATEGORY.STUDENT.key,
              MEMBER_CATEGORY.DEGREE_COMPLETION.key,
              MEMBER_CATEGORY.STAFF.key,
            ].join(","),
            language
          )
          .then((response) => {
            switch (response.status) {
              case "SUCCESS":
                setKeyMembers(
                  response.data
                    .filter(
                      (l) => l.category === MEMBER_CATEGORY.KEY_MEMBER.key
                    )
                    .sort((a, b) => {
                      if (a.priority < b.priority) return 1;
                      if (a.priority > b.priority) return -1;
                    })
                );
                setResearchProfessors(
                  response.data
                    .filter(
                      (l) =>
                        l.category === MEMBER_CATEGORY.RESEARCH_PROFESSOR.key
                    )
                    .sort(sortByEmploymentDate)
                );
                setResearchers(
                  response.data
                    .filter(
                      (l) => l.category === MEMBER_CATEGORY.RESEARCHER.key
                    )
                    .sort(sortByEmploymentDate)
                );
                setExternals(
                  response.data
                    .filter((l) => l.category === MEMBER_CATEGORY.EXTERNAL.key)
                    .sort(sortByEmploymentDate)
                );

                setStudents(
                  response.data
                    .filter((l) => l.category === "STUDENT")
                    .sort(sortByName)
                );
                setStaffs(
                  response.data
                    .filter((l) => l.category === "STAFF")
                    .sort(sortByName)
                );
                setCompletions(
                  response.data
                    .filter((l) => l.category === "DEGREE_COMPLETION")
                    .sort(sortByCompletionAndName)
                );

                setPageState("NORMAL");
                break;
              default:
                setPageState("ERROR");
            }
          });
      });
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === "PENDING" && <Spinner />}
      {pageState === "NORMAL" && (
        <>
          <Header
            title=""
            onSaveText={"새 데이터 추가"}
            onSave={() =>
              history.push(
                PATH.ADMIN_MEMBER_MEMBER_EDIT.replace(":memberID", "new")
              )
            }
          />
          <Member.Title1
            textMap={{ KOREAN: "KEY MEMBERS", ENGLISH: "Key Members" }}
          />
          <Member.CardList items={keyMembers} clickToEdit={true} />

          <Member.Title1
            textMap={{ KOREAN: "PROJECT MEMBERS", ENGLISH: "PROJECT MEMBERS" }}
            subTextMap={{
              KOREAN: `A. ${MEMBER_CATEGORY.RESEARCH_PROFESSOR.KOREAN}`,
              ENGLISH: `A. ${MEMBER_CATEGORY.RESEARCH_PROFESSOR.ENGLISH}`,
            }}
          />
          <Member.CardList items={researchProfessors} clickToEdit={true} />
          <Member.Title1
            textMap={{ KOREAN: "PROJECT MEMBERS", ENGLISH: "PROJECT MEMBERS" }}
            subTextMap={{
              KOREAN: `B. ${MEMBER_CATEGORY.RESEARCHER.KOREAN}`,
              ENGLISH: `B. ${MEMBER_CATEGORY.RESEARCHER.ENGLISH}`,
            }}
          />
          <Member.CardList items={researchers} clickToEdit={true} />
          <Member.Title1
            textMap={{
              KOREAN: "EXTERNAL MEMBERS",
              ENGLISH: "EXTERNAL MEMBERS",
            }}
          />
          <Member.CardList items={externals} clickToEdit={true} />
          <Member.Title1
            textMap={{
              KOREAN: "STUDENTS",
              ENGLISH: "STUDENTS",
            }}
          />
          <Member.CardList items={students} clickToEdit={true} />
          <Member.Title1
            textMap={{
              KOREAN: "STAFFS",
              ENGLISH: "STAFFS",
            }}
          />
          <Member.CardList items={staffs} clickToEdit={true} />
          <Member.Title1
            textMap={{
              KOREAN: "DEGREE COMPLETION",
              ENGLISH: "DEGREE COMPLETION",
            }}
          />
          <Member.CardList items={completions} clickToEdit={true} />
        </>
      )}
    </LayoutForm2>
  );
};

export default AdminMemberListView;

const sortByEmploymentDate = (a, b) => {
  if (a.priority < b.priority) return 1;
  if (a.priority > b.priority) return -1;

  if (JSON.parse(a.fields)["임용날짜"] > JSON.parse(b.fields)["임용날짜"])
    return 1;
  if (JSON.parse(a.fields)["임용날짜"] < JSON.parse(b.fields)["임용날짜"])
    return -1;
};

const sortByName = (a, b) => {
  if (a.priority < b.priority) return 1;
  if (a.priority > b.priority) return -1;

  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
};

const sortByCompletionAndName = (a, b) => {
  if (a.priority < b.priority) return 1;
  if (a.priority > b.priority) return -1;

  if (
    JSON.parse(a.fields)["학위취득연도"] > JSON.parse(b.fields)["학위취득연도"]
  )
    return 1;
  if (
    JSON.parse(a.fields)["학위취득연도"] < JSON.parse(b.fields)["학위취득연도"]
  )
    return -1;

  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
};
