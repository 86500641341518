import {
  ClockCircleOutlined,
  PaperClipOutlined,
  PushpinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Input, Pagination, Row, Table } from "antd";
import {
  ADMIN_FORUM_LIST_PAGE_SIZE,
  FORUM_CATEGORY,
  FORUM_LIST_PAGE_SIZE,
  PATH,
} from "app/config";
import helpers from "helpers";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import useStore from "services/store";
import Header from "./header";

const List = observer(
  ({
    title = null,
    data,
    totalCount,
    hidePagination = false,
    isAdmin = false,
  }) => {
    const pageSize = isAdmin
      ? ADMIN_FORUM_LIST_PAGE_SIZE
      : FORUM_LIST_PAGE_SIZE;
    const [currentPage, setCurrentPage] = React.useState(1);
    const { language } = useStore();
    const history = useHistory();
    const location = useLocation();
    const [queryText, setQueryText] = React.useState("");
    const [searchType, setSearchType] = React.useState("title");

    React.useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      setCurrentPage(parseInt(queryParams.get("page")) || 1);
      setQueryText(queryParams.get("query") || "");
      setSearchType(queryParams.get("type") || "title");
    }, [location]);

    const columns = [
      {
        title: language === "KOREAN" ? "번호" : "No.",
        dataIndex: "id",
        key: "id",
      },
      {
        title: language === "KOREAN" ? "제목" : "Title",
        dataIndex: "title",
        key: "title",
        render: (text, record, index) => {
          return (
            <>
              <div style={{ fontSize: 16 }}>{text}</div>

              <div style={{ fontSize: 14, color: "#888" }}>
                {record.participants && (
                  <>
                    <UserOutlined />
                    &nbsp;{record.participants}&nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                )}
                {record.isSingleDate && record.singleDate && (
                  <>
                    <ClockCircleOutlined />
                    &nbsp;
                    {helpers.date.toFormat3(record.singleDate)}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                )}

                {!record.isSingleDate && record.rangeBeginDate && (
                  <>
                    <ClockCircleOutlined />
                    &nbsp;
                    {`${helpers.date.toFormat1(
                      record.rangeBeginDate
                    )} ~ ${helpers.date.toFormat1(record.rangeEndDate)}`}
                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                  </>
                )}

                {record.fileCount !== 0 && (
                  <>
                    <PaperClipOutlined />
                    &nbsp; {record.fileCount}
                  </>
                )}
              </div>
            </>
          );
        },
      },
      {
        title: language === "KOREAN" ? "분류" : "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record, index) => {
          let categoryObject;
          switch (text) {
            case FORUM_CATEGORY.ANNOUNCEMENT.id:
              categoryObject = FORUM_CATEGORY.ANNOUNCEMENT;
              break;
            case FORUM_CATEGORY.CONFERENCE.id:
              categoryObject = FORUM_CATEGORY.CONFERENCE;
              break;
            case FORUM_CATEGORY.SEMINAR.id:
              categoryObject = FORUM_CATEGORY.SEMINAR;
              break;
            case FORUM_CATEGORY.EXTERNAL.id:
              categoryObject = FORUM_CATEGORY.EXTERNAL;
              break;
            default:
              break;
          }
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <span
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  background: categoryObject.color,
                  display: "inline-block",
                }}
              />
              &nbsp;&nbsp;
              {language === "KOREAN" && categoryObject.koreanName}
              {language === "ENGLISH" && categoryObject.englishName}
            </div>
          );
        },
      },
      {
        title: language === "KOREAN" ? "작성일" : "Date",
        dataIndex: "writtenDate",
        key: "writtenDate",
        render: (text, record, index) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {helpers.date.toFormat1(text)}
            </div>
          );
        },
      },
      {
        title: "",
        dataIndex: "isPinned",
        key: "writtenDate",
        render: (text, record, index) => {
          return text ? <PushpinOutlined /> : null;
        },
      },
    ];
    return (
      <>
        {title && <Header title={title} />}
        <Table
          rowClassName="clickable"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (isAdmin) {
                  history.push(
                    PATH.ADMIN_FORUM_EDIT.replace(":forumID", record.id)
                  );
                } else {
                  history.push(
                    PATH.FORUM_ARTICLE.replace(
                      ":category",
                      record.category.toLowerCase()
                    ).replace(":forumID", record.id)
                  );
                }
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {}, // mouse leave row
            };
          }}
          dataSource={data}
          columns={columns}
          pagination={false}
        />
        {!hidePagination && (
          <Pagination
            style={{
              marginTop: 50,
              marginBottom: 200,
              display: "flex",
              justifyContent: "center",
            }}
            showSizeChanger={false}
            current={currentPage}
            total={totalCount}
            pageSize={pageSize}
            onChange={(page) => {
              history.push(
                `${window.location.pathname}?page=${page}&query=${queryText}&type=${searchType}`
              );
            }}
          />
        )}
      </>
    );
  }
);

export default List;
