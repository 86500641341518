import React from "react";
import { Table } from "antd";

const AlumniTable = ({ data, columns }) => {
  return (
    <Table
      style={{ marginBottom: 120 }}
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  );
};

export default AlumniTable;
