import { ClockCircleOutlined, PictureOutlined } from "@ant-design/icons";
import { Tag, Col } from "antd";
import { API_BASE_URL, PATH } from "app/config";
import helpers from "helpers";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ImageRenderer = ({
  abstractPhotoGalleryArticle,
  isFake = false,
  isAdmin = false,
}) => {
  const cardWidth = 220;
  return isFake ? (
    <Container cardWidth={cardWidth} />
  ) : (
    <Col xs={12} sm={8} md={6}>
      <Link
        to={
          isAdmin
            ? PATH.ADMIN_PHOTO_EDIT.replace(
                ":galleryID",
                abstractPhotoGalleryArticle.id
              )
            : PATH.FORUM_GALLERY.replace(
                ":galleryID",
                abstractPhotoGalleryArticle.id
              )
        }
      >
        <Container cardWidth={cardWidth}>
          {!isFake && (
            <>
              <ImageContainer>
                <Image
                  src={`${abstractPhotoGalleryArticle.topImageURL}`}
                  cardWidth={cardWidth}
                />
                <Tag
                  color="#fff"
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: 0,
                    fontSize: 11,
                    color: "#373737",
                    border: "0.5px solid #d0d0d0",
                  }}
                >
                  <PictureOutlined />
                  &nbsp;
                  {abstractPhotoGalleryArticle.count}
                </Tag>
              </ImageContainer>
              <Title cardWidth={cardWidth}>
                {abstractPhotoGalleryArticle.title}
              </Title>

              {/* <Date>
              <Tag
              style={{
                fontSize: 11,
                backgroundColor: "#909090",
                color: "white",
              }}
              >
              {abstractPhotoGalleryArticle.beginsAt ===
                abstractPhotoGalleryArticle.endsAt
                ? `${helpers.date.toFormat1(
                  abstractPhotoGalleryArticle.beginsAt
                  )}`
                  : `${helpers.date.toFormat1(
                    abstractPhotoGalleryArticle.beginsAt
                    )} ~ ${helpers.date.toFormat1(
                      abstractPhotoGalleryArticle.endsAt
                      )}`}
                      </Tag>
                    </Date> */}
            </>
          )}
        </Container>
      </Link>
    </Col>
  );
};

const Container = styled.div`
  /* width: ${(props) => props.cardWidth}px;
  height: ${(props) => props.cardWidth}px; */
  width: 100%;
  aspect-ratio: 1;
  margin-bottom: 120px;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
`;

const Image = styled.img`
  object-fit: cover;
  /* width: ${(props) => props.cardWidth}px;
  height: ${(props) => props.cardWidth}px; */
  width: 100%;
  aspect-ratio: 1;
  object-position: center;
`;

const Title = styled.div`
  /* width: ${(props) => props.cardWidth}px; */
  width: 100%;
  font-size: 14px;
  margin-top: 4px;
  color: #373737;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2em;
  height: 3.6em; /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
`;

const Date = styled.div`
  width: ${(props) => props.cardWidth}px;
`;

export default ImageRenderer;
