import { WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Table } from "antd";
import { TABLE_COLUMNS } from "app/config";
import { STATUS } from "app/constants";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const TableEditingModal = observer(
  ({ visible, setVisible, state, setState, refreshData }) => {
    const { action } = useStore();

    /**
     * 방문연구원 데이터를 생성합니다.
     */
    const createVisitor = async () => {
      const response = await action.createVisitor(state);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("생성에 실패했습니다.");
      }

      message.success("성공적으로 추가했습니다.");
      setVisible(false);
      await refreshData();
    };

    /**
     * 방문연구원 데이터를 수정합니다..
     */
    const updateVisitor = async () => {
      const response = await action.updateVisitor(state);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("수정에 실패했습니다.");
      }

      message.success("성공적으로 수정했습니다.");
      setVisible(false);
      await refreshData();
    };

    /**
     * OK 버튼을 누를 때 실행할 함수입니다.
     */
    const onOk = async () => {
      if (state.id === null) {
        await createVisitor();
      } else {
        await updateVisitor();
      }
    };

    /**
     * 수정페이지에서 [데이터 삭제]를 누를 시 실행할 함수입니다.
     */
    const onDelete = async () => {
      const response = await action.deleteVisitor(state.id);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("삭제에 실패했습니다.");
      }

      message.success("성공적으로 데이터를 삭제했습니다.");
      setVisible(false);
      await refreshData();
    };

    return (
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
        destroyOnClose={true}
      >
        <Container>
          <Title>
            {state.id === null ? "방문 연구원 추가" : "방문 연구원 수정"}
          </Title>
          <Form.Item
            label="이름 (국문)"
            name="이름 (국문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.name_KOREAN}
              value={state.name_KOREAN}
              onChange={(e) => setState({ name_KOREAN: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="이름 (영문)"
            name="이름 (영문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.name_ENGLISH}
              value={state.name_ENGLISH}
              onChange={(e) => setState({ name_ENGLISH: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="소속 (국문)"
            name="소속 (국문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.affiliation_KOREAN}
              value={state.affiliation_KOREAN}
              onChange={(e) => setState({ affiliation_KOREAN: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="소속 (영문)"
            name="소속 (영문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.affiliation_ENGLISH}
              value={state.affiliation_ENGLISH}
              onChange={(e) =>
                setState({ affiliation_ENGLISH: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="초청자 (국문)"
            name="초청자 (국문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.inviter_KOREAN}
              value={state.inviter_KOREAN}
              onChange={(e) => setState({ inviter_KOREAN: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="초청자 (영문)"
            name="초청자 (영문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.inviter_ENGLISH}
              value={state.inviter_ENGLISH}
              onChange={(e) => setState({ inviter_ENGLISH: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="체류 기간" name="체류 기간" labelCol={{ span: 5 }}>
            <Input
              style={{ width: 140, marginRight: 10 }}
              defaultValue={state.beginDate}
              value={state.beginDate}
              onChange={(e) => setState({ beginDate: e.target.value })}
            />
            ~
            <Input
              style={{ width: 140, marginLeft: 10 }}
              defaultValue={state.endDate}
              value={state.endDate}
              onChange={(e) => setState({ endDate: e.target.value })}
            />
          </Form.Item>
          {state.id !== null && (
            <Button
              type="default"
              danger={true}
              style={{ fontSize: 14, marginTop: 20, marginBottom: 40 }}
              onClick={onDelete}
            >
              <WarningOutlined />
              데이터 삭제
            </Button>
          )}
        </Container>
      </Modal>
    );
  }
);

const Container = styled.div`
  width: 550px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

export default TableEditingModal;
