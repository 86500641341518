import React from "react";
import { useState, useCallback } from "react";
import DraggableCard from "./draggableCard";
import update from "immutability-helper";
const style = {
  width: 400,
};
const DraggableContainer = ({ data, setData }) => {
  const setState = (key, value, idx) => {
    const newData = data;
    newData[idx][key] = value;
    setData([...newData]);
  };

  {
    /** 카드 위치를 변경할 때, 변경된 카드에서 실행될 함수  */
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = data[dragIndex];
        setData(
          update(data, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      },
      [data]
    );

    return (
      <>
        <div style={style}>
          {data.map((item, idx) => (
            <DraggableCard
              key={idx}
              index={idx}
              id={item.id}
              state={data[idx]}
              setState={(key, value) => setState(key, value, idx)}
              moveCard={moveCard}
            />
          ))}
        </div>
      </>
    );
  }
};

export default DraggableContainer;
