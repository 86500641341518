import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { DeleteOutlined } from "@ant-design/icons";

const Attachments = observer(({ attachments, setAttachments }) => {
  return (
    <Container>
      {["첨부파일1", "첨부파일2", "첨부파일3", "첨부파일4", "첨부파일5"].map(
        (item, idx) => (
          <Row key={idx}>
            <Label>{item}</Label>
            <FileHandler
              width={500}
              value={attachments[item]}
              onChange={(value) => setAttachments({ [item]: value })}
            />
          </Row>
        )
      )}
    </Container>
  );
});

const FileHandler = observer(({ width, value, onChange, ...rest }) => {
  return (
    <>
      <FileHandlerContainer width={width}>
        <FileHandlerLabel>
          {Boolean(value?.length)
            ? typeof value === "string"
              ? value.split("?")[0].split("/").slice(-1)[0]
              : value.map((f) => f.name).join(", ")
            : "파일 선택하기"}
          <input
            {...rest}
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {
              onChange([...e.target.files]);
            }}
          />
        </FileHandlerLabel>
      </FileHandlerContainer>
      {value ? (
        <DeleteButton
          onClick={() => {
            onChange({ target: { value: null } });
          }}
        >
          <DeleteOutlined />
        </DeleteButton>
      ) : null}
    </>
  );
});

const Row = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const Label = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  padding-right: 20px;
  user-select: none;
  color: #808080;
`;

const FileHandlerContainer = styled.div`
  position: relative;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  border: ${(props) => (props.readOnly ? "none" : "1px solid #ccc")};
  border-radius: 4px;
  font-size: 12px;

  box-sizing: border-box;
  min-width: 150px;
  padding-left: 20px;
  padding-right: 20px;
`;

const FileHandlerLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #808080;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  width: 100%;
  padding-right: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
  box-sizing: border-box;
`;

const DeleteButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  color: #808080;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &&:hover {
    color: #ff8a65;
  }
`;

export default Attachments;
