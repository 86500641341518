import React from "react";
import "antd/dist/antd.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import routes from "views";
import "./index.css";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, idx) => (
          <Route
            exact
            path={route.path}
            key={idx}
            component={route.component}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
