import moment from "moment";
import { DatePicker, Input, message } from "antd";
import Header from "components/header";
import React from "react";
import styled from "styled-components";

import { STATUS } from "app/constants";
import { PATH } from "app/config";
import { observer } from "mobx-react";

const { RangePicker } = DatePicker;

/**
 * Photo 아티클을 수정하는 에디터 컴포넌트입니다.
 */
const TitleRow = observer(({ photoGalleryArticleModel }) => {
  const [isSingleDate, setIsSingleDate] = React.useState(true);

  // moment sample code 그대로 사용:
  // moment 관련 코드를 정의합니다.
  const [singleDate, setSingleDate] = React.useState(); // moment Object
  const [_dates, _setDates] = React.useState([]);
  const [_hackValue, _setHackValue] = React.useState();
  const [rangeDate, setRangeDate] = React.useState(); // moment Objects
  const disabledDate = (current) => {
    if (!_dates || _dates.length === 0) {
      return false;
    }
    const tooEarly = _dates[1] && _dates[1].diff(current, "days") > 7;
    return tooEarly;
  };

  const onOpenChange = (open) => {
    if (open) {
      _setHackValue([]);
      _setDates([]);
    } else {
      _setHackValue(undefined);
    }
  };

  React.useEffect(() => {
    // 이벤트 날짜 데이터를  moment 형식으로 변경해서 저장합니다.
    // 1-1. beginsAt과 endsAt이 동일할 경우, isSingleDate로 간주합니다.
    setIsSingleDate(
      photoGalleryArticleModel.beginsAt?.format("LL") ===
        photoGalleryArticleModel.endsAt?.format("LL")
    );

    if (
      moment(photoGalleryArticleModel.beginsAt).isValid() &&
      moment(photoGalleryArticleModel.endsAt).isValid() &&
      photoGalleryArticleModel.beginsAt?.format("LL") !==
        photoGalleryArticleModel.endsAt?.format("LL")
    ) {
      setRangeDate([
        ...[
          moment(photoGalleryArticleModel.beginsAt),
          moment(photoGalleryArticleModel.endsAt),
        ],
      ]);
    }
  }, []);

  //   /**
  //    * 현재 입력된 데이터를 POST/PATCH 콜에 적합한 형태로 가공합니다.
  //    */
  //   const _getFinalData = () => {
  //     let dateData = {};

  //     // isSingleDate일 경우, beginsAt과 endsAt에 동일한 값을 저장합니다.
  //     if (isSingleDate) {
  //       dateData["beginsAt"] =
  //         (singleDate && singleDate.format("YYYY-MM-DD HH:mmZ")) || null;
  //       dateData["endsAt"] =
  //         (singleDate && singleDate.format("YYYY-MM-DD HH:mmZ")) || null;
  //     } else {
  //       dateData["beginsAt"] =
  //         (rangeDate && rangeDate[0].format("YYYY-MM-DD HH:mmZ")) || null;
  //       dateData["endsAt"] =
  //         (rangeDate && rangeDate[1].format("YYYY-MM-DD HH:mmZ")) || null;
  //     }

  //     const finalData = {
  //       ...{
  //         id: headerData.id,
  //         title: headerData.title,
  //         body: editorRef.current?.getContent(),
  //       },
  //       ...dateData,
  //     };

  //     return finalData;
  //   };

  //   /* 데이터가 정상적인지 체크합니다. */
  //   const isDataValid = (data) => {
  //     if (!data.title) {
  //       message.error("제목을 입력해주세요.");
  //       return false;
  //     }

  //     return true;
  //   };

  return (
    <Container>
      <Input
        value={photoGalleryArticleModel.title}
        onChange={(e) =>
          photoGalleryArticleModel.set({ title: e.target.value })
        }
        placeholder="아티클 제목"
        maxLength={200}
        style={{ fontSize: "18px", padding: 8, marginBottom: 10 }}
      />
      <Input.Group compact style={{ marginBottom: 50 }}>
        {
          /* 단일 날짜 선택 시에 보여질 컴포넌트입니다: */
          isSingleDate && (
            <>
              <DatePicker
                value={photoGalleryArticleModel.beginsAt}
                onChange={(momentObject) => {
                  photoGalleryArticleModel.set({
                    beginsAt: momentObject,
                    endsAt: momentObject,
                  });
                }}
                format="YYYY-MM-DD"
                // showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                placeholder={"행사 일자 및 시각"}
              />
              <span
                onClick={() => {
                  setIsSingleDate(false);
                }}
                style={{
                  marginLeft: 10,
                  display: "inline-block",
                  verticalAlign: "bottom",
                  cursor: "pointer",
                  color: "#1890ff",
                }}
              >
                복수 날짜 선택
              </span>
            </>
          )
        }
        {!isSingleDate && (
          <>
            <RangePicker
              value={_hackValue || rangeDate}
              disabledDate={disabledDate}
              onCalendarChange={(val) => _setDates(val)}
              onChange={(val) => {
                photoGalleryArticleModel.set({
                  beginsAt: val[0],
                  endsAt: val[1],
                });
                setRangeDate(val);
              }}
              onOpenChange={onOpenChange}
              placeholder={["행사 시작 일자", "행사 종료 일자"]}
            />
            <span
              onClick={() => {
                photoGalleryArticleModel.set({
                  beginsAt: null,
                  endsAt: null,
                });
                setIsSingleDate(true);
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                verticalAlign: "bottom",
                cursor: "pointer",
                color: "#1890ff",
              }}
            >
              단일 날짜 선택
            </span>
          </>
        )}
      </Input.Group>
    </Container>
  );
});

const Container = styled.div``;

export default TitleRow;
