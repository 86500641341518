import { Pagination, Table } from "antd";
import { ADMIN_EVENT_LIST_PAGE_SIZE, PATH } from "app/config";
import helpers from "helpers";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

const List = observer(({ data, totalCount, hidePagination = false }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setCurrentPage(parseInt(queryParams.get("page")) || 1);
  }, [location]);

  const columns = [
    {
      title: "번호",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      render: (text, record, index) => {
        return (
          <div
            style={{ fontSize: 16, cursor: "pointer" }}
            onClick={() => {
              history.push(
                PATH.ADMIN_EVENT_PAGE_EDIT.replace(":eventID", record.id)
              );
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "작성일",
      dataIndex: "writtenDate",
      key: "writtenDate",
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {helpers.date.toFormat1(text)}
          </div>
        );
      },
    },
    {
      title: "링크",
      dataIndex: "link",
      key: "link",
      render: (text, record, index) => {
        return (
          <Link
            to={`${PATH.EVENT_ARTICLE.replace(":eventID", record.id)}`}
          >{`${PATH.EVENT_ARTICLE.replace(":eventID", record.id)}`}</Link>
        );
      },
    },
  ];
  return (
    <>
      <Table
        // rowClassName="clickable"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
      {!hidePagination && (
        <Pagination
          showSizeChanger={false}
          current={currentPage}
          total={totalCount}
          pageSize={ADMIN_EVENT_LIST_PAGE_SIZE}
          onChange={(page) => {
            history.push(`${window.location.pathname}?page=${page}`);
          }}
          style={{ margin: "50px 0 120px" }}
        />
      )}
    </>
  );
});

export default List;
