import React from "react";
import { Layout } from "antd";
import { grey } from "@ant-design/colors";

import APTCP_PNG from "graphic/footer/logo_aptcp.png";
import EDU_PNG from "graphic/footer/logo_edu.png";
import MSIT_PNG from "graphic/footer/logo_msit.png";
import NRF_PNG from "graphic/footer/logo_nrf.png";
import PHYSICS_PNG from "graphic/footer/logo_sogang_physics.png";
import SOGANG_PNG from "graphic/footer/logo_sogang.png";

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      {FOOTER_TEXT.map((l, idx) => (
        <span
          style={{ display: "block", color: grey[0], fontSize: 12 }}
          key={idx}
        >
          {l}
        </span>
      ))}
      <div style={{ textAlign: "center", marginTop: 20 }}>
        {FOOTER_IMAGES.map((l, idx) => (
          <img
            src={l.image}
            key={idx}
            height="40"
            style={{ margin: "0 20", cursor: "pointer" }}
            onClick={() => {
              window.open(l.link, "_blank").focus();
            }}
          />
        ))}
      </div>
    </Layout.Footer>
  );
};

const FOOTER_TEXT = [
  "35 Baekbeom-ro (Sinsu-dong), Mapo-gu, Seoul 04107, Korea | Tel : +82-2-705-7865 | Fax : +82-2-704-9031",
  "Copyright ⓒ 2009 Center for Quantum Spacetime. All rights reserved. | E.Mail : cquest@sogang.ac.kr",
];

const FOOTER_IMAGES = [
  { image: APTCP_PNG, link: "https://aptcp.org" },
  { image: EDU_PNG, link: "https://www.moe.go.kr" },
  { image: MSIT_PNG, link: "https://www.msit.go.kr" },
  { image: NRF_PNG, link: "https://www.nrf.re.kr" },
  { image: SOGANG_PNG, link: "https://sogang.ac.kr" },
  { image: PHYSICS_PNG, link: "https://physics.sogang.ac.kr" },
];

export default Footer;
