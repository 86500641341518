import React from "react";
import { Card, Col, Row } from "antd";
import { observer } from "mobx-react";
import useStore from "services/store";
import { grey } from "@ant-design/colors";
import { ExportOutlined, SettingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { PATH } from "app/config";
import { REQUEST_STATUS } from "app/constants";

const sortByCquestNumber = (a, b) => {
  if (a.cquestNumber > b.cquestNumber) return 1;
  if (a.cquestNumber < b.cquestNumber) return -1;
};

const CardList = ({ data, isAdmin = false, setModalVisible }) => {
  return (
    <div>
      {Object.keys(data)
        .sort()
        .reverse()
        .map((year, idx) => (
          <div style={{ marginBottom: 140 }}>
            <Title key={idx} year={year} />
            {data[year]
              .sort(sortByCquestNumber)
              .reverse()
              .map((paper, sidx) => (
                <CardItem
                  key={sidx}
                  isAdmin={isAdmin}
                  {...paper}
                  setModalVisible={setModalVisible}
                />
              ))}
          </div>
        ))}
    </div>
  );
};

const Title = observer(({ year }) => {
  const { language } = useStore();
  return (
    <div
      style={{
        marginTop: 80,
        fontSize: 28,
        color: "#000",
        marginBottom: 40,
      }}
    >
      {language === "KOREAN" ? `${year}년 논문` : null}
      {language === "ENGLISH" ? `${year} Paper` : null}
    </div>
  );
});

const CardItem = observer(
  ({
    id,
    DOI,
    DOILink,
    arXiv,
    year,
    author,
    cquestNumber,
    journal,
    title,
    isAdmin,
    setModalVisible,
  }) => {
    const { adminPaperStore, action } = useStore();

    const keyStyle = {
      display: "inline-block",
      // width: 110,
      fontSize: 14,
      color: grey[2],
    };
    const valueStyle = {
      fontSize: 14,
    };

    const onCardClick = async () => {
      if (!isAdmin) return;
      const response = await action.getPaperDetail(id);
      if (response.status !== REQUEST_STATUS.SUCCESS) {
        return;
      }

      adminPaperStore.set({
        id: response.data.id,
        title: response.data.title,
        year: response.data.year.toString(),
        author: response.data.author,
        journal: response.data.journal,
        DOI: response.data.DOI,
        DOILink: response.data.DOILink,
        arXiv: response.data.arXiv,
        cquestNumber: response.data.cquestNumber,
        publishYear: response.data.publishYear?.toString(),
      });
      setModalVisible(true);
    };

    return (
      <Card
        style={{
          position: "relative",
          maxWidth: 850,
          marginBottom: 20,
          cursor: isAdmin ? "pointer" : "inherit",
        }}
        onClick={onCardClick}
      >
        <span style={{ display: "block", fontSize: 16, color: "#000" }}>
          {title}
        </span>
        <div style={{ marginTop: 20 }}>
          <div style={{ display: author ? "inherit" : "none" }}>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <span style={keyStyle}>Author</span>
              </Col>
              <Col xs={18} md={20} lg={21}>
                <span style={valueStyle}>{author}</span>
              </Col>
            </Row>
          </div>
          <div style={{ display: journal ? "inherit" : "none" }}>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <span style={keyStyle}>journal</span>
              </Col>
              <Col xs={18} md={20} lg={21}>
                <span style={valueStyle}>{journal}</span>
              </Col>
            </Row>
          </div>
          <div style={{ display: DOI ? "inherit" : "none" }}>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <span style={keyStyle}>DOI</span>
              </Col>
              <Col xs={18} md={20} lg={21}>
                <span style={valueStyle}>
                  {DOILink ? (
                    <a href={DOILink} target="_blank" rel="noopener noreferrer">
                      {DOI} &nbsp;&nbsp;
                      <ExportOutlined />
                    </a>
                  ) : (
                    DOI
                  )}
                </span>
              </Col>
            </Row>
          </div>
          <div style={{ display: arXiv ? "inherit" : "none" }}>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <span style={keyStyle}>arXiv</span>
              </Col>
              <Col xs={18} md={20} lg={21}>
                <span style={valueStyle}>
                  {arXiv ? (
                    <a
                      rel="noopener noreferrer"
                      href={`https://arxiv.org/abs/${arXiv.replace(/ /g, "")}`}
                      target="_blank"
                    >
                      {arXiv} &nbsp;&nbsp;
                      <ExportOutlined />
                    </a>
                  ) : null}
                </span>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <span style={keyStyle}>CQUeST No.</span>
              </Col>
              <Col xs={18} md={20} lg={21}>
                <span style={valueStyle}>{cquestNumber}</span>
              </Col>
            </Row>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            fontSize: 32,
            cursor: "pointer",
          }}
        >
          {/* {arXiv ? (
          <a
            target="_blank"
            without
            rel="noopener noreferrer"
            href={`https://arxiv.org/pdf/${arXiv.replace(/\s/g, "")}`}
          >
            <FilePdfOutlined style={{ color: "#b60005" }} />
          </a>
        ) : null} */}
        </div>
      </Card>
    );
  }
);

export default CardList;
