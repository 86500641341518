import React from "react";
import { Card, Table } from "antd";
import { grey } from "@ant-design/colors";
import { observer } from "mobx-react";

const VisitorTable = observer(({ year, data, columns, onEdit = null }) => {
  return (
    <Card style={{ marginBottom: 150 }}>
      <div style={{ marginTop: -46, marginBottom: 50 }}>
        <span
          style={{
            display: "inline-block",
            fontSize: 24,
            color: grey[3],
            background: "#fff",
          }}
        >
          &nbsp;&nbsp;{year}&nbsp;&nbsp;
        </span>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
    </Card>
  );
});

export default VisitorTable;
