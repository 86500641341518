import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Carousel } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useStore from "services/store";
import { REQUEST_STATUS } from "app/constants";
import { API_BASE_URL } from "app/config";

// const imageList = [
//   {
//     src: "http://cquest.sogang.ac.kr/static/media/new-event2.ed1c60b7.png",
//     path: "/event/5",
//   },
//   {
//     src: "http://cquest.sogang.ac.kr/static/media/new-event3.0f567ccf.png",
//     path: "/event/6",
//   },

//   {
//     src: "http://cquest.sogang.ac.kr/static/media/new-event1.5e071712.png",
//     path: "/event/3",
//   },
//   {
//     src: "http://cquest.sogang.ac.kr/static/media/new-event4.6da67fa7.png",
//     path: "/event/2",
//   },
//   {
//     src: "http://cquest.sogang.ac.kr/static/media/slide1.7ac76cc6.gif",
//     path: "",
//   },
//   {
//     src: "http://cquest.sogang.ac.kr/static/media/slide2.4f18a29c.png",
//     path: "",
//   },
// ];

const Slider = () => {
  const { action } = useStore();
  const carouselRef = React.useRef();
  const [images, setImages] = React.useState(null);

  const initialize = async () => {
    const response = await action.getImageSlider();
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return;
    }

    console.log(response.data.filter((item) => item.image));

    setImages(response.data.filter((item) => item.image));
  };

  React.useEffect(() => {
    initialize();
  }, []);

  return (
    images && (
      <div
        style={{ position: "relative", maxWidth: "1280px", margin: "0 auto" }}
      >
        <SideButton
          onClick={() => {
            carouselRef.current.prev();
          }}
          style={{ left: 0 }}
          TextComponent={<CaretLeftOutlined />}
        />
        <CarouselWrapper autoplaySpeed={7000} ref={carouselRef} autoplay>
          {images.map((item, idx) =>
            item.url ? (
              <a href={item.url} target="_blank">
                <img
                  key={idx}
                  alt=""
                  src={API_BASE_URL + item.image}
                  width="100%"
                  height="100%"
                />
              </a>
            ) : (
              <img
                key={idx}
                alt=""
                src={API_BASE_URL + item.image}
                width="100%"
                height="100%"
              />
            )
          )}
        </CarouselWrapper>
        <SideButton
          onClick={() => {
            carouselRef.current.next();
          }}
          style={{ right: 0 }}
          TextComponent={<CaretRightOutlined />}
        />
      </div>
    )
  );
};

const SideButton = ({ onClick, style = {}, TextComponent }) => {
  return (
    <SideButtonContainer onClick={onClick} style={style}>
      {TextComponent}
    </SideButtonContainer>
  );
};

const SideButtonContainer = styled.div`
  width: 4%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &&:hover {
    opacity: 0.3;
  }
`;

const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 16px;
    height: 6px;
    border-radius: 6px;
  }
  > .slick-dots li.slick-active button {
    width: 24px;
    height: 6px;
    border-radius: 6px;
  }
`;

export default Slider;
