import React from "react";
import TUICalendar from "@toast-ui/react-calendar";
import "tui-calendar/dist/tui-calendar.css";
import { FORUM_CATEGORY, PATH } from "app/config";
import * as api from "services/api";
import { REQUEST_STATUS, STATUS } from "app/constants";
import { useHistory } from "react-router";
import Header from "./header";
import styled from "styled-components";
import useStore from "services/store";

/**
 * 달력 컴포넌트입니다.
 *
 * 달력 데이터를 page root 단에서 로드하지 않는 이유는, 페이지네이션 과정에서 달력 데이터가 리랜더링되는 걸
 * 방지하기 위함입니다.
 */
const Calendar = () => {
  const { action } = useStore();
  const history = useHistory();
  const calendarRef = React.useRef(null);
  const [schedules, setSchedules] = React.useState([]);
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());

  /**
   * 이벤트 데이터를 가져오는 함수입니다.
   */
  const getCalendarEvents = async () => {
    // 1. 캘린더 데이터를 가져옵니다.
    const response = await action.getCalendarEventList();

    // 2. 데이터 요청에 문제가 생길 시 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return;
    }

    // 3. 데이터를 캘린더 포맷에 맞게 수정한 뒤, 저장합니다.
    let _schedules = [];
    for (const item of response.data) {
      for (const date of item.dateListString.split(",")) {
        _schedules.push({
          id: item.id,
          category: "time",
          calendarId: item.category,
          title: item.title,
          start: date,
          end: date,
          isAllDay: false,
        });
      }
    }
    setSchedules(_schedules);
  };

  /**
   * 현재 보여지는 캘린더의 month state을 수정하는 함수입니다.
   */
  const addCalendarDelta = (delta) => {
    const date = new Date(currentYear, currentMonth);
    date.setMonth(currentMonth + delta);
    setCurrentYear(date.getFullYear());
    setCurrentMonth(date.getMonth());
    if (delta === 1) {
      calendarRef.current.getInstance().next();
    } else if (delta === -1) {
      calendarRef.current.getInstance().prev();
    }
  };

  React.useEffect(() => {
    getCalendarEvents();
  }, []);

  /**
   * 캘린더에 보여지는 일정을 클릭했을 때 실행될 합수입니다.
   */
  const onClickSchedule = ({ schedule }) => {
    const categoryName = schedule.calendarId;
    const forumID = schedule.id;

    for (const key of Object.keys(FORUM_CATEGORY)) {
      if (FORUM_CATEGORY[key].id === categoryName) {
        window.scrollTo(0, 0);
        history.push(
          PATH.FORUM_ARTICLE.replace(
            ":category",
            categoryName.toLowerCase()
          ).replace(":forumID", forumID)
        );
        return;
      }
    }
  };

  return (
    <>
      <Header
        currentMonth={currentMonth}
        currentYear={currentYear}
        addCalendarDelta={addCalendarDelta}
      />
      <CalendarContainer>
        <TUICalendar
          ref={calendarRef}
          view={"month"}
          disableDblClick={true}
          disableClick={true}
          isReadOnly={true}
          contentEditable={false}
          calendars={CALENDAR_CONFIG}
          schedules={schedules}
          onClickSchedule={onClickSchedule}
        />
      </CalendarContainer>
    </>
  );
};

const CalendarContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  border: 0.5px solid #e5e5e5;
  border-top-width: 0;
`;

const CALENDAR_CONFIG = [
  {
    id: FORUM_CATEGORY.CONFERENCE.id,
    name: FORUM_CATEGORY.CONFERENCE.id,
    bgColor: FORUM_CATEGORY.CONFERENCE.color,
    borderColor: FORUM_CATEGORY.CONFERENCE.color,
    color: "#ffffff",
  },
  {
    id: FORUM_CATEGORY.SEMINAR.id,
    name: FORUM_CATEGORY.SEMINAR.id,
    bgColor: FORUM_CATEGORY.SEMINAR.color,
    borderColor: FORUM_CATEGORY.SEMINAR.color,
    color: "#ffffff",
  },
  {
    id: FORUM_CATEGORY.EXTERNAL.id,
    name: FORUM_CATEGORY.EXTERNAL.id,
    bgColor: FORUM_CATEGORY.EXTERNAL.color,
    borderColor: FORUM_CATEGORY.EXTERNAL.color,
    color: "#ffffff",
  },
];

export default Calendar;
