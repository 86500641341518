import { WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { TABLE_COLUMNS } from "app/config";
import { STATUS } from "app/constants";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const { Option } = Select;

const PreviousMemberEditingModal = observer(
  ({ visible, setVisible, state, setState, refreshData }) => {
    const { action } = useStore();

    /**
     * 방문연구원 데이터를 생성합니다.
     */
    const createAlumniMember = async () => {
      const response = await action.createAlumniMember(state);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("생성에 실패했습니다.");
      }

      message.success("성공적으로 추가했습니다.");
      setVisible(false);
      await refreshData();
    };

    /**
     * 방문연구원 데이터를 수정합니다..
     */
    const updateAlumniMember = async () => {
      const response = await action.updateAlumniMember(state);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("수정에 실패했습니다.");
      }

      message.success("성공적으로 수정했습니다.");
      setVisible(false);
      await refreshData();
    };

    /**
     * OK 버튼을 누를 때 실행할 함수입니다.
     */
    const onOk = async () => {
      if (state.id === null) {
        await createAlumniMember();
      } else {
        await updateAlumniMember();
      }
    };

    /**
     * 수정페이지에서 [데이터 삭제]를 누를 시 실행할 함수입니다.
     */
    const onDelete = async () => {
      const response = await action.deleteAlumniMember(state.id);
      if (response.status !== STATUS.SUCCESS) {
        return message.error("삭제에 실패했습니다.");
      }

      message.success("성공적으로 데이터를 삭제했습니다.");
      setVisible(false);
      await refreshData();
    };

    return (
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
        destroyOnClose={true}
      >
        <Container>
          <Title>
            {state.id === null ? "거쳐가신 분 추가" : "거쳐가신 분 수정"}
          </Title>
          <Form.Item label="카테고리" name="카테고리" labelCol={{ span: 5 }}>
            <Select
              defaultValue={state.category}
              value={state.category}
              onChange={(value) => setState({ category: value })}
              style={{ width: "300px" }}
            >
              <Option value="EMERITUS">정년 퇴임교수</Option>
              <Option value="FULL_TIME_PROFESSOR">퇴직 전임교수</Option>
              <Option value="RESEARCH_PROFESSOR">퇴직 연구교수</Option>
              <Option value="RESEARCHER">퇴직 연구원</Option>
              <Option value="VISITOR_PROFESSOR">퇴직 방문교수</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="이름 (국문)"
            name="이름 (국문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.name_KOREAN}
              value={state.name_KOREAN}
              onChange={(e) => setState({ name_KOREAN: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="이름 (영문)"
            name="이름 (영문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.name_ENGLISH}
              value={state.name_ENGLISH}
              onChange={(e) => setState({ name_ENGLISH: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="소속 (국문)"
            name="소속 (국문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.affiliation_KOREAN}
              value={state.affiliation_KOREAN}
              onChange={(e) => setState({ affiliation_KOREAN: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="소속 (영문)"
            name="소속 (영문)"
            labelCol={{ span: 5 }}
          >
            <Input
              style={{ width: 300 }}
              defaultValue={state.affiliation_ENGLISH}
              value={state.affiliation_ENGLISH}
              onChange={(e) =>
                setState({ affiliation_ENGLISH: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="이메일" name="이메일" labelCol={{ span: 5 }}>
            <Input
              style={{ width: 300 }}
              defaultValue={state.email}
              value={state.email}
              onChange={(e) => setState({ email: e.target.value })}
            />
          </Form.Item>

          <Form.Item label="고용 기간" name="고용 기간" labelCol={{ span: 5 }}>
            <Input
              style={{ width: 140, marginRight: 10 }}
              defaultValue={state.beginDate}
              value={state.beginDate}
              onChange={(e) => setState({ beginDate: e.target.value })}
            />
            ~
            <Input
              style={{ width: 140, marginLeft: 10 }}
              defaultValue={state.endDate}
              value={state.endDate}
              onChange={(e) => setState({ endDate: e.target.value })}
            />
          </Form.Item>
          <Button
            type="default"
            danger={true}
            style={{ fontSize: 14, marginTop: 20, marginBottom: 40 }}
            onClick={onDelete}
          >
            <WarningOutlined />
            데이터 삭제
          </Button>
        </Container>
      </Modal>
    );
  }
);

const Container = styled.div`
  width: 550px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

export default PreviousMemberEditingModal;
