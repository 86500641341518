import React from "react";

// export const API_BASE_URL = "http://localhost:8000";
// export const API_BASE_URL = "http://3.38.58.247:8000";
export const API_BASE_URL = "https://cquest.sogang.ac.kr:8000";

export const TINYMCE_API_KEY =
  "1ocs2zh8o266q9xfq6ak3afsb95asbaj0eu9mcfr0rzbv4tt";

const USER_PERMISSION_TYPE = {
  MASTER: "MASTER",
};

export const ADMIN_PERMISSION = {
  EDIT_ARTICLES: [USER_PERMISSION_TYPE.MASTER],
};

export const FORUM_LIST_PAGE_SIZE = 10;
export const ADMIN_FORUM_LIST_PAGE_SIZE = 50;
export const ADMIN_EVENT_LIST_PAGE_SIZE = 50;

export const SINGLE_PAGE_ID = {
  OVERVIEW1: "overview-greetings",
  OVERVIEW2: "overview-introduction",
  OVERVIEW3: "overview-purpose",
  OVERVIEW4: "overview-organization",
  OVERVIEW5: "overview-contact",

  ACKNOWLEDGEMENT: "acknowledgement",

  COLLABORATION1: "collaboration-international",
  COLLABORATION2: "collaboration-popularization",

  EXTERNAL1: "external-institute",
  EXTERNAL2: "external-journal",
  EXTERNAL3: "external-others",
};

export const SINGLE_PAGE_ADMIN_NAME_ID_MAP = {
  acknowledgement: "사사",
  "overview-greetings": "인사말",
  "overview-introduction": "센터소개",
  "overview-purpose": "센터목적",
  "overview-organization": "센터조직도",
  "overview-contact": "센터연락처",

  "collaboration-international": "국제협력",
  "collaboration-popularization": "과학대중화",

  "external-institute": "관련기관",
  "external-journal": "학회 및 학술지",
  "external-others": "기타 사이트",
};

export const PATH = {
  LANDING: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  OVERVIEW1: "/overview/greetings",
  OVERVIEW2: "/overview/introduction",
  OVERVIEW3: "/overview/purpose",
  OVERVIEW4: "/overview/organization",
  OVERVIEW5: "/overview/contact",
  COLLABORATION1: "/collaboration/international",
  COLLABORATION2: "/collaboration/popularization",
  EXTERNAL1: "/external/institute",
  EXTERNAL2: "/external/journal",
  EXTERNAL3: "/external/other",
  MEMBERS1: "/members/core",
  MEMBERS2: "/members/research",
  MEMBERS3: "/members/students",
  MEMBERS4: "/members/visitors",
  MEMBERS5: "/members/alumni",
  ACHIEVEMENTS1: "/preprint",
  ACHIEVEMENTS2: "/published",
  ACHIEVEMENTS3: "/acknowledgement",
  FORUM1: "/forum/calendar",
  FORUM2: "/forum/conference",
  FORUM3: "/forum/seminar",
  FORUM4: "/forum/external",
  FORUM5: "/forum/announcement",
  FORUM6: "/forum/gallery",
  FORUM_GALLERY: "/forum/gallery/:galleryID",
  FORUM_ARTICLE: "/forum/:category/:forumID",
  EVENT_ARTICLE: "/event/:eventID",

  ADMIN_LANDING: "/admin",
  ADMIN_MEMBER_OPTION: "/admin/member",
  ADMIN_MEMBER_MEMBER_LIST: "/admin/member/member/list",
  ADMIN_MEMBER_MEMBER_EDIT: "/admin/member/member/detail/:memberID",
  ADMIN_MEMBER_VISITOR_EDIT: "/admin/member/member/visitor",
  ADMIN_MEMBER_PREVIOUS_EDIT: "/admin/member/member/previous",
  ADMIN_SINGLE_PAGE_LIST: "/admin/single-page/list",
  ADMIN_SINGLE_PAGE_EDIT: "/admin/single-page/:language/:singlePageID",
  ADMIN_IMAGE_SLIDER: "/admin/image-slider",
  ADMIN_FORUM_LIST: "/admin/forum",
  ADMIN_FORUM_EDIT: "/admin/forum/:forumID",
  ADMIN_FORUM_NEW: "/admin/forum/new",
  ADMIN_EVENT_PAGE_LIST: "/admin/event",
  ADMIN_EVENT_PAGE_EDIT: "/admin/event/:eventID",
  ADMIN_PAPER_LIST: "/admin/paper",
  ADMIN_PAPER_EDIT: "/admin/paper/:paperID",
  ADMIN_PHOTO_LIST: "/admin/photo",
  ADMIN_PHOTO_EDIT: "/admin/photo/:photoID",
  ADMIN_PHOTO_NEW: "/admin/photo/new",
  ADMIN_PHOTO_EDIT: "/admin/photo/edit/:galleryID",
};

export const MEMBER_CATEGORY = {
  KEY_MEMBER: {
    key: "KEY_MEMBER",
    KOREAN: "KEY MEMBERS",
    ENGLISH: "KEY MEMBERS",
  },
  RESEARCH_PROFESSOR: {
    key: "RESEARCH_PROFESSOR",
    KOREAN: "연구교수",
    ENGLISH: "RESEARCH PROFESSORS",
  },
  RESEARCHER: { key: "RESEARCHER", KOREAN: "연구원", ENGLISH: "RESEARCHERS" },
  EXTERNAL: {
    key: "EXTERNAL",
    KOREAN: "외부 교수", // will not be shown
    ENGLISH: "EXTERNAL PROFESSORS", // will not be shown
  },
  STUDENT: { key: "STUDENT", KOREAN: "STUDENTS", ENGLISH: "STUDENTS" },
  STAFF: { key: "STAFF", KOREAN: "STAFFS", ENGLISH: "STAFFS" },
  DEGREE_COMPLETION: {
    key: "DEGREE_COMPLETION",
    KOREAN: "DEGREE COMPLETION",
    ENGLISH: "DEGREE COMPLETION",
  },
};

export const ALUMNI_MEMBER_CATEGORY = {
  RESEARCH_PROFESSOR: "RESEARCH_PROFESSOR",
  RESEARCHER: "RESEARCHER",
  VISITOR_PROFESSOR: "VISITOR_PROFESSOR",
  EMERITUS: "EMERITUS",
  FULL_TIME_PROFESSOR: "FULL_TIME_PROFESSOR",
};

export const SIDE_PANEL_MENU = {
  overview: {
    title: {
      KOREAN: "센터소개",
      ENGLISH: "Overview",
    },
    items: [
      {
        title: {
          KOREAN: "인사말",
          ENGLISH: "Greetings",
        },

        path: PATH.OVERVIEW1,
      },
      {
        title: {
          KOREAN: "센터소개",
          ENGLISH: "Introduction",
        },
        path: PATH.OVERVIEW2,
      },
      {
        title: {
          KOREAN: "센터목적",
          ENGLISH: "Purpose",
        },
        path: PATH.OVERVIEW3,
      },
      {
        title: {
          KOREAN: "센터조직도",
          ENGLISH: "Organization",
        },
        path: PATH.OVERVIEW4,
      },
      {
        title: {
          KOREAN: "센터연락처",
          ENGLISH: "Contact",
        },
        path: PATH.OVERVIEW5,
      },
    ],
  },
  members: {
    title: {
      KOREAN: "연구진소개",
      ENGLISH: "Members",
    },
    items: [
      {
        title: {
          KOREAN: "전임연구인력",
          ENGLISH: "Tenured Researchers",
        },

        path: PATH.MEMBERS1,
      },
      {
        title: {
          KOREAN: "비전임연구인력",
          ENGLISH: "Non-tenured Researchers",
        },
        path: PATH.MEMBERS2,
      },
      {
        title: {
          KOREAN: "학생 연구원",
          ENGLISH: "Students",
        },
        path: PATH.MEMBERS3,
      },
      {
        title: {
          KOREAN: "방문 연구원",
          ENGLISH: "Visitors",
        },
        path: PATH.MEMBERS4,
      },
      {
        title: {
          KOREAN: "거쳐가신 분",
          ENGLISH: "Alumni",
        },
        path: PATH.MEMBERS5,
      },
    ],
  },
  achievements: {
    title: {
      KOREAN: "연구업적",
      ENGLISH: "Achievements",
    },
    items: [
      {
        title: {
          KOREAN: "게재논문",
          ENGLISH: "Preprint",
        },
        path: PATH.ACHIEVEMENTS1,
      },
      {
        title: {
          KOREAN: "출판논문",
          ENGLISH: "Published",
        },
        path: PATH.ACHIEVEMENTS2,
      },
      {
        title: {
          KOREAN: "사사",
          ENGLISH: "Acknowledgement",
        },
        path: PATH.ACHIEVEMENTS3,
      },
    ],
  },
  forum: {
    title: {
      KOREAN: "센터활동",
      ENGLISH: "Forum",
    },
    items: [
      {
        title: {
          KOREAN: "센터 일정",
          ENGLISH: "Calendar",
        },
        path: PATH.FORUM1,
      },
      {
        title: {
          KOREAN: "학회",
          ENGLISH: "Conference",
        },
        path: PATH.FORUM2,
      },
      {
        title: {
          KOREAN: "세미나",
          ENGLISH: "Seminar",
        },
        path: PATH.FORUM3,
      },
      {
        title: {
          KOREAN: "외부행사",
          ENGLISH: "External Events",
        },
        path: PATH.FORUM4,
      },
      {
        title: {
          KOREAN: "새소식",
          ENGLISH: "Announcement",
        },
        path: PATH.FORUM5,
      },
      {
        title: {
          KOREAN: "포토 갤러리",
          ENGLISH: "Photo Gallery",
        },
        path: PATH.FORUM6,
      },
    ],
  },
  collaboration: {
    title: {
      KOREAN: "대외협력",
      ENGLISH: "Collaboration",
    },
    items: [
      {
        title: {
          KOREAN: "국제협력",
          ENGLISH: "International Cooperation",
        },
        path: PATH.COLLABORATION1,
      },
      {
        title: {
          KOREAN: "산학협력과 과학대중화",
          ENGLISH:
            "Academic-Industry-cooperation and Popularization of Science",
        },
        path: PATH.COLLABORATION2,
      },
    ],
  },
  external: {
    title: {
      KOREAN: "관련사이트",
      ENGLISH: "External",
    },
    items: [
      {
        title: {
          KOREAN: "관련기관",
          ENGLISH: "Institute",
        },
        path: PATH.EXTERNAL1,
      },
      {
        title: {
          KOREAN: "학회 및 학술지",
          ENGLISH: "Journal",
        },
        path: PATH.EXTERNAL2,
      },
      {
        title: {
          KOREAN: "기타 사이트",
          ENGLISH: "Others",
        },
        path: PATH.EXTERNAL3,
      },
    ],
  },
  admin: {
    title: {
      KOREAN: "관리자",
      ENGLISH: "admin",
    },
    items: [
      {
        title: {
          KOREAN: "연구진 관리",
          ENGLISH: "연구진 관리",
        },
        path: PATH.ADMIN_MEMBER_OPTION,
      },
      {
        title: {
          KOREAN: "배너 이미지 관리",
          ENGLISH: "배너 이미지 관리",
        },
        path: PATH.ADMIN_IMAGE_SLIDER,
      },
      {
        title: {
          KOREAN: "단일 페이지 관리",
          ENGLISH: "단일 페이지 관리",
        },
        path: PATH.ADMIN_SINGLE_PAGE_LIST,
      },
      {
        title: {
          KOREAN: "센터활동 관리",
          ENGLISH: "센터활동 관리",
        },
        path: PATH.ADMIN_FORUM_LIST,
      },
      {
        title: {
          KOREAN: "연구업적 관리",
          ENGLISH: "연구업적 관리",
        },
        path: PATH.ADMIN_PAPER_LIST,
      },
      {
        title: {
          KOREAN: "이벤트 페이지 관리",
          ENGLISH: "이벤트 페이지 관리",
        },
        path: PATH.ADMIN_EVENT_PAGE_LIST,
      },
      {
        title: {
          KOREAN: "포토 갤러리 관리",
          ENGLISH: "포토 갤러리 관리",
        },
        path: PATH.ADMIN_PHOTO_LIST,
      },
      {
        title: {
          KOREAN: "로그아웃",
          ENGLISH: "로그아웃",
        },
        path: PATH.LOGOUT,
      },
    ],
  },
};

export const TABLE_COLUMNS = {
  VISITOR_MEMBERS: {
    KOREAN: [
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "소속",
        dataIndex: "affiliation",
        key: "affiliation",
      },
      {
        title: "체류 기간",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "초청자",
        dataIndex: "inviter",
        key: "inviter",
      },
      /** 수정 열은 adminPage에서만 보여집니다.  */
      {
        title: "",
        dateIndex: "",
        key: "",
        render: (text, record) => <a onClick={record.onClick}>수정</a>,
      },
    ],
    ENGLISH: [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Affiliation",
        dataIndex: "affiliation",
        key: "affiliation",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Inviter",
        dataIndex: "inviter",
        key: "inviter",
      },
      /** 수정 열은 adminPage에서만 보여집니다.  */
      {
        title: "",
        dateIndex: "",
        key: "",
        render: (text, record) => <a onClick={record.onClick}>수정</a>,
      },
    ],
  },
  ALUMNI_MEMBERS: {
    KOREAN: [
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "소속",
        dataIndex: "affiliation",
        key: "affiliation",
      },
      {
        title: "이메일",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "고용 기간",
        dataIndex: "date",
        key: "date",
      },
      /** 수정 열은 adminPage에서만 보여집니다.  */
      {
        title: "",
        dateIndex: "",
        key: "",
        render: (text, record) => <a onClick={record.onClick}>수정</a>,
      },
    ],
    ENGLISH: [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Affiliation",
        dataIndex: "affiliation",
        key: "affiliation",
      },
      {
        title: "E-Mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Date of Employment",
        dataIndex: "date",
        key: "date",
      },
      /** 수정 열은 adminPage에서만 보여집니다.  */
      {
        title: "",
        dateIndex: "",
        key: "",
        render: (text, record) => <a onClick={record.onClick}>수정</a>,
      },
    ],
  },
};

export const FORUM_CATEGORY = {
  CONFERENCE: {
    id: "CONFERENCE",
    koreanName: "학회",
    englishName: "Conference",
    color: "#d15980",
    pathID: "FORUM_CONFERENCE",
    articlePathID: "FORUM_CONFERENCE_ARTICLE",
  },
  SEMINAR: {
    id: "SEMINAR",
    koreanName: "세미나",
    englishName: "Seminar",
    color: "#323278",
    pathID: "FORUM_SEMINAR",
    articlePathID: "FORUM_SEMINAR_ARTICLE",
  },
  EXTERNAL: {
    id: "EXTERNAL",
    koreanName: "외부행사",
    englishName: "External Event",
    color: "#648b7f",
    pathID: "FORUM_EXTERNAL",
    articlePathID: "FORUM_EXTERNAL_ARTICLE",
  },
  ANNOUNCEMENT: {
    id: "ANNOUNCEMENT",
    koreanName: "새소식",
    englishName: "Announcement",
    color: "#ffd773",
    pathID: "FORUM_ANNOUNCEMENT",
    articlePathID: "FORUM_ANNOUNCEMENT_ARTICLE",
  },
};
