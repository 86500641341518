import React from "react";
import { LayoutForm2 } from "components/layout";
import { SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Forum from "components/forum";
import Spinner from "components/spinner";
import * as api from "services/api";
import { useLocation, useParams } from "react-router";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";
import useStore from "services/store";
import { Divider } from "antd";

const ForumGalleryView = observer(() => {
  const { action } = useStore();
  const location = useLocation();
  const { galleryID } = useParams();

  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [detailData, setDetailData] = React.useState({});

  // list data
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    initializeData();
  }, [location]);

  const initializeData = async () => {
    const queryParams = new URLSearchParams(location.search);

    const [response] = await Promise.all([
      action.getPhotoGalleryDetail(galleryID),
    ]);

    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return;
    }

    setDetailData(response.data);
    setPageState(PAGE_STATE.NORMAL);
  };

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.forum}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Forum.GalleryDetail photoGalleryArticleModel={detailData} />
          {/* <Divider style={{ marginTop: 20, marginBottom: 40 }} />
          <Forum.GalleryList data={data} showHeader={false} /> */}
        </>
      )}
    </LayoutForm2>
  );
});

export default ForumGalleryView;
