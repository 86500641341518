import { grey } from "@ant-design/colors";
import React from "react";

const TEXT = [
  "프로필 이미지는 2MB 이내의 JPG 또는 PNG만 가능하며, 올바른 작동을 위해 정방형 이미지를 권장드립니다.",
  "기본 정렬은 <우선순위 내림차순>이며, 우선순위는 정수 데이터입니다.",
  "동일 우선순위의 경우 [연구교수], [연구원]은 <임용날짜 오름차순>, 그 외의 항목은 <이름(국문) 오름차순>으로 정렬됩니다.",
];
const Description = () => {
  return (
    <>
      <div style={{ paddingBottom: 50 }}>
        {TEXT.map((text, idx) => (
          <span style={{ color: grey[2], display: "block" }} key={idx}>
            {text}
          </span>
        ))}
      </div>
    </>
  );
};

export default Description;
