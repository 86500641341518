import React from "react";
import { Col, Menu, Row } from "antd";
import { observer } from "mobx-react";
import useStore from "services/store";
import { grey, blue } from "@ant-design/colors";
import { Link } from "react-router-dom";

const isMenuFocused = (path) => window.location.pathname.startsWith(path);

const SidePanel = observer(({ sidePanelMenu }) => {
  const { language } = useStore();
  return (
    <Row>
      <Col xs={0} lg={24}>
        <div style={{ width: 300, marginTop: 100 }}>
          <span
            style={{
              paddingTop: 40,
              marginBottom: 20,
              display: "block",
              paddingLeft: 44,
              fontSize: 20,
              fontWeight: "bold",
              color: grey.primary,
            }}
          >
            {sidePanelMenu.title[language]}
          </span>
          <Menu
            mode="inline"
            inlineIndent={58}
            //   inlineCollapsed={this.state.collapsed}
          >
            {sidePanelMenu.items.map((item, idx) => (
              <Menu.Item
                key={idx}
                style={
                  isMenuFocused(item.path)
                    ? {
                        background: "#e6f7ff",
                        whiteSpace: "normal",
                        height: "auto",
                        lineHeight: "30px",
                      }
                    : {
                        whiteSpace: "normal",
                        height: "auto",
                        lineHeight: "30px",
                      }
                }
              >
                <Link
                  to={item.path}
                  style={
                    isMenuFocused(item.path)
                      ? {
                          fontSize: 18,

                          fontWeight: "bold",
                          color: blue.primary,
                        }
                      : { fontSize: 18, color: grey[2] }
                  }
                >
                  {item.title[language]}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </Col>
    </Row>
  );
});

export default SidePanel;
