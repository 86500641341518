import { useMemo } from "react";
import { applySnapshot, onSnapshot, types } from "mobx-state-tree";
import Action from "./action";
import AdminPaperStore from "./adminPaperStore";

const Store = types
  .model("App", {
    adminPaperStore: AdminPaperStore,
    action: Action,
    device: types.optional(types.enumeration(["DESKTOP", "MOBILE"]), "DESKTOP"),
    token: types.optional(types.string, ""),
    language: types.optional(
      types.enumeration(["KOREAN", "ENGLISH"]),
      "KOREAN"
    ),
  })
  .actions((self) => ({
    set(kvDict) {
      for (const key of Object.keys(kvDict)) {
        self[key] = kvDict[key];
      }
    },
    setToken(token) {
      self.token = token;
    },
    setDevice(device) {
      self.device = device;
    },
    setLanguage(language) {
      self.language = language;
    },
  }))
  .views((self) => ({}));

/**
 *  Make states persist.
 *  c.f. https://stackoverflow.com/questions/50887863
 *       https://stackoverflow.com/questions/42921220
 */
const asyncLocalStorage = {
  setItem: async function (key, value) {
    await Promise.resolve();
    localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await Promise.resolve();
    return localStorage.getItem(key);
  },
};
const persist = (name, store) => {
  onSnapshot(store, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    asyncLocalStorage.setItem(name, data);
  });
  asyncLocalStorage.getItem(name).then((data) => {
    if (data !== null) {
      const snapshot = JSON.parse(data);
      applySnapshot(store, snapshot);
    }
  });
};

let _store;
const useStore = () => {
  const store = useMemo(() => {
    if (!_store)
      _store = Store.create({
        action: Action.create(),
        adminPaperStore: AdminPaperStore.create(),
      });

    persist("CQUEST_APP_20211202", _store);
    return _store;
  }, []);
  return store;
};

export default useStore;
