import React from "react";
import { Table } from "antd";
import { PATH } from "app/config";
import { Link } from "react-router-dom";

const List = ({ data }) => {
  const columns = [
    {
      title: "페이지 ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "카테고리명",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "국문 버전",
      dataIndex: "korean",
      key: "korean",
      render: (item, data) => (
        <div>
          {item} &nbsp;&nbsp;(
          <Link
            to={PATH.ADMIN_SINGLE_PAGE_EDIT.replace(
              ":language",
              "korean"
            ).replace(":singlePageID", data.id)}
          >
            수정하기
          </Link>
          )
        </div>
      ),
    },
    {
      title: "영문 버전",
      dataIndex: "english",
      key: "english",
      render: (item, data) => (
        <div>
          {item} &nbsp;&nbsp;(
          <Link
            to={PATH.ADMIN_SINGLE_PAGE_EDIT.replace(
              ":language",
              "english"
            ).replace(":singlePageID", data.id)}
          >
            수정하기
          </Link>
          )
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    ></Table>
  );
};

export default List;
