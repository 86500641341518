import React from "react";
import { Form, Input, Space, Button, Divider, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { MEMBER_CATEGORY } from "app/config";

const Forms = ({
  category,
  setCategory,
  koreanName,
  setKoreanName,
  englishName,
  setEnglishName,
  priority,
  setPriority,
  email,
  setEmail,
  koreanFieldsKey,
  setKoreanFieldsKey,
  koreanFieldsValue,
  setKoreanFieldsValue,
  englishFieldsKey,
  setEnglishFieldsKey,
  englishFieldsValue,
  setEnglishFieldsValue,
  initialKoreanFields,
  initialEnglishFields,
}) => {
  React.useEffect(() => {
    // if category is set, initialize its default key value
    if (category) {
      setCategory(
        Object.keys(MEMBER_CATEGORY).find(
          (key) => MEMBER_CATEGORY[key].key === category
        )
      );
    } else {
      // if not, set the first item as default value
      setCategory(MEMBER_CATEGORY[Object.keys(MEMBER_CATEGORY)[0]].key);
    }
  }, []);

  const validateInteger = React.useCallback((_, value) => {
    const regExp = /^(0|[1-9][0-9]*)$/;
    if (!value) {
      return Promise.reject(new Error("필수 입력 항목입니다."));
    }
    if (!value.match(regExp)) {
      return Promise.reject(new Error("정수 값을 입력해주세요."));
    }
    return Promise.resolve();
  }, []);

  return (
    <Form
      name="dynamic_form_nest_item"
      //   onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="분류"
        name="category"
        colon={false}
        labelCol={{ span: 7 }}
        rules={[{ required: true, message: "필수 입력 항목입니다." }]}
      >
        <Select
          onChange={(value) => setCategory(value)}
          defaultValue={
            MEMBER_CATEGORY[category]?.ENGLISH ||
            MEMBER_CATEGORY[Object.keys(MEMBER_CATEGORY)[0]].ENGLISH
          }
        >
          {Object.keys(MEMBER_CATEGORY).map((key, idx) => (
            <Select.Option value={MEMBER_CATEGORY[key].key}>
              {MEMBER_CATEGORY[key].ENGLISH}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="이름(국문)"
        name="name-korean"
        colon={false}
        labelCol={{ span: 7 }}
        rules={[{ required: true, message: "필수 입력 항목입니다." }]}
      >
        <Input
          spellCheck={false}
          value={koreanName}
          defaultValue={koreanName}
          onChange={(e) => setKoreanName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="이름(영문)"
        name="name-english"
        colon={false}
        labelCol={{ span: 7 }}
        rules={[{ required: true, message: "필수 입력 항목입니다." }]}
      >
        <Input
          spellCheck={false}
          value={englishName}
          defaultValue={englishName}
          onChange={(e) => setEnglishName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="우선순위"
        name="priority"
        colon={false}
        labelCol={{ span: 7 }}
        rules={[
          {
            validator: validateInteger,
          },
        ]}
      >
        <Input
          spellCheck={false}
          defaultValue={priority}
          value={priority}
          defaultValue={priority}
          onChange={(e) => setPriority(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="이메일"
        name="email"
        colon={false}
        labelCol={{ span: 7 }}
      >
        <Input
          spellCheck={false}
          autoComplete={"off"}
          value={email}
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Divider />
      <Form.List name="fields-korean" initialValue={initialKoreanFields}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  width: "100%",
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, "key"]}
                  fieldKey={[fieldKey, "key"]}
                  style={{ width: 168 }}
                  rules={[
                    {
                      required: true,
                      message: "빈 값으로 할 수 없습니다.",
                    },
                  ]}
                >
                  <Input
                    placeholder={"국문 필드 키"}
                    style={{ textAlign: "right" }}
                    value={koreanFieldsKey[key]}
                    onChange={(e) =>
                      setKoreanFieldsKey({ [key]: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "value"]}
                  fieldKey={[fieldKey, "value"]}
                  style={{ width: 400 }}
                  rules={[
                    {
                      required: true,
                      message: "빈 값으로 할 수 없습니다.",
                    },
                  ]}
                >
                  <Input
                    placeholder="국문 필드 값"
                    value={koreanFieldsValue[key]}
                    onChange={(e) =>
                      setKoreanFieldsValue({ [key]: e.target.value })
                    }
                  />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => {
                    remove(name);
                    setKoreanFieldsKey({ [key]: "" });
                    setKoreanFieldsValue({ [key]: "" });
                  }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                국문 필드 추가
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.List name="fields-english" initialValue={initialEnglishFields}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: 8,
                  width: "100%",
                }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, "key"]}
                  fieldKey={[fieldKey, "key"]}
                  style={{ width: 168 }}
                  rules={[
                    {
                      required: true,
                      message: "빈 값으로 할 수 없습니다.",
                    },
                  ]}
                >
                  <Input
                    placeholder={"영문 필드 키"}
                    style={{ textAlign: "right" }}
                    value={englishFieldsKey[key]}
                    onChange={(e) =>
                      setEnglishFieldsKey({ [key]: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "value"]}
                  fieldKey={[fieldKey, "value"]}
                  style={{ width: 400 }}
                  rules={[
                    {
                      required: true,
                      message: "빈 값으로 할 수 없습니다.",
                    },
                  ]}
                >
                  <Input
                    placeholder="영문 필드 값"
                    value={englishFieldsValue[key]}
                    onChange={(e) =>
                      setEnglishFieldsValue({ [key]: e.target.value })
                    }
                  />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => {
                    remove(name);
                    setEnglishFieldsKey({ [key]: "" });
                    setEnglishFieldsValue({ [key]: "" });
                  }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                영문 필드 추가
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default Forms;
