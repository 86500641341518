import React from "react";
import { Upload, message } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const ImageUpload = ({ image, setImage }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setIsLoading(false);
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "inline-block",
          verticalAlign: "bottom",
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="member-profile-uploader"
          showUploadList={false}
          customRequest={fakeRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {image ? (
            <img src={image} alt="avatar" style={{ width: "100%" }} />
          ) : (
            <div>
              {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>이미지 추가</div>
            </div>
          )}
        </Upload>
      </div>
      {image ? (
        <DeleteOutlined
          onClick={() => setImage("")}
          style={{
            display: "inline-block",
            cursor: "pointer",
            color: "#b60005",
            fontSize: 16,
            verticalAlign: "bottom",
            marginBottom: 10,
          }}
        />
      ) : null}
    </div>
  );
};

/** Mock Requeset in order to bypass Antd Upload logic */
const fakeRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

/** Get Base64 of uploaded image file */
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/** Filter image file */
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("JPG 또는 PNG 파일만 업로드할 수 있습니다.");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("이미지 파일의 크기는 2MB 이내로 해주세요.");
  }
  return isJpgOrPng && isLt2M;
};

export default ImageUpload;
