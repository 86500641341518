import React from "react";
import styled from "styled-components";

const Viewer = ({ data }) => {
  return (
    <Container className="mce-content-body">
      <div
        dangerouslySetInnerHTML={{ __html: data }}
        className="single-page-container"
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 200px;
`;

export default Viewer;
