import React from "react";
import styled from "styled-components";
import MapIconPNG from "graphic/landingPanel/map.png";
import PaperIconPNG from "graphic/landingPanel/paper.png";
import CalendarIconPNG from "graphic/landingPanel/newCalendar.png";
import GalleryIconPNG from "graphic/landingPanel/gallery.png";
import { observer } from "mobx-react";
import useStores from "services/store";
import { useHistory } from "react-router";
import { PATH } from "app/config";

const TEXT_MAP = {
  DIRECTION: {
    KOREAN: "오시는 길",
    ENGLISH: "Direction",
  },
  DIRECTION_DESCRIPTION: {
    KOREAN: "센터로 오시는 길을 알려드립니다",
    ENGLISH: "How to come",
  },
  PAPER: { KOREAN: "논문", ENGLISH: "Publications" },
  PAPER_DESCRIPTION: {
    KOREAN: "논문 상세 페이지로 이동합니다",
    ENGLISH: "Moving on detailed list",
  },
  EVENT: {
    KOREAN: "이달의 행사",
    ENGLISH: "Monthly Events",
  },
  EVENT_DESCRIPTION: {
    KOREAN: "센터의 행사를 확인하실 수 있습니다",
    ENGLISH: "Center for Quantum Spacetime",
  },
  GALLERY: {
    KOREAN: "포토 갤러리",
    ENGLISH: "Photo Gallery",
  },
  GALLERY_DESCRIPTION: {
    KOREAN: "센터의 활동 사진 페이지로 이동합니다",
    ENGLISH: "Moving on to photo gallery",
  },
};

export default observer(() => {
  const history = useHistory();
  const { language } = useStores();

  return (
    <Container>
      <Banner
        isFirstItem={true}
        onClick={() => {
          history.push(PATH.OVERVIEW5);
        }}
      >
        <Icon src={MapIconPNG} />
        <Text>
          <Title>{TEXT_MAP.DIRECTION[language]}</Title>
          <Description>{TEXT_MAP.DIRECTION_DESCRIPTION[language]}</Description>
        </Text>
      </Banner>
      <Banner
        onClick={() => {
          history.push(PATH.ACHIEVEMENTS1);
        }}
      >
        <Icon src={PaperIconPNG} />
        <Text>
          <Title>{TEXT_MAP.PAPER[language]}</Title>
          <Description>{TEXT_MAP.PAPER_DESCRIPTION[language]}</Description>
        </Text>
      </Banner>
      <Banner
        onClick={() => {
          history.push(PATH.FORUM1);
        }}
      >
        <Icon src={CalendarIconPNG} />
        <Text>
          <Title>{TEXT_MAP.EVENT[language]}</Title>
          <Description>{TEXT_MAP.EVENT_DESCRIPTION[language]}</Description>
        </Text>
      </Banner>
      <Banner
        onClick={() => {
          history.push(PATH.FORUM6);
        }}
      >
        <Icon src={GalleryIconPNG} />
        <Text>
          <Title>{TEXT_MAP.GALLERY[language]}</Title>
          <Description>{TEXT_MAP.GALLERY_DESCRIPTION[language]}</Description>
        </Text>
      </Banner>
    </Container>
  );
});

const Container = styled.div`
  margin-top: 140px;
  padding-left: 44px;
  width: 300px;
`;

const Banner = styled.div`
  padding: 12px 0;
  width: ${300 - 44}px;
  border: 1px solid #ddd;
  border-top-width: ${(props) => (props.isFirstItem ? "1px" : "0px")};
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: #000000;
  transition: all 0.2s ease-in-out;

  &&:hover {
    color: #0976db;
  }
`;

const Icon = styled.img`
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: inherit;
`;

const Description = styled.div`
  margin-top: 0px;
  font-size: 12px;
  color: #808080;
`;

const Text = styled.div`
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
`;
