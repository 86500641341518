import { grey } from "@ant-design/colors";
import { observer } from "mobx-react";
import React from "react";
import useStore from "services/store";

export const Title1 = observer(({ textMap = {}, subTextMap = {} }) => {
  const { language } = useStore();
  return (
    <>
      <span
        style={{
          display: "inline-block",
          fontSize: 32,
          color: "#000",
          marginBottom: 40,
        }}
      >
        {textMap[language]}
      </span>
      <span
        style={{
          display: "inline-block",
          fontSize: 24,
          color: grey[5],
          paddingLeft: 20,
        }}
      >
        {subTextMap[language]}
      </span>
    </>
  );
});

export const Title2 = observer(({ textMap }) => {
  const { language } = useStore();
  return (
    <span
      style={{
        display: "block",
        fontSize: 24,
        color: grey[2],
        marginTop: -40, // ignore parent margin
        marginBottom: 40,
      }}
    >
      {textMap[language]}
    </span>
  );
});
