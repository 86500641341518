import React from "react";
import styled from "styled-components";
import RUG from "react-upload-gallery";
// Add style manually
import "react-upload-gallery/dist/style.css"; // or scss
import useStore from "services/store";
import { API_BASE_URL } from "app/config";
import axios from "axios";
import Header from "./header";

/**
 * 이미지를 base64로 변환합니다.
 */
function getBase64(file) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const ImageUploader = ({ initialState, setState }) => {
  const { action } = useStore();

  const customRequest = async ({
    uid,
    file,
    send,
    action,
    headers,
    onProgress,
    onSuccess,
    onError,
  }) => {
    let base64String = await getBase64(file);
    console.warn(file.name);
    base64String = `${file.name};base64string,${
      base64String.split(";base64,")[1]
    }`;

    // console.log(file.name);

    //   base64String = filename.slice()
    // console.log(base64String);
    const form = new FormData();

    // send file
    form.append("file", file);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .post(
        `${API_BASE_URL}/image`,
        { image: base64String },
        {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(uid, Math.round((loaded / total) * 100));
          },
          cancelToken: source.token,
        }
      )
      .then(({ data: response }) => {
        onSuccess(uid, response);
      })
      .catch((error) => {
        onError(uid, {
          action,
          status: error.request,
          response: error.response,
        });
      });

    return {
      abort() {
        source.cancel();
      },
    };
  };

  return (
    <RUG
      initialState={initialState}
      header={Header}
      customRequest={customRequest}
      accept={["jpg", "jpeg", "png", "gif"]}
      onChange={(data) => {
        setState(data.map((item) => item.source));
      }}
      source={(response) => {
        return `${API_BASE_URL}${response}`;
      }}
      onSortEnd={(images, { oldIndex, newIndex }) => {
        setState(images.map((item) => item.source));
      }}
    />
  );
};

export default ImageUploader;
