import React from "react";
import { LayoutForm2 } from "components/layout";
import { SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Forum from "components/forum";
import Spinner from "components/spinner";
import { useLocation } from "react-router";
import useStore from "services/store";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";
import Header from "components/header";

const Forum2View = observer(() => {
  const { action, language } = useStore();
  const location = useLocation();
  const [pageState, setPageState] = React.useState("PENDING");
  const [listItem, setListItem] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);

  /**
   * 포럼 데이터를 가져오는 함수입니다.
   */
  const initializeData = async () => {
    // 1. 페이지 패러미터를 확인합니다.
    const queryParams = new URLSearchParams(location.search);

    // 2. 서버에 데이터를 요청합니다.
    const responseList = await Promise.all([
      action.getForumList(
        "CONFERENCE",
        parseInt(queryParams.get("page") || 1),
        queryParams.get("query") || "",
        queryParams.get("type") || "title"
      ),
      action.getPinnedForumList("CONFERENCE"),
    ]);

    // 3. 데이터 요청에 문제가 생길 시, 에러 상태로 변경하고 함수를 종료합니다.
    if (!responseList.every((item) => item.status === REQUEST_STATUS.SUCCESS)) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 4. 아티클 데이터와 페이지네이션을 저장합니다.
    const [response, pinnedResponse] = responseList;
    setListItem(pinnedResponse.data.concat(response.data.results));
    setTotalCount(response.data.count);

    // 5. 페이지 상태를 '정상'으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.forum}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Forum.List
            title={language === "KOREAN" ? "학회" : "CONFERENCE"}
            data={listItem}
            totalCount={totalCount}
          />
        </>
      )}
    </LayoutForm2>
  );
});

export default Forum2View;
