import moment from "moment";
import { DatePicker, Input, Select, Checkbox, message } from "antd";
import Header from "components/header";
import React from "react";
import styled from "styled-components";
import OriginalSunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

import { STATUS } from "app/constants";
import { useHistory, useParams } from "react-router";
import { API_BASE_URL, PATH, TINYMCE_API_KEY } from "app/config";
import DeleteButton from "./deleteButton";
import Attachments from "./attachments";
import TextEditor from "components/new/textEditor";
import useStore from "services/store";

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Forum 아티클을 수정하는 에디터 컴포넌트입니다.
 */
const Editor = ({ initialData }) => {
  const { action } = useStore();

  // 헤더 데이터 상태를 관리합니다.
  const [headerData, _setHeaderData] = React.useState({
    id: initialData.id,
    title: initialData.title,
    participants: initialData.participants,
    category: initialData.category,
    isPinned: initialData.isPinned,
    isSingleDate: initialData.isSingleDate,
    isLegacy: initialData.isLegacy,
  });
  const setHeaderData = (obj) =>
    _setHeaderData((prev) => ({ ...prev, ...obj }));

  // 첨부파일 데이터를 관리합니다.
  const [attachments, _setAttachments] = React.useState({
    첨부파일1: initialData.file1,
    첨부파일2: initialData.file2,
    첨부파일3: initialData.file3,
    첨부파일4: initialData.file4,
    첨부파일5: initialData.file5,
  });
  const setAttachments = (obj) =>
    _setAttachments((prev) => ({ ...prev, ...obj }));

  // 아티클 본문 데이터를 관리합니다.
  const [body, setBody] = React.useState(initialData.body);
  const editorRef = React.useRef(null); // // TinyMCE 에디터에 Ref를 사용합니다.

  const textEditorRef = React.useRef();
  const history = useHistory();

  // moment sample code 그대로 사용:
  // moment 관련 코드를 정의합니다.
  const [singleDate, setSingleDate] = React.useState(); // moment Object
  const [_dates, _setDates] = React.useState([]);
  const [_hackValue, _setHackValue] = React.useState();
  const [rangeDate, setRangeDate] = React.useState(); // moment Objects
  const disabledDate = (current) => {
    if (!_dates || _dates.length === 0) {
      return false;
    }
    const tooEarly = _dates[1] && _dates[1].diff(current, "days") > 7;
    return tooEarly;
  };

  const onOpenChange = (open) => {
    if (open) {
      _setHackValue([]);
      _setDates([]);
    } else {
      _setHackValue(undefined);
    }
  };

  React.useEffect(() => {
    // 이벤트 날짜 데이터를  moment 형식으로 변경해서 저장합니다.
    if (initialData.isSingleDate) {
      setHeaderData({ isSingle: true });
      if (moment(initialData.singleDate).isValid()) {
        // singleDate 값이 없을 경우, 빈칸을, 그 외의 경우에는 moment 규격에 맞는 데이터를 저장합니다.
        setSingleDate(moment(initialData.singleDate));
      }
    } else {
      setHeaderData({ isSingle: false });
      if (
        moment(initialData.rangeBeginDate).isValid() &&
        moment(initialData.rangeEndDate).isValid()
      ) {
        setRangeDate([
          ...[
            moment(initialData.rangeBeginDate),
            moment(initialData.rangeEndDate),
          ],
        ]);
      }
    }
  }, []);

  /**
   * 기존 포럼 데이터 수정을 요청합니다.
   */
  const onEdit = async () => {
    // 1. 데이터를 가져온 후, 유효성 검사를 시행합니다.
    const data = _getFinalData();
    if (!isDataValid(data)) return;

    // 2. 데이터 PATCH를 시도하고, 성공할 시 해당 페이지로 이동합니다.
    const response = await action.updateForum(data);
    if (response.status !== STATUS.SUCCESS) {
      console.warn(response);
      return message.error("데이터 수정에 실패했습니다.");
    }
    message.success("성공적으로 수정했습니다.");
    history.push(
      PATH.FORUM_ARTICLE.replace(
        ":category",
        data.category.toLowerCase()
      ).replace(":forumID", data.id)
    );
  };

  /**
   * 새 포럼 데이터 생성을 요청합니다.
   */
  const onCreate = async () => {
    // 1. 데이터를 가져온 후, 유효성 검사를 시행합니다.
    const data = _getFinalData();
    if (!isDataValid(data)) return;

    // 2. 데이터 POST를 시도하고, 성공할 시 새 탭에 해당 페이지를 띄우고,
    // 본 페이지는 뒤로 이동합니다.
    const response = await action.createForum(data);
    if (response.status !== STATUS.SUCCESS) {
      return message.error("게시글 작성에 실패했습니다.");
    }

    message.success("성공적으로 게시글을 작성했습니다.");
    history.push(PATH.ADMIN_FORUM_LIST);

    window.open(
      PATH.FORUM_ARTICLE.replace(
        ":category",
        data.category.toLowerCase()
      ).replace(":forumID", response.data.id),
      "_blank"
    );
  };

  /**
   * 현재 입력된 데이터를 POST/PATCH 콜에 적합한 형태로 가공합니다.
   */
  const _getFinalData = () => {
    let dateData = {};

    if (headerData.isSingleDate) {
      dateData["isSingleDate"] = true;
      dateData["singleDate"] =
        (singleDate && singleDate.format("YYYY-MM-DD HH:mmZ")) || null;
      // isSingleDate가 참일 경우, rangeDate 값을 null로 비웁니다.
      dateData["rangeBeginDate"] = null;
      dateData["rangeEndDate"] = null;
    } else {
      dateData["isSingleDate"] = false;
      dateData["singleDate"] = null;
      dateData["rangeBeginDate"] =
        (rangeDate && rangeDate[0].format("YYYY-MM-DD HH:mmZ")) || null;
      dateData["rangeEndDate"] =
        (rangeDate && rangeDate[1].format("YYYY-MM-DD HH:mmZ")) || null;
    }

    // 만약 [복수 선택]을 클릭하고 빈칸으로 만들었다면, isSingleDate를 참으로 변경합니다.
    if (
      dateData["rangeBeginDate"] === null &&
      dateData["rangeEndDate"] === null
    )
      dateData["isSingleDate"] = true;

    const finalData = {
      ...{
        id: headerData.id,
        title: headerData.title,
        isPinned: headerData.isPinned,
        body: headerData.isLegacy ? body : editorRef.current?.getContent(),
        category: headerData.category,
        participants:
          headerData.category === "SEMINAR" ? headerData.participants : "",
        file1: attachments["첨부파일1"],
        file2: attachments["첨부파일2"],
        file3: attachments["첨부파일3"],
        file4: attachments["첨부파일4"],
        file5: attachments["첨부파일5"],
        isLegacy: initialData.isLegacy,
      },
      ...dateData,
    };

    return finalData;
  };

  /* 데이터가 정상적인지 체크합니다. */
  const isDataValid = (data) => {
    if (!data.title) {
      message.error("제목을 입력해주세요.");
      return false;
    }

    return true;
  };

  return (
    <Container>
      <Header
        title={!initialData.id ? "새 아티클 작성" : "아티클 수정"}
        onSave={!initialData.id ? onCreate : onEdit}
        onSaveText={!initialData.id ? "생성" : "저장"}
      />
      <Input
        value={headerData.title}
        onChange={(e) => setHeaderData({ title: e.target.value })}
        placeholder="아티클 제목"
        maxLength={200}
        style={{ fontSize: "18px", padding: 8, marginBottom: 10 }}
      />
      <Input.Group compact style={{ marginBottom: 50 }}>
        <Select
          value={headerData.category}
          onChange={(value) => setHeaderData({ category: value })}
          style={{ width: "200px" }}
        >
          <Option value="CONFERENCE">학회</Option>
          <Option value="SEMINAR">세미나</Option>
          <Option value="EXTERNAL">외부행사</Option>
          <Option value="ANNOUNCEMENT">새소식</Option>
        </Select>
        {headerData.category === "SEMINAR" && (
          <Input
            maxLength={100}
            value={headerData.participants}
            onChange={(e) => setHeaderData({ participants: e.target.value })}
            style={{ width: "240px", marginLeft: 20 }}
            placeholder="발표자"
          />
        )}

        {headerData.isSingleDate && (
          <>
            <DatePicker
              value={singleDate}
              onChange={(momentObject) => {
                setSingleDate(momentObject);
              }}
              style={{ marginLeft: 20 }}
              format="YYYY-MM-DD HH:mm"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              placeholder={"행사 일자 및 시각"}
            />
            <span
              onClick={() => {
                setHeaderData({ isSingleDate: false });
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                verticalAlign: "bottom",
                cursor: "pointer",
                color: "#1890ff",
              }}
            >
              복수 날짜 선택
            </span>
          </>
        )}
        {!headerData.isSingleDate && (
          <>
            <RangePicker
              style={{ marginLeft: 20 }}
              value={_hackValue || rangeDate}
              disabledDate={disabledDate}
              onCalendarChange={(val) => _setDates(val)}
              onChange={(val) => setRangeDate(val)}
              onOpenChange={onOpenChange}
              placeholder={["행사 시작 일자", "행사 종료 일자"]}
            />
            <span
              onClick={() => {
                setHeaderData({ isSingleDate: true });
              }}
              style={{
                marginLeft: 10,
                display: "inline-block",
                verticalAlign: "bottom",
                cursor: "pointer",
                color: "#1890ff",
              }}
            >
              단일 날짜 선택
            </span>
          </>
        )}
        <Checkbox.Group
          style={{
            float: "right",
            userSelect: "none",
            marginTop: 10,
          }}
        >
          <label style={{ color: "#444", fontSize: 14 }}>
            <input
              type="checkbox"
              checked={headerData.isPinned}
              onChange={(e) => setHeaderData({ isPinned: e.target.checked })}
            />
            &nbsp;&nbsp;아티클 상단 고정
          </label>
        </Checkbox.Group>
      </Input.Group>
      {/** legacy 모드일경우, 기존 SunEditor를 사용합니다. 그렇지 않다면, TinyMCE를 사용합니다. */}
      {initialData.isLegacy ? (
        <OriginalSunEditor
          width="100%"
          height="500px"
          defaultValue={body}
          onChange={(content) => setBody(content)}
          setOptions={{
            buttonList: buttonList.complex,
            templates: [],
            callBackSave: !initialData.id ? onCreate : onEdit,
          }}
          setDefaultStyle="font-size:16px; line-height: 1.5"
        />
      ) : (
        <TextEditor editorRef={editorRef} initialValue={initialData.body} />
      )}

      <UploadArea>
        <Attachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </UploadArea>
      {headerData.id && (
        <>
          <div style={{ height: 100 }} />
          <DeleteButton forumID={headerData.id} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 120px;
`;

const UploadArea = styled.div`
  margin-top: 100px;
`;

export default Editor;
