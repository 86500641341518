import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router";
import Header from "components/header";
import useStore from "services/store";
import Forum from "components/forum";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";
import Spinner from "components/spinner";

const AdminPhotoListView = observer(() => {
  const history = useHistory();
  const { action, language } = useStore();
  const location = useLocation();
  const [pageState, setPageState] = React.useState("PENDING");
  const [data, setData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(1);

  /**
   * 포럼 데이터를 가져오는 함수입니다.
   */
  const initializeData = async () => {
    // 1. 페이지 패러미터를 확인합니다.
    const queryParams = new URLSearchParams(location.search);

    // 2. 서버에 데이터를 요청합니다.
    const response = await action.getPhotoGalleryList(
      queryParams.get("query") || "",
      queryParams.get("page") || 1
    );

    // 3. 데이터 요청에 문제가 생길 시, 에러 상태로 변경하고 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 4. 포토 데이터를 저장합니다.
    setData(response.data.results);
    setTotalCount(response.data.count);

    // 5. 페이지 상태를 '정상'으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
  }, []);

  React.useEffect(() => {
    initializeData();
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Header
            title="포토 갤러리 관리"
            onSaveText="새 글 쓰기"
            onSave={() => {
              history.push(PATH.ADMIN_PHOTO_NEW);
            }}
          />
          {/* {language === "KOREAN" && <Header title="포토 갤러리" />}
          {language === "ENGLISH" && <Header title="PHOTO GALLERY" />} */}
          <Forum.GalleryList
            showHeader={false}
            isAdmin={true}
            data={data}
            totalCount={totalCount}
          />
          {/* <UnderConstruction /> */}
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminPhotoListView;
