import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Forum from "components/forum";
import Spinner from "components/spinner";
import * as api from "services/api";
import { useHistory, useLocation } from "react-router";
import Header from "components/header";
import useStore from "services/store";

const AdminForumListView = observer(() => {
  const { action } = useStore();
  const history = useHistory();
  const location = useLocation();
  const [pageState, setPageState] = React.useState("PENDING");
  const [listItem, setListItem] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action
      .validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history)
      .then(() => {
        const queryParams = new URLSearchParams(location.search);
        Promise.all([
          api.forum__adminList(parseInt(queryParams.get("page") || 1)),
          api.forum__pinnedList(),
        ]).then(([response, pinnedResponse]) => {
          if (
            response.status === "SUCCESS" &&
            pinnedResponse.status === "SUCCESS"
          ) {
            setListItem(pinnedResponse.data.concat(response.data.results));
            setTotalCount(response.data.count);
            setPageState("NORMAL");
          }
        });
      });
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === "PENDING" && <Spinner />}
      {pageState === "NORMAL" && (
        <>
          <Header
            title="센터활동 관리"
            onSaveText={"새 글 쓰기"}
            onSave={() => history.push(PATH.ADMIN_FORUM_NEW)}
          />
          <Forum.List data={listItem} totalCount={totalCount} isAdmin={true} />
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminForumListView;
