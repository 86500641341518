import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

/**
 * 현재 제작중인 컴포넌트를 위한 임시 플레이스홀더입니다.
 */
const UnderConstruction = () => {
  return (
    <Container>
      <Icon>
        <ExclamationCircleOutlined />
      </Icon>
      <Text>UNDER CONSTRUCTION</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  user-select: none;
`;

const Icon = styled.div`
  font-size: 80px;
  color: #acacac; ;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #acacac; ;
`;

export default UnderConstruction;
