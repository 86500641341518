import React from "react";
import { LayoutForm2 } from "components/layout";
import Member from "components/member";
import Spinner from "components/spinner";
import { SIDE_PANEL_MENU, TABLE_COLUMNS } from "app/config";
import useStore from "services/store";
import { observer } from "mobx-react";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";

const Members4View = observer(() => {
  const { language, action } = useStore();
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [data, setData] = React.useState();

  /**
   * 서버에서 방문자 데이터를 가져오는 합수입니다.
   */
  const initializeData = async () => {
    // 1. 데이터를 요청합니다.
    const response = await action.getVisitorList(language);

    // 2. 요청에 문제가 생길 시, 에러 상태로 변경 후 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 3. 받아온 데이터를 연도별로 분리해서 저장합니다.
    let dataByYear = {};
    for (const item of response.data) {
      let year = item.date?.split(".")[0];
      if (isNaN(year)) continue;

      if (!dataByYear[year]) dataByYear[year] = [item];
      else dataByYear[year].push(item);
    }
    setData(dataByYear);

    // 4. 페이지 상태를 '정상'으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
  }, [language]);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.members}>
      {pageState === PAGE_STATE.PENDING ? <Spinner /> : null}
      {pageState === PAGE_STATE.NORMAL ? (
        <>
          <Member.Title1
            textMap={{ KOREAN: "방문 연구원", ENGLISH: "VISITORS" }}
          />
          {Object.keys(data)
            .sort()
            .reverse()
            .map((key, idx) => (
              <Member.VisitorTable
                key={idx}
                data={data[key]}
                year={key}
                /* 수정하기 column은 제거합니다: */
                columns={TABLE_COLUMNS.VISITOR_MEMBERS[language].slice(0, -1)}
              />
            ))}
        </>
      ) : null}
    </LayoutForm2>
  );
});

export default Members4View;
