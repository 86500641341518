import { WarningOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import { TABLE_COLUMNS } from "app/config";
import { STATUS } from "app/constants";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const EditModal = observer(({ visible, setVisible, refreshData }) => {
  const { action, adminPaperStore } = useStore();

  /**
   * 연구업적 데이터를 생성합니다.
   */
  const createPaper = async () => {
    const response = await action.createPaper(adminPaperStore);
    if (response.status !== STATUS.SUCCESS) {
      return message.error("생성에 실패했습니다.");
    }

    message.success("성공적으로 추가했습니다.");
    setVisible(false);
    await refreshData();
  };

  /**
   * 연구업적 데이터를 수정합니다..
   */
  const updatePaper = async () => {
    const response = await action.updatePaper(adminPaperStore);
    if (response.status !== STATUS.SUCCESS) {
      return message.error("수정에 실패했습니다.");
    }

    message.success("성공적으로 수정했습니다.");
    setVisible(false);
    await refreshData();
  };

  /**
   * OK 버튼을 누를 때 실행할 함수입니다.
   */
  const onOk = async () => {
    if (adminPaperStore.id === null) {
      await createPaper();
    } else {
      await updatePaper();
    }
  };

  /**
   * 수정페이지에서 [데이터 삭제]를 누를 시 실행할 함수입니다.
   */
  const onDelete = async () => {
    const response = await action.deletePaper(adminPaperStore.id);
    if (response.status !== STATUS.SUCCESS) {
      return message.error("삭제에 실패했습니다.");
    }

    message.success("성공적으로 데이터를 삭제했습니다.");
    setVisible(false);
    await refreshData();
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={onOk}
      destroyOnClose={true}
    >
      <Container>
        <Title>
          {adminPaperStore.id === null ? "연구업적 추가" : "연구업적 수정"}
        </Title>
        <Form.Item label="Title" name="Title" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.title}
            value={adminPaperStore.title}
            onChange={(e) => adminPaperStore.set({ title: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Year" name="Year" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.year}
            value={adminPaperStore.year}
            onChange={(e) => adminPaperStore.set({ year: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Author" name="Author" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.author}
            value={adminPaperStore.author}
            onChange={(e) => adminPaperStore.set({ author: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Journal" name="Journal" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.journal}
            value={adminPaperStore.journal}
            onChange={(e) => adminPaperStore.set({ journal: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="DOI" name="DOI" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.DOI}
            value={adminPaperStore.DOI}
            onChange={(e) => adminPaperStore.set({ DOI: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="DOI Link" name="DOI Link" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.DOILink}
            value={adminPaperStore.DOILink}
            onChange={(e) => adminPaperStore.set({ DOILink: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="arXiv" name="arXiv" labelCol={{ span: 5 }}>
          <Input
            style={{ width: 300 }}
            defaultValue={adminPaperStore.arXiv}
            value={adminPaperStore.arXiv}
            onChange={(e) => adminPaperStore.set({ arXiv: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="CQUeST No." name="CQUeST No." labelCol={{ span: 5 }}>
          <Input
            style={{ width: 140, marginRight: 10 }}
            defaultValue={adminPaperStore.cquestNumber}
            value={adminPaperStore.cquestNumber}
            onChange={(e) =>
              adminPaperStore.set({ cquestNumber: e.target.value })
            }
          />
        </Form.Item>

        <Form.Item
          label="Publish Year"
          name="Publish Year"
          labelCol={{ span: 5 }}
        >
          <Input
            style={{ width: 140, marginRight: 10 }}
            defaultValue={adminPaperStore.publishYear}
            value={adminPaperStore.publishYear}
            onChange={(e) =>
              adminPaperStore.set({ publishYear: e.target.value })
            }
          />
        </Form.Item>
        {adminPaperStore.id !== null && (
          <Button
            type="default"
            danger={true}
            style={{ fontSize: 14, marginTop: 20, marginBottom: 40 }}
            onClick={onDelete}
          >
            <WarningOutlined />
            데이터 삭제
          </Button>
        )}
      </Container>
    </Modal>
  );
});

const Container = styled.div`
  width: 550px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

export default EditModal;
