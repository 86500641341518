import CardList from "./cardList";
import Header from "./header";
import EditModal from "./editModal";

const Paper = {
  CardList,
  Header,
  EditModal,
};

export default Paper;
