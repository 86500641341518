import React from "react";
import { Button } from "antd";

const Header = ({ title, onSave, onSaveText = "" }) => {
  return (
    <div>
      <span
        style={{
          display: "inline-block",
          fontSize: 32,
          color: "#000",
          marginBottom: 40,
        }}
      >
        {title}
      </span>
      <Button
        type="primary"
        onClick={onSave}
        style={{
          float: "right",
          marginTop: 10,
          fontSize: 16,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 0,
          paddingBotom: 0,
          height: 40,
          lineHeight: "40px",
        }}
      >
        {onSaveText}
      </Button>
    </div>
  );
};

export default Header;
