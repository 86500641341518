import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { useHistory } from "react-router";
import { Card, Space } from "antd";
import {
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import Header from "components/header";
import useStore from "services/store";

const AdminMemberOptionView = () => {
  const history = useHistory();
  const { action } = useStore();
  React.useEffect(() => {
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      <Header title="연구진 관리" />
      <Space direction="horizontal" align="center">
        <Card
          onClick={() => {
            history.push(PATH.ADMIN_MEMBER_MEMBER_LIST);
          }}
          style={{ cursor: "pointer", width: 250, textAlign: "center" }}
        >
          <UserOutlined
            style={{
              color: "#7dc1ff",
              display: "block",
              fontSize: 100,
              marginBottom: 20,
            }}
          />
          <span style={{ fontSize: 20, fontWeight: "bold" }}>연구진</span>
        </Card>
        <Card
          onClick={() => {
            history.push(PATH.ADMIN_MEMBER_VISITOR_EDIT);
          }}
          style={{ cursor: "pointer", width: 250, textAlign: "center" }}
        >
          <TeamOutlined
            style={{
              color: "#7dc1ff",
              display: "block",
              fontSize: 100,
              marginBottom: 20,
            }}
          />
          <span style={{ fontSize: 20, fontWeight: "bold" }}>방문 연구원</span>
        </Card>
        <Card
          onClick={() => {
            history.push(PATH.ADMIN_MEMBER_PREVIOUS_EDIT);
          }}
          style={{ cursor: "pointer", width: 250, textAlign: "center" }}
        >
          <UserSwitchOutlined
            style={{
              color: "#7dc1ff",
              display: "block",
              fontSize: 100,
              marginBottom: 20,
            }}
          />
          <span style={{ fontSize: 20, fontWeight: "bold" }}>거쳐가신 분</span>
        </Card>
        {/*
        <Card
          onClick={() => {
            history.push(PATH.ADMIN_MEMBER_PREVIOUS_EDIT);
          }}
          style={{ cursor: "pointer", width: 250, textAlign: "center" }}
        >
          <InsertRowBelowOutlined
            style={{
              color: "#7dc1ff",
              display: "block",
              fontSize: 100,
              marginBottom: 20,
            }}
          />
          <span style={{ fontSize: 20, fontWeight: "bold" }}>거쳐가신 분</span>
        </Card> */}
      </Space>
    </LayoutForm2>
  );
};

export default AdminMemberOptionView;
