import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import useStore from "services/store";
import { STATUS } from "app/constants";
import { useHistory } from "react-router";
import UnderConstruction from "components/underConstruction";

const AdminLandingView = observer(() => {
  const { action } = useStore();
  const history = useHistory();
  React.useEffect(() => {
    history.replace(PATH.ADMIN_MEMBER_OPTION);
  });
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {/* <UnderConstruction /> */}
    </LayoutForm2>
  );
});

export default AdminLandingView;
