import React from "react";
import styled from "styled-components";

const AddButton = () => {
  return <Container></Container>;
};

const Container = styled.div``;

export default AddButton;
