import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  PaperClipOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Divider, Tag } from "antd";
import { PATH } from "app/config";
import helpers from "helpers";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useParams } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const Viewer = observer(({ data }) => {
  const { token } = useStore();
  const history = useHistory();
  const { forumID } = useParams();
  return (
    <>
      <Title>
        <ArrowLeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        &nbsp;&nbsp;{data.title}
        &nbsp;&nbsp;
        {token && (
          <SettingOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={() =>
              history.push(PATH.ADMIN_FORUM_EDIT.replace(":forumID", forumID))
            }
          />
        )}
      </Title>
      <TagArea>
        {data.participants && (
          <Tag color="blue" style={{ fontSize: 14 }}>
            &nbsp;
            <UserOutlined />
            &nbsp;
            {data.participants}&nbsp;
          </Tag>
        )}
        {data.isSingleDate && data.singleDate && (
          <Tag color="green" style={{ fontSize: 14 }}>
            &nbsp;
            <ClockCircleOutlined />
            &nbsp;
            {helpers.date.toFormat3(data.singleDate)}
            &nbsp;
          </Tag>
        )}

        {!data.isSingleDate && data.rangeBeginDate && (
          <Tag color="green" style={{ fontSize: 14 }}>
            &nbsp;
            <ClockCircleOutlined />
            &nbsp;
            {`${helpers.date.toFormat1(
              data.rangeBeginDate
            )} ~ ${helpers.date.toFormat1(data.rangeEndDate)}`}
            &nbsp;
          </Tag>
        )}
      </TagArea>
      <AttachmentArea>
        {[data.file1, data.file2, data.file3, data.file4, data.file5].map(
          (item, idx) => {
            return (
              item !== null && (
                <AttachmentItem key={idx}>
                  <a href={item} target="_blank" rel="noopener noreferrer">
                    <PaperClipOutlined />
                    &nbsp;
                    {item.split("/").slice(-1)[0]}
                  </a>
                </AttachmentItem>
              )
            );
          }
        )}
      </AttachmentArea>
      <Divider />
      {data.isLegacy ? (
        <Container className="sun-editor-editable">
          <div
            className="single-page-container"
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </Container>
      ) : (
        <div
          className="mce-content-body"
          dangerouslySetInnerHTML={{ __html: data.body }}
        ></div>
      )}
    </>
  );
});

const Container = styled.div`
  margin-bottom: 200px;
`;

const Title = styled.div`
  font-size: 20px;
`;

const TagArea = styled.div`
  margin-top: 20px;
`;

const AttachmentArea = styled.div`
  margin-top: 20px;
`;

const AttachmentItem = styled.span`
  margin-right: 20px;
  font-size: 14px;
`;
export default Viewer;
