import React from "react";
import styled from "styled-components";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Legend from "./legend";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const MONTH_TABLE = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Header = ({ addCalendarDelta, currentMonth, currentYear }) => {
  return (
    <Container>
      <LeftArea>
        <GoBackButton
          onClick={() => {
            addCalendarDelta(-1);
          }}
        >
          <LeftOutlined />
        </GoBackButton>
        <Title>{[MONTH_TABLE[currentMonth], currentYear].join(" ")}</Title>
        <GoNextButton
          onClick={() => {
            addCalendarDelta(1);
          }}
        >
          <RightOutlined />
        </GoNextButton>
      </LeftArea>
      <LegendContainer>
        <Legend />
      </LegendContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 10px;
`;

const LeftArea = styled.div`
  display: inline-block;
  user-select: none;
  vertical-align: bottom;
  margin-right: 40px;
`;

const Title = styled.div`
  display: inline-block;
  font-size: 18px;
  width: 200px;
  text-align: center;
  vertical-align: middle;
`;

const GoBackButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer;
  color: #4c4c4c;
`;
const GoNextButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer;
  color: #4c4c4c;
`;

const LegendContainer = styled.div`
  display: inline-block;
  text-align: right;
  vertical-align: bottom;
`;

export default Header;
