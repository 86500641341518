import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, SIDE_PANEL_MENU } from "app/config";
import Forum from "components/forum";
import { observer } from "mobx-react";
import useStore from "services/store";
import { useHistory, useParams } from "react-router";
import { PAGE_STATE, STATUS } from "app/constants";
import Spinner from "components/spinner";
import Header from "components/layout/header";
import Photo from "components/photo";
import { PhotoGalleryArticleModel } from "services/models";

const AdminPhotoNewView = observer(() => {
  const { action } = useStore();
  const history = useHistory();
  const initialData = {
    id: null,
    title: "",
    beginsAt: null,
    endsAt: null,
  };
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);

  const photoGalleryArticleModel = PhotoGalleryArticleModel.create({
    id: null,
    title: "",
    beginsAt: null,
    endsAt: null,
    topImage: null,
    body: "",
    imageList: [],
    isSingleDate: true,
  });

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
    setPageState(PAGE_STATE.NORMAL);
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Photo.Editor photoGalleryArticleModel={photoGalleryArticleModel} />
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminPhotoNewView;
