import { PATH } from "app/config";
import React from "react";
import { useHistory } from "react-router";
import MemberCard from "./memberCard";

const CardList = ({ items, clickToEdit = false }) => {
  const history = useHistory();
  return (
    <div
      style={{
        maxWidth: 900,
        marginBottom: 150,
      }}
    >
      {items.map((item, idx) => (
        <MemberCard
          key={idx}
          image={item.image}
          name={item.name}
          email={item.email}
          fields={JSON.parse(item.fields)}
          style={{
            marginBottom: 20,
            cursor: clickToEdit ? "pointer" : "inherit",
          }}
          onClick={() => {
            if (clickToEdit)
              history.push(
                PATH.ADMIN_MEMBER_MEMBER_EDIT.replace(":memberID", item.id)
              );
          }}
        />
      ))}
    </div>
  );
};

export default CardList;
