import React from "react";
import { LayoutForm2 } from "components/layout";
import { SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Forum from "components/forum";
import { PAGE_STATE, REQUEST_STATUS, STATUS } from "app/constants";
import Spinner from "components/spinner";
import { useLocation } from "react-router";
import useStore from "services/store";
import Header from "components/header";

const Forum1View = observer(() => {
  const { action, language } = useStore();
  const location = useLocation();

  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [listItem, setListItem] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);

  /**
   * 포럼 데이터를 가져오는 함수입니다.
   */
  const initializeData = async () => {
    // 1. URL 패러미터가 있는지 확인합니다.
    const queryParams = new URLSearchParams(location.search);

    // 2. 서버에서 데이터를 가져옵니다.
    const responseList = await Promise.all([
      action.getForumList(
        "ANNOUNCEMENT,EXTERNAL,SEMINAR,CONFERENCE",
        parseInt(queryParams.get("page") || 1),
        queryParams.get("query") || "",
        queryParams.get("type") || "title"
      ),
      action.getPinnedForumList("ANNOUNCEMENT,EXTERNAL,SEMINAR,CONFERENCE"),
    ]);

    // 3. 요청 중 에러가 생길 시, 페이지 상태를 변경하고 함수를 종료합니다.
    if (!responseList.every((item) => item.status === REQUEST_STATUS.SUCCESS)) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 4. 포럼 리스트와 상단고정 리스트를 병합 후, 아티클 데이터와 페이지네이션 값을 저장합니다.
    const [response, pinnedResponse] = responseList;
    setListItem(pinnedResponse.data.concat(response.data.results));
    setTotalCount(response.data.count);

    // 5. 페이지 상태를 '정상'으로 수정합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.forum}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          {language === "KOREAN" && <Header title="센터 일정" />}
          {language === "ENGLISH" && <Header title="CALENDAR" />}
          <Forum.Calendar />
          <Forum.List data={listItem} totalCount={totalCount} />
        </>
      )}
    </LayoutForm2>
  );
});

export default Forum1View;
