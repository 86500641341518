import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import useStore from "services/store";
import { STATUS } from "app/constants";
import { API_BASE_URL, TINYMCE_API_KEY } from "app/config";

/**
 * TinyMCE 기반 텍스트 에디터입니다.
 */
const TextEditor = ({ editorRef, initialValue, height = 500 }) => {
  const { action } = useStore();

  return (
    <Editor
      apiKey={TINYMCE_API_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      init={{
        // images_upload_url: "http://localhost:8000/image",

        /* we override default upload handler to simulate successful upload*/
        images_upload_handler: async (blobInfo, success, failure) => {
          const imageData = `${blobInfo.filename()};base64string,${blobInfo.base64()}`;

          const response = await action.createImage(imageData);
          if (response.status === STATUS.SUCCESS)
            setTimeout(function () {
              /* no matter what you upload, we will turn it into TinyMCE logo :)*/
              success(`${API_BASE_URL}${response.data}`);
            }, 2000);
        },
        language: "ko",
        height: height,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount template",
        ],
        toolbar:
          "undo redo | fontselect fontsizeselect | bold italic | forecolor backcolor removeformat  | insertfile image table media link anchor | alignleft aligncenter alignright alignjustify |  numlist bullist | pagebreak | charmap emoticons | fullscreen  preview save print | codesample | ltr rtl | underline strikethrough | outdent indent |  formatselect template ",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
};

// /* without images_upload_url set, Upload tab won't show up*/
// images_upload_url: 'postAcceptor.php',

// /* we override default upload handler to simulate successful upload*/
// images_upload_handler: function (blobInfo, success, failure) {
//   setTimeout(function () {
//     /* no matter what you upload, we will turn it into TinyMCE logo :)*/
//     success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
//   }, 2000);
// },
export default TextEditor;

// export default function App() {
//   const editorRef = useRef(null);
//   const log = () => {
//     if (editorRef.current) {
//       console.log(editorRef.current.getContent());
//     }
//   };
//   return (
//     <>
//       <Editor
//         onInit={(evt, editor) => (editorRef.current = editor)}
//         initialValue="<p>This is the initial content of the editor.</p>"
//         init={{
//           height: 500,
//           menubar: false,
//           plugins: [
//             "advlist autolink lists link image charmap print preview anchor",
//             "searchreplace visualblocks code fullscreen",
//             "insertdatetime media table paste code help wordcount",
//           ],
//           toolbar:
//             "undo redo | formatselect | " +
//             "bold italic backcolor | alignleft aligncenter " +
//             "alignright alignjustify | bullist numlist outdent indent | " +
//             "removeformat | help",
//           content_style:
//             "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
//         }}
//       />
//       <button onClick={log}>Log editor content</button>
//     </>
//   );
// }
