import { API_BASE_URL } from "app/config";
import axios from "axios";

export const imageSlider__list = async () => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/image-slider/list`,
  });
};

export const imageSlider__create = async () => {
  return await request({
    method: "POST",
    url: `${API_BASE_URL}/image-slider/create`,
  });
};

export const imageSlider__delete = async () => {
  return await request({
    method: "DELETE",
    url: `${API_BASE_URL}/image-slider/delete/:imageSliderID`,
  });
};

export const singlePage__update = async ({
  id,
  dataKorean = undefined,
  dataEnglish = undefined,
}) => {
  return await request({
    method: "PATCH",
    url: `${API_BASE_URL}/single-page/update/${id}`,
    data: {
      data_korean: dataKorean,
      data_english: dataEnglish,
    },
  });
};

export const singlePage__boolList = async () => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/single-page/bool-list`,
  });
};

export const singlePage__detail = async ({ id, language }) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/single-page/detail/${id}`,
    params: {
      language,
    },
  });
};

export const forum__pinnedList = async (category = "") => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/forum/list/pinned`,
    params: {
      category,
    },
  });
};

export const forum__calendarList = async () => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/forum/list/calendar`,
  });
};

export const forum__list = async (category, page = 1) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/forum/list`,
    params: {
      category,
      page,
    },
  });
};

export const forum__adminList = async (page = 1) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/forum/list/admin`,
    params: {
      page,
    },
  });
};

export const forum__detail = async (forumID) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/forum/detail/${forumID}`,
  });
};

export const forum__delete = async (forumID) => {
  return await request({
    method: "DELETE",
    url: `${API_BASE_URL}/forum/delete/${forumID}`,
  });
};

export const forum__create = async ({
  title,
  isSingleDate,
  isPinned = undefined,
  body,
  singleDate = undefined,
  rangeBeginDate = undefined,
  rangeEndDate = undefined,
  category,
  file1,
  file2,
  file3,
  file4,
  file5,
  participants = undefined,
}) => {
  // Change data into formData:
  let formData = new FormData();
  const plainData = {
    title,
    isSingleDate,
    isPinned,
    body,
    singleDate,
    rangeBeginDate,
    rangeEndDate,
    category,
    participants,
  };

  const fileData = {
    file1,
    file2,
    file3,
    file4,
    file5,
  };

  Object.keys(plainData).map((key) => {
    if (plainData[key] !== undefined) formData.append(key, plainData[key]);
  });

  for (const key of Object.keys(fileData)) {
    if (!fileData[key]) continue;
    if (typeof fileData[key] === "string") continue;
    if (!fileData[key][0]) formData.append(key, "");
    else {
      formData.append(key, fileData[key][0], fileData[key][0].name);
    }
  }

  return await request({
    method: "POST",
    url: `${API_BASE_URL}/forum/create`,
    data: formData,
  });
};

export const forum__update = async ({
  forumID,
  title,
  isSingleDate,
  isPinned = undefined,
  body,
  singleDate = "",
  rangeBeginDate = "",
  rangeEndDate = "",
  category,
  file1,
  file2,
  file3,
  file4,
  file5,
  participants = undefined,
}) => {
  // Change data into formData:
  let formData = new FormData();
  const plainData = {
    title,
    isSingleDate,
    isPinned,
    body,
    category,
    participants,
    singleDate,
    rangeBeginDate,
    rangeEndDate,
  };

  const fileData = {
    file1,
    file2,
    file3,
    file4,
    file5,
  };

  Object.keys(plainData).map((key) => {
    formData.append(key, plainData[key]);
  });

  for (const key of Object.keys(fileData)) {
    if (!fileData[key]) continue;
    if (typeof fileData[key] === "string") continue;
    if (!fileData[key][0]) formData.append(key, "");
    else {
      formData.append(key, fileData[key][0], fileData[key][0].name);
    }
  }

  return await request({
    method: "PATCH",
    url: `${API_BASE_URL}/forum/${forumID}`,
    data: formData,
  });
};

export const event__list = async (page = 1) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/event/list`,
    params: {
      page,
    },
  });
};

export const event__create = async ({ title, body }) => {
  return await request({
    method: "POST",
    url: `${API_BASE_URL}/event/create`,
    data: {
      title,
      body,
    },
  });
};

export const event__update = async ({ eventID, title, body }) => {
  return await request({
    method: "PATCH",
    url: `${API_BASE_URL}/event/update/${eventID}`,
    data: {
      title,
      body,
    },
  });
};

export const event__detail = async (eventID) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/event/detail/${eventID}`,
  });
};

export const event__delete = async (eventID) => {
  return await request({
    method: "DELETE",
    url: `${API_BASE_URL}/event/delete/${eventID}`,
  });
};

export const member__create = async ({
  priority,
  image,
  category,
  name_KOREAN,
  name_ENGLISH,
  email,
  fields_KOREAN,
  fields_ENGLISH,
}) => {
  return await request({
    method: "POST",
    url: `${API_BASE_URL}/admin/member/create`,
    data: {
      priority,
      image,
      category,
      name_KOREAN,
      name_ENGLISH,
      email,
      fields_KOREAN,
      fields_ENGLISH,
    },
  });
};

export const member__update = async ({
  memberID,
  priority,
  image,
  category,
  name_KOREAN,
  name_ENGLISH,
  email,
  fields_KOREAN,
  fields_ENGLISH,
}) => {
  return await request({
    method: "PATCH",
    url: `${API_BASE_URL}/admin/member/update/${memberID}`,
    data: {
      priority,
      image,
      category,
      name_KOREAN,
      name_ENGLISH,
      email,
      fields_KOREAN,
      fields_ENGLISH,
    },
  });
};

export const member__list = async (categories, language) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/member/list`,
    params: {
      categories,
      language,
    },
  });
};

export const member__detail = async (memberID) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/admin/member/detail/${memberID}`,
  });
};

export const member__delete = async (memberID) => {
  return await request({
    method: "DELETE",
    url: `${API_BASE_URL}/admin/member/delete/${memberID}`,
  });
};

export const visitorMember__list = async (language) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/visitor-member/list`,
    params: {
      language,
    },
  });
};

export const alumniMember__list = async (language) => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/alumni-member/list`,
    params: {
      language,
    },
  });
};

export const paper__list = async () => {
  return await request({
    method: "GET",
    url: `${API_BASE_URL}/paper/list`,
  });
};

const request = async (axiosParams, onResponse) => {
  try {
    const response = await axios(axiosParams);
    return {
      status: "SUCCESS",
      data: onResponse ? onResponse(response.data) : response.data,
    };
  } catch (err) {
    return {
      status: "FAILURE",
    };
  }
};
