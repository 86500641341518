import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, PATH, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Spinner from "components/spinner";
import * as api from "services/api";
import { useHistory, useLocation } from "react-router";
import Event from "components/event";
import Header from "components/header";
import useStore from "services/store";

const AdminEventPageListView = observer(() => {
  const history = useHistory();
  const { action } = useStore();
  const location = useLocation();
  const [pageState, setPageState] = React.useState("PENDING");
  const [listItem, setListItem] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(null);

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);

    const queryParams = new URLSearchParams(location.search);
    api.event__list(parseInt(queryParams.get("page") || 1)).then((response) => {
      if (response.status === "SUCCESS") {
        setListItem(response.data.results);
        setTotalCount(response.data.count);
        setPageState("NORMAL");
      }
    });
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === "PENDING" && <Spinner />}
      {pageState === "NORMAL" && (
        <>
          <Header
            title="이벤트 페이지 관리"
            onSave={() => {
              history.push(
                PATH.ADMIN_EVENT_PAGE_EDIT.replace(":eventID", "new")
              );
            }}
            onSaveText={"새 글 쓰기"}
          />

          <Event.List data={listItem} totalCount={totalCount} />
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminEventPageListView;
