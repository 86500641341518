import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import useStore from "services/store";
import { STATUS } from "app/constants";
import { getRoot } from "mobx-state-tree";
import { useHistory } from "react-router";
import { PATH } from "app/config";

const LoginForm = () => {
  const history = useHistory();
  const { action } = useStore();
  const [errorText, setErrorText] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const tryLogin = async () => {
    // 1. 로그인을 시도합니다.
    const response = await action.login(username, password);

    if (response.status === STATUS.SUCCESS) {
      getRoot(action).setToken(response.data);
      history.push(PATH.ADMIN_LANDING);
    } else {
      setErrorText("아이디 또는 비밀번호가 일치하지 않습니다.");
    }
  };

  const _detectEnter = async (e) => {
    if (e.key === "Enter") {
      await tryLogin();
    }
  };

  return (
    <Container>
      <Input
        size="large"
        placeholder="Username"
        prefix={<UserOutlined />}
        onChange={(e) => {
          setUsername(e.target.value);
          setErrorText("");
        }}
        value={username}
      />
      <div style={{ height: 10 }} />
      <Input
        size="large"
        placeholder="Password"
        prefix={<KeyOutlined />}
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setErrorText("");
        }}
        onKeyDown={_detectEnter}
      />
      <div style={{ height: 40 }} />
      <Button onClick={tryLogin}>LOGIN</Button>
      <ErrorText>{errorText}</ErrorText>
    </Container>
  );
};

const Container = styled.div`
  width: 300px;
  height: 200px;

  margin: calc(50vh - 200px) auto 0;
`;

const Button = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #1890ff;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &&:hover {
    opacity: 0.9;
  }
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: #ccc;
  text-align: center;
  margin-top: 10px;
`;

export default LoginForm;
