import CardList from "./cardList";
import { Title1, Title2 } from "./title";
import VisitorTable from "./visitorTable";
import AlumniTable from "./alumniTable";
import TableEditingModal from "./tableEditingModal";
import PreviousMemberEditingModal from "./previousMemberEditingModal";

const Member = {
  CardList,
  Title1,
  Title2,
  VisitorTable,
  AlumniTable,
  TableEditingModal,
  PreviousMemberEditingModal,
};

export default Member;
