import React from "react";
import { LayoutForm2 } from "components/layout";
import Spinner from "components/spinner";
import { SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Paper from "components/paper";
import useStore from "services/store";
import { REQUEST_STATUS, PAGE_STATE } from "app/constants";
import Header from "components/header";

/**
 * 서버에서 받아온 논문 데이터를 연도별로 grouping 하는 함수입니다.
 */
const formatDataByYear = (data) => {
  const dataByYear = {};
  for (const item of data) {
    if (!dataByYear[item.year]) dataByYear[item.year] = [item];
    else dataByYear[item.year].push(item);
  }
  return dataByYear;
};

const AchievementsView = observer(() => {
  const { action, adminPaperStore } = useStore();
  const [pageState, setPageState] = React.useState("PENDING");
  const [originalPaperList, setOriginalPaperList] = React.useState([]);
  const [paperData, setPaperData] = React.useState([]);

  const [modalVisible, setModalVisible] = React.useState(false);
  /**
   * 현재 선택한 데이터의 상태를 저장합니다.
   */

  React.useEffect(() => {
    initializePaperList();
  }, []);

  /**
   * [추가하기] 를 누를 때 실행할 함수입니다.
   */
  const onCreate = () => {
    adminPaperStore.set({
      id: null,
      title: "",
      year: "",
      author: "",
      journal: "",
      DOI: "",
      arXiv: "",
      cquestNumber: "",
      publishYear: "",
    });

    setModalVisible(true);
  };

  /**
   * 논문 데이터를 가져오는 함수입니다.
   * */
  const initializePaperList = async () => {
    // 0. PENDING 상태로 변경합니다.
    setPageState(PAGE_STATE.PENDING);

    // 1. 데이터를 요청합니다.
    const response = await action.getPaperList();

    // 2. 요청에 문제가 생길 경우, 에러 상태로 변경하고 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 3. 필터링을 위해 원본 데이터를 저장하고, 연도별로 정렬한 데이터도 저장합니다.
    setOriginalPaperList(response.data);
    setPaperData(formatDataByYear(response.data));

    // 4. 페이지 상태를 '정상'으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  /**
   * 키워드에 상응하는 논문을 보여주는 함수입니다.
   * @param {} query 검색 키워드
   */
  const searchPaper = (query) => {
    query = query.toLowerCase();
    const filteredData = [];
    for (const item of originalPaperList) {
      if (
        item.title.toLowerCase().includes(query) ||
        item.author.toLowerCase().includes(query) ||
        item.journal.toLowerCase().includes(query) ||
        item.arXiv.toLowerCase().includes(query) ||
        item.DOI.toLowerCase().includes(query) ||
        item.cquestNumber.toLowerCase().includes(query)
      ) {
        filteredData.push(item);
      }
    }
    setPaperData(formatDataByYear(filteredData));
  };

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING ? <Spinner /> : null}
      {pageState === PAGE_STATE.NORMAL ? (
        <>
          <Paper.EditModal
            visible={modalVisible}
            setVisible={setModalVisible}
            refreshData={initializePaperList}
          />
          <Header
            title="연구업적 관리"
            onSave={onCreate}
            onSaveText={"추가하기"}
          />
          <Paper.Header searchPaper={searchPaper} />
          <Paper.CardList
            data={paperData}
            isAdmin={true}
            setModalVisible={setModalVisible}
          />
        </>
      ) : null}
    </LayoutForm2>
  );
});

export default AchievementsView;
