import { grey } from "@ant-design/colors";
import { Col, Input, Row, Select } from "antd";
import { PATH } from "app/config";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory, useLocation } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const { Option } = Select;

const Header = observer(({ title }) => {
  const { language } = useStore();
  const [queryText, setQueryText] = React.useState(null);
  const location = useLocation();
  const history = useHistory();
  const [searchCategory, setSearchCategory] = React.useState("title");

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setQueryText(queryParams.get("query") || "");
  }, []);

  return (
    <Row
      style={{
        marginBottom: 50,
        alignItems: "center",
      }}
    >
      <Col xl={16} lg={12} md={12} sm={10} xs={24}>
        <Title>{title}</Title>
      </Col>
      <Col xl={8} lg={12} md={12} sm={14} xs={0} style={{ marginTop: 10 }}>
        <Input.Search
          value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
          onSearch={(value, event) => {
            history.push(`${window.location.pathname}?query=${queryText}`);
          }}
        />
      </Col>
      <div
        style={{
          marginTop: 10,
          display: "inline-block",
          fontSize: 14,
          color: grey[4],
          marginBottom: 20,
        }}
      >
        {TEXT_MAP[language].map((item, key) => (
          <div>{item}</div>
        ))}
      </div>
    </Row>
  );
});

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const TEXT_MAP = {
  KOREAN: ["본 게시판은 2005년부터의 사진을 전부 게시하고 있습니다."],
  ENGLISH: ["This gallery includes all the photos from 2005."],
};

export default Header;
