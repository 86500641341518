import Viewer from "./viewer";
import Editor from "./editor";
import List from "./list";

const SinglePage = {
  Viewer,
  Editor,
  List,
};

export default SinglePage;
