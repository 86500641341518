import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import useStore from "services/store";
import { FORUM_CATEGORY } from "app/config";

const LEGEND_MAP = {
  [FORUM_CATEGORY.CONFERENCE.id]: {
    KOREAN: "학회",
    ENGLISH: "Conference",
  },
  [FORUM_CATEGORY.SEMINAR.id]: {
    KOREAN: "세미나",
    ENGLISH: "Seminar",
  },
  [FORUM_CATEGORY.EXTERNAL.id]: {
    KOREAN: "외부 행사",
    ENGLISH: "External",
  },
};

export default observer(() => {
  const { language } = useStore();
  return (
    <Wrapper>
      {[
        FORUM_CATEGORY.CONFERENCE,
        FORUM_CATEGORY.SEMINAR,
        FORUM_CATEGORY.EXTERNAL,
      ].map((category, idx) => (
        <Container key={idx}>
          <Dot color={category.color} />
          <Text>{LEGEND_MAP[category.id][language]}</Text>
        </Container>
      ))}
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
`;

const Dot = styled.div`
  background-color: ${(props) => props.color};
  margin-right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;
const Text = styled.div`
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  color: #888;
`;
