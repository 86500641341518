import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, SIDE_PANEL_MENU, TABLE_COLUMNS } from "app/config";
import useStore from "services/store";
import { useHistory } from "react-router";
import Member from "components/member";
import { PAGE_STATE, REQUEST_STATUS, STATUS } from "app/constants";
import Spinner from "components/spinner";
import { observer } from "mobx-react-lite";
import Header from "components/header";

const AdminMemberVisitorEditView = observer(() => {
  const [modalVisible, setModalVisible] = React.useState(false);

  const { action, language } = useStore();
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [data, setData] = React.useState();

  /**
   * 현재 선택한 데이터의 상태를 저장합니다.
   */
  const [editState, _setEditState] = React.useState({
    id: null,
    name_KOREAN: "",
    name_ENGLISH: "",
    affiliation_KOREAN: "",
    affiliation_ENGLISH: "",
    inviter_KOREAN: "",
    inviter_ENGLISH: "",
    beginDate: "",
    endDate: "",
  });
  const setEditState = (obj) =>
    _setEditState((prevState) => ({ ...prevState, ...obj }));

  /**
   * [추가하기] 를 누를 때 실행할 함수입니다:
   */
  const onCreateButton = () => {
    setEditState({
      id: null,
      name_KOREAN: "",
      name_ENGLISH: "",
      affiliation_KOREAN: "",
      affiliation_ENGLISH: "",
      inviter_KOREAN: "",
      inviter_ENGLISH: "",
      beginDate: "",
      endDate: "",
    });
    setModalVisible(true);
  };

  const onEdit = async (visitorData) => {
    const response = await action.getVisitorDetail(visitorData.id);
    if (response.status !== STATUS.SUCCESS) {
      return;
    }

    setEditState(response.data);
    setModalVisible(true);
  };

  /**
   * 서버에서 방문자 데이터를 가져오는 합수입니다.
   */
  const initializeData = async () => {
    // 0. pending 상태로 변경합니다.
    setPageState(PAGE_STATE.PENDING);

    // 1. 데이터를 요청합니다.
    const response = await action.getVisitorList(language);

    // 2. 요청에 문제가 생길 시, 에러 상태로 변경 후 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 3. 받아온 데이터를 연도별로 분리해서 저장합니다.
    let dataByYear = {};
    for (const item of response.data.map((data) => {
      // 3-1. 각각의 데이터에 "수정하기" 연동 함수를 추가합니다.
      const onClick = () => {
        onEdit(data);
      };
      data["onClick"] = onClick;
      return data;
    })) {
      let year = item.date?.split(".")[0];
      if (isNaN(year)) continue;

      if (!dataByYear[year]) dataByYear[year] = [item];
      else dataByYear[year].push(item);
    }
    setData(dataByYear);

    // 4. 페이지 상태를 '정상'으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
  }, []);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING ? <Spinner /> : null}
      {pageState === PAGE_STATE.NORMAL ? (
        <>
          <Member.TableEditingModal
            visible={modalVisible}
            setVisible={setModalVisible}
            state={editState}
            setState={setEditState}
            refreshData={initializeData}
          />
          <Header
            title="VISITORS"
            onSave={onCreateButton}
            onSaveText={"추가하기"}
          />
          {Object.keys(data)
            .sort()
            .reverse()
            .map((key, idx) => (
              <Member.VisitorTable
                key={idx}
                data={data[key]}
                year={key}
                columns={TABLE_COLUMNS.VISITOR_MEMBERS[language]}
              />
            ))}
        </>
      ) : null}
    </LayoutForm2>
  );
});

export default AdminMemberVisitorEditView;
