import MemberEditor from "./memberEditor";
import Header from "./header";
import DeleteData from "./deleteData";

const AdminMember = {
  MemberEditor,
  Header,
  DeleteData,
};

export default AdminMember;
