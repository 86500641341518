import { PictureOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";

const Header = ({ images, accept, uploadFiles, openDialogue }) => {
  return (
    <Container onClick={() => openDialogue()}>
      <PictureOutlined style={{ fontSize: 24, color: "#6fb9d6" }} />
      <div
        style={{
          marginTop: 2,
          fontSize: 14,
          color: "#6fb9d6",
          fontWeight: "bold",
        }}
      >
        &nbsp;&nbsp; 이미지 추가
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 200px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  color: #d9d9d9;
  cursor: pointer;
  margin-left: 22px;

  &&:hover {
    border-color: #6fb9d6;
  }
`;

export default Header;
