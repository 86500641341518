import React from "react";
import { LayoutForm2 } from "components/layout";
import { SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import Forum from "components/forum";
import Spinner from "components/spinner";
import * as api from "services/api";
import { useLocation, useParams } from "react-router";

const ForumArticleView = observer(() => {
  const location = useLocation();
  const { forumID, category } = useParams();

  const [pageState, setPageState] = React.useState("PENDING");
  const [detailData, setDetailData] = React.useState(null);
  const [listData, setListData] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(null);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!queryParams.get("page")) {
      window.scrollTo(0, 0);
    }
    Promise.all([
      api.forum__detail(forumID),
      api.forum__list(
        category.toUpperCase(),
        parseInt(queryParams.get("page") || 1)
      ),
      api.forum__pinnedList(category.toUpperCase()),
    ])

      .then(([detailResponse, listResponse, pinnedResponse]) => {
        if (
          detailResponse.status === "SUCCESS" &&
          listResponse.status === "SUCCESS" &&
          pinnedResponse.status === "SUCCESS"
        ) {
          setDetailData(detailResponse.data);
          setTotalCount(listResponse.data.count);
          setListData(pinnedResponse.data.concat(listResponse.data.results));
        }
      })
      .then(() => {
        setPageState("NORMAL");
      });
  }, [location]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.forum}>
      {pageState === "PENDING" && <Spinner />}
      {pageState === "NORMAL" && (
        <>
          <Forum.Viewer data={detailData} />
          <Forum.List data={listData} totalCount={totalCount} />
        </>
      )}
    </LayoutForm2>
  );
});

export default ForumArticleView;
