import React from "react";
import styled from "styled-components";

import OriginalCalendar from "./calendar";
// import Header from "./header";

const Calendar = () => {
  return (
    <Container>
      <OriginalCalendar />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export default Calendar;
