import { PATH } from "app/config";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import useStore from "services/store";

const LogoutView = observer(() => {
  const history = useHistory();
  const { setToken } = useStore();

  React.useEffect(() => {
    setToken("");
    history.replace(PATH.LANDING);
  }, []);

  return <></>;
});

export default LogoutView;
