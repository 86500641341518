export default {
  toFormat1: (dateObject) => {
    if (!dateObject) return "";
    else {
      dateObject = new Date(dateObject);
      const zPad = (value) => value.toString().padStart(2, "0");

      let year = zPad(dateObject.getFullYear());
      let month = zPad(dateObject.getMonth() + 1);
      let date = zPad(dateObject.getDate());

      return `${year}-${month}-${date}`;
    }
  },
  toFormat2: (dateObject) => {
    if (!dateObject) return "";
    else {
      dateObject = new Date(dateObject);
      const zPad = (value) => value.toString().padStart(2, "0");

      let month = monthMap[dateObject.getMonth()];
      let date = zPad(dateObject.getDate());
      let weekday = weekDayMap[dateObject.getDay()];

      return `${month}${date}${weekday}`;
    }
  },
  toFormat3: (dateObject) => {
    if (!dateObject) return "";
    else {
      dateObject = new Date(dateObject);
      const zPad = (value) => value.toString().padStart(2, "0");

      let year = zPad(dateObject.getFullYear());
      let month = zPad(dateObject.getMonth() + 1);
      let date = zPad(dateObject.getDate());
      let hours = zPad(dateObject.getHours());
      let minutes = zPad(dateObject.getMinutes());

      return `${year}-${month}-${date} ${hours}:${minutes}`.replace(
        " 00:00",
        ""
      );
    }
  },
};

const monthMap = [
  "Jan. ",
  "Feb. ",
  "Mar. ",
  "Apr. ",
  "May. ",
  "Jun. ",
  "Jul. ",
  "Aug. ",
  "Sep. ",
  "Oct. ",
  "Nov. ",
  "Dec. ",
];
const weekDayMap = [
  " (Sun.)",
  " (Mon.)",
  " (Tue.)",
  " (Wed.)",
  " (Thu.)",
  " (Fri.)",
  " (Sat.)",
];

// export default {
//   toDateObject: (dateString) => {
//     // dateField accepts <Date> object or <null>.
//     if (!dateString) return null;

//     if (!/^\d{4}-\d{2}-\d{2}$|^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateString))
//       return NaN;
//     const date = new Date(dateString + " ");
//     if (!(date instanceof Date) || isNaN(date)) return NaN;

//     return date;
//   },
//   toFormalDate: (dateString, hideTime = false) => {
//     const zPad = (value) => value.toString().padStart(2, "0");
//     if (!dateString) return "";
//     let date = new Date(dateString);
//     if (!(date instanceof Date) || isNaN(date)) return "";

//     date = [
//       `${zPad(date.getFullYear())}-${zPad(date.getMonth() + 1)}-${zPad(
//         date.getDate()
//       )}`,
//       ` ${zPad(date.getHours())}:${zPad(date.getMinutes())}`,
//     ]
//       .join("")
//       .replace(" 00:00", "");

//     return hideTime ? date.split(" ")[0] : date;
//   },
//   prettify: (date, hideTime = false) => {
//     const formatHM = (H, M) => {
//       // always in 24:00 format:
//       let hours = H;
//       let minutes = M;

//       let formattedString =
//         hours >= 13
//           ? [
//               ("0" + (hours - 12).toString()).slice(-2),
//               ":",
//               ("0" + minutes.toString()).slice(-2),
//             ].join("")
//           : `${("0" + H.toString()).slice(-2)}:${("0" + M.toString()).slice(
//               -2
//             )}`;

//       return " " + formattedString + (hours >= 12 ? " p.m." : " a.m.");
//     };

//     const formatYMD = (Y, M, D, A) => {
//       const monthMap = [
//         "Jan. ",
//         "Feb. ",
//         "Mar. ",
//         "Apr. ",
//         "May. ",
//         "Jun. ",
//         "Jul. ",
//         "Aug. ",
//         "Sep. ",
//         "Oct. ",
//         "Nov. ",
//         "Dec. ",
//       ];
//       const weekDayMap = [
//         " (Sun.)",
//         " (Mon.)",
//         " (Tue.)",
//         " (Wed.)",
//         " (Thu.)",
//         " (Fri.)",
//         " (Sat.)",
//       ];

//       let month = M;
//       let date = D;
//       let weekDay = A;

//       return [
//         monthMap[month - 1],
//         ("0" + date.toString()).slice(-2),
//         weekDayMap[weekDay],
//       ].join("");
//     };
//     // if empty, return '';
//     if (!date) return "";

//     // secure Date object:
//     let dateObject;
//     if (date && !date.getMonth) {
//       dateObject = new Date(date);
//     } else if (date.getMonth) {
//       //  ( potential positive false )
//       dateObject = date;
//     }

//     return (
//       formatYMD(
//         dateObject.getFullYear(),
//         dateObject.getMonth() + 1,
//         dateObject.getDate(),
//         dateObject.getDay()
//       ) +
//       (!hideTime
//         ? formatHM(dateObject.getHours(), dateObject.getMinutes())
//         : "")
//     ).replace(" 00:00 a.m.", "");
//   },
// };
