import { grey } from "@ant-design/colors";
import { observer } from "mobx-react";
import React from "react";
import useStore from "services/store";
import { Row, Col, Input } from "antd";

const TEXT_MAP = {
  ENGLISH: [
    `• Only papers published after the opening of the center (June 10th 2015) will be written.`,
    `• Please read the <Notes on Publication of Papers> in the Announcement on the center's homepage.`,
    `• Papers will be written in the order in which they were received with the center's unique number ([ex> CQUeST-2005-0001]).`,
  ],
  KOREAN: [
    `• 센터 개소 이후(2005. 6. 10)의 논문만 기재합니다.`,
    `• 센터 홈페이지 공지사항의 <논문 출판시 유의사항> 을 숙지하여 주시길 바랍니다.`,
    `• 논문은 접수된 순서로 센터고유번호[ex> CQUeST-2005-0001]를 매겨 기재해 드립니다.`,
  ],
};

const Header = observer(({ searchPaper }) => {
  const [keyword, setKeyword] = React.useState("");

  const { language } = useStore();
  return (
    <Row>
      <Col xs={24} lg={16}>
        <div
          style={{
            display: "inline-block",
            fontSize: 14,
            color: grey[4],
            marginBottom: 20,
          }}
        >
          {TEXT_MAP[language].map((item, key) => (
            <div>{item}</div>
          ))}
        </div>
      </Col>
      <Col xs={24} lg={8}>
        <Input.Search
          allowClear={true}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={(value, event) => {
            searchPaper(event.target.value || value);
          }}
        />
      </Col>
    </Row>
  );
});

export default Header;
