import React from "react";
import { LayoutForm2 } from "components/layout";
import Member from "components/member";
import Spinner from "components/spinner";
import { MEMBER_CATEGORY, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import useStore from "services/store";
import { PAGE_STATE, REQUEST_STATUS, STATUS } from "app/constants";

/** 각각의 멤버 데이터를 정렬하는 방식을 기재한 함수입니다. */
const sortingMethod = (a, b) => {
  if (a.priority < b.priority) return 1;
  if (a.priority > b.priority) return -1;

  if (
    JSON.parse(a.fields)["학위취득연도"] > JSON.parse(b.fields)["학위취득연도"]
  )
    return 1;
  if (
    JSON.parse(a.fields)["학위취득연도"] < JSON.parse(b.fields)["학위취득연도"]
  )
    return -1;
};

const Members2View = observer(() => {
  const { language, action } = useStore();
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [students, setStudents] = React.useState([]);
  const [staffs, setStaffs] = React.useState([]);
  const [degreeCompletions, setDegreeCompletions] = React.useState([]);
  const [researchers, setResearchers] = React.useState([]);

  const initializeMemberList = async () => {
    // 1. 데이터를 요청합니다.
    const responseList = await Promise.all([
      action.getMemberList(MEMBER_CATEGORY.STUDENT.key, language),
      action.getMemberList(MEMBER_CATEGORY.STAFF.key, language),
      action.getMemberList(MEMBER_CATEGORY.DEGREE_COMPLETION.key, language),
    ]);

    // 2. 응답에 문제가 생길 시, 페이지 상태를 변경하고 종료합니다.
    if (!responseList.every((item) => item.status === REQUEST_STATUS.SUCCESS)) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 3. 각각의 response에 대해, "sortingMethod" 에 따라 정렬을 하고 데이터를 저장합니다.
    const [
      studentResponse,
      staffResponse,
      degreeCompletionResponse,
    ] = responseList;

    setStudents(studentResponse.data.sort(sortingMethod));
    setStaffs(staffResponse.data.sort(sortingMethod));
    setDegreeCompletions(degreeCompletionResponse.data.sort(sortingMethod));

    // 4. 페이지 상태를 '정상'으로 업데이트 합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeMemberList();
  }, [language]);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.members}>
      {pageState === PAGE_STATE.PENDING ? <Spinner /> : null}
      {pageState === PAGE_STATE.NORMAL ? (
        <>
          <Member.Title1
            textMap={{
              KOREAN: "학생 연구원",
              ENGLISH: "STUDENTS",
            }}
          />
          <Member.CardList items={students} />
          <Member.Title1
            textMap={{
              KOREAN: "행정 연구원",
              ENGLISH: "STAFFS",
            }}
          />
          <Member.CardList items={staffs} />
          <Member.Title1
            textMap={{
              KOREAN: "학위 배출 인력",
              ENGLISH: "DEGREE COMPLETION",
            }}
          />
          <Member.CardList items={degreeCompletions} />
        </>
      ) : null}
    </LayoutForm2>
  );
});

export default Members2View;
