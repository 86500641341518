import { Empty } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import useStore from "services/store";
import styled from "styled-components";

const NoData = observer(() => {
  const { language } = useStore();

  return (
    <Container>
      <Empty
        style={{ color: "#808080" }}
        description={
          language === "KOREAN" ? "검색 결과가 없습니다." : "No Data found."
        }
      />
    </Container>
  );
});

const Container = styled.div`
  margin: 100px auto;
`;
export default NoData;
