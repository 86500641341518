import { Col, Input, Row, Select } from "antd";
import { PATH } from "app/config";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory, useLocation } from "react-router";
import useStore from "services/store";
import styled from "styled-components";

const { Option } = Select;

const Header = observer(({ title }) => {
  const { language } = useStore();
  const [queryText, setQueryText] = React.useState(null);
  const location = useLocation();
  const history = useHistory();
  const [searchCategory, setSearchCategory] = React.useState("title");

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setQueryText(queryParams.get("query") || "");
  }, []);

  return (
    <Row
      style={{
        marginBottom: 50,
        alignItems: "center",
      }}
    >
      <Col xl={15} lg={12} md={12} sm={10} xs={24}>
        <Title>{title}</Title>
      </Col>
      <Col xl={9} lg={12} md={12} sm={14} xs={0} style={{ marginTop: 10 }}>
        <SearchBar>
          <Select
            defaultValue={searchCategory}
            value={searchCategory}
            onChange={(value) => setSearchCategory(value)}
            style={{ marginRight: 10, width: 200, textAlign: "center" }}
          >
            <Option value="title">
              {language === "KOREAN" ? "제목" : "Title"}
            </Option>
            <Option value="content">
              {language === "KOREAN" ? "제목 + 내용" : "Content"}
            </Option>
            {location.pathname === PATH.FORUM3 && (
              <Option value="participants">
                {language === "KOREAN" ? "발표자" : "Speaker"}
              </Option>
            )}
          </Select>

          <Input.Search
            value={queryText}
            onChange={(e) => setQueryText(e.target.value)}
            onSearch={(value, event) => {
              history.push(
                `${window.location.pathname}?query=${queryText}&type=${searchCategory}`
              );
            }}
          />
        </SearchBar>
      </Col>
    </Row>
  );
});

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
`;
export default Header;
