import { ADMIN_PERMISSION, API_BASE_URL, SIDE_PANEL_MENU } from "app/config";
import { LayoutForm2 } from "components/layout";
import Header from "components/header";
import React from "react";
import AdminImageSlider from "components/adminImageSlider";
import { observer } from "mobx-react";
import useStore from "services/store";
import { useHistory } from "react-router";
import { PAGE_STATE, REQUEST_STATUS } from "app/constants";
import Spinner from "components/spinner";
import { message } from "antd";

const AdminImageSliderView = observer(() => {
  // 페이지 상태를 저장합니다.
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const { action } = useStore();
  const [data, setData] = React.useState([]);
  const history = useHistory();

  /** 저장 버튼을 누를 때 실행할 함수입니다. */
  const onSave = async () => {
    // Array 데이터를 하나의 object로 병합합니다.
    const finalObject = {};
    for (const [idx, item] of data.entries()) {
      finalObject[`image${item.id}`] = item.image;
      finalObject[`order${item.id}`] = idx + 1;
      finalObject[`url${item.id}`] = item.url;
    }
    console.log(finalObject);
    const response = await action.updateImageSlider(finalObject);

    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return message.error("수정에 실패했습니다.");
    }

    return message.success("수정에 성공했습니다.");
  };

  /** 이미지 슬라이더의 데이터를 불러옵니다. */
  const initializeData = async () => {
    const response = await action.getImageSlider();

    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return;
    }

    // 오름차순으로 정렬하고 고유 id를 부여한 다음 저장합니다.
    let _orderedDataList = response.data.sort((a, b) => {
      if (a.order < b.order) return -1;
      else if (a.order > b.order) return 1;
    });
    _orderedDataList = _orderedDataList.map((item, idx) => {
      item["id"] = idx + 1;
      item["image"] = item["image"]
        ? API_BASE_URL + item["image"]
        : item["image"];
      return item;
    });
    setData(_orderedDataList);

    console.log(_orderedDataList);

    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
    // action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
  }, []);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      <Header
        title="이미지 슬라이더 편집"
        onSave={onSave}
        onSaveText={"저장"}
      />
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <AdminImageSlider.Editor data={data} setData={setData} />
      )}
    </LayoutForm2>
  );
});

export default AdminImageSliderView;
