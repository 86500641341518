import List from "./list";
import Edit from "./edit";
import Viewer from "./viewer";

const Event = {
  List,
  Edit,
  Viewer,
};

export default Event;
