import { types } from "mobx-state-tree";
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

/**
 * DateTime 모델입니다.
 * (https://spectrum.chat/mobx-state-tree/general/types-date-and-utc-string~b57a8143-fe91-4b96-9fa2-fb82b10b1d76)
 */
const DateTime = types.custom({
  name: "DateTime",
  fromSnapshot: (string) => moment.utc(string, DATE_FORMAT).local(),
  toSnapshot: (mDate) => mDate.format(DATE_FORMAT),
  isTargetType: (maybeMoment) => moment.isMoment(maybeMoment),
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined) return "";
    const mDate = moment(snapshot, DATE_FORMAT, true);
    if (!mDate.isValid()) {
      const message = `"${snapshot}" is not in valid date format ${DATE_FORMAT}`;
      console.error(message);
      return message;
    }
    return "";
  },
});

export const Image = types.model("Image", {
  id: types.string,
  width: types.integer,
  height: types.integer,
  url: types.string,
});

export const AbstractPhotoGalleryArticleModel = types
  .model("AbstractPhotoGalleryArticle", {
    id: types.maybeNull(types.string),
    title: types.string,
    beginsAt: types.maybeNull(DateTime), // DATESTRING
    endsAt: types.maybeNull(DateTime), // DATESTRING
    topImageURL: types.maybeNull(types.string),
    count: types.integer,
  })
  .actions((self) => ({
    set(kvDict) {
      for (const key of Object.keys(kvDict)) {
        self[key] = kvDict[key];
      }
    },
  }));

export const PhotoGalleryArticleModel = types
  .model("PhotoGalleryArticle", {
    id: types.maybeNull(types.string),
    title: types.string,
    beginsAt: types.maybeNull(DateTime), // DATESTRING
    endsAt: types.maybeNull(DateTime), // DATESTRING
    topImageURL: types.maybeNull(types.string),
    body: types.string,
    imageList: types.array(types.string),
    // isSingleDate: types.boolean,
  })
  .actions((self) => ({
    set(kvDict) {
      for (const key of Object.keys(kvDict)) {
        self[key] = kvDict[key];
      }
    },
  }));
