import React from "react";
import styled from "styled-components";

// import Gallery from "react-photo-gallery";
import { useHistory, useLocation } from "react-router";
// import { PATH } from "app/config";
import ImageRenderer from "./imageRenderer";
import Header from "./header";
import { observer } from "mobx-react-lite";
import useStore from "services/store";
import NoData from "./noData";
import { Pagination, Row } from "antd";

const GalleryViewer = observer(
  ({ totalCount, data, showHeader = true, isAdmin = false }) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const { language } = useStore();
    const history = useHistory();
    const location = useLocation();
    const [queryText, setQueryText] = React.useState("");
    const pageSize = 12;

    React.useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      setCurrentPage(parseInt(queryParams.get("page")) || 1);
      setQueryText(queryParams.get("query") || "");
    }, [location]);

    const length = data.length;
    const fakeLength = data.length % 4;
    return (
      <>
        {showHeader && (
          <Header
            title={language === "KOREAN" ? "포토 갤러리" : "Photo Gallery"}
          />
        )}
        <Container>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 },
            ]}
          >
            {data.map((item, idx) => (
              <ImageRenderer
                abstractPhotoGalleryArticle={item}
                key={idx}
                isAdmin={isAdmin}
              />
            ))}
          </Row>

          {/* {[...Array(fakeLength)].map((_, idx) => (
            <ImageRenderer isFake={true} key={idx} />
          ))} */}
          {!data.length && <NoData />}
        </Container>
        <Pagination
          style={{
            marginTop: 50,
            marginBottom: 200,
            display: "flex",
            justifyContent: "center",
          }}
          showSizeChanger={false}
          current={currentPage}
          total={totalCount}
          pageSize={pageSize}
          onChange={(page) => {
            history.push(
              `${window.location.pathname}?page=${page}&query=${queryText}`
            );
          }}
        />
      </>
    );
  }
);

const Container = styled.div`
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 100px;
`;

export default GalleryViewer;
