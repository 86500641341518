import { WarningOutlined } from "@ant-design/icons";
import React from "react";
import { Button, message } from "antd";
import * as api from "services/api";
import { STATUS } from "app/constants";
import { PATH } from "app/config";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import useStore from "services/store";

const DeleteButton = observer(({ galleryID }) => {
  const history = useHistory();
  const { action } = useStore();

  const deleteItem = async () => {
    if (window.confirm("데이터를 삭제하시겠습니까?")) {
      const response = await action.deletePhotoGallery(galleryID);

      if (response.status === STATUS.SUCCESS) {
        history.push(PATH.ADMIN_PHOTO_LIST);
        message.success("성공적으로 삭제되었습니다. ");
      }
    } else {
      message.error("삭제에 실패했습니다.");
    }
  };
  return (
    <Button
      type="default"
      danger={true}
      style={{ fontSize: 14 }}
      onClick={deleteItem}
    >
      <WarningOutlined />
      페이지 삭제
    </Button>
  );
});

export default DeleteButton;
