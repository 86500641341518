import { types, getRoot, flow } from "mobx-state-tree";
import axios from "axios";
import { API_BASE_URL, PATH } from "app/config";
import { STATUS } from "app/constants";

const Action = types
  .model("action", {})
  /** 이미지 배너 슬라이더와 관련한 함수 정의: */
  .actions((self) => ({
    getImageSlider: flow(function* action() {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/image-slider/list`,
        },
      });
    }),
    updateImageSlider: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/image-slider/update`,
          data: data,
        },
      });
    }),
  }))
  /** 연구 업적과 관련한 함수 정의: */
  .actions((self) => ({
    createPaper: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/paper`,
          data: data,
        },
      });
    }),
    getPaperList: flow(function* action(type = null) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/paper`,
          params: {
            type,
          },
        },
      });
    }),
    getPaperDetail: flow(function* action(paperID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/paper/${paperID}`,
        },
      });
    }),
    updatePaper: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/paper/${data.id}`,
          data: data,
        },
      });
    }),
    deletePaper: flow(function* action(dataID) {
      return yield self.request({
        axiosParams: {
          method: "DELETE",
          url: `${API_BASE_URL}/paper/${dataID}`,
        },
      });
    }),
  }))
  /** 연구진 관리와 관련한 함수 정의: */
  .actions((self) => ({
    createAlumniMember: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/admin/alumni-member/create`,
          data: data,
        },
      });
    }),
    getAlumniMemberList: flow(function* action(language) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/alumni-member/list`,
          params: {
            language,
          },
        },
      });
    }),
    getAlumniMemberDetail: flow(function* action(visitorID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/admin/alumni-member/detail/${visitorID}`,
        },
      });
    }),
    updateAlumniMember: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/admin/alumni-member/update/${data.id}`,
          data: data,
        },
      });
    }),
    deleteAlumniMember: flow(function* action(dataID) {
      return yield self.request({
        axiosParams: {
          method: "DELETE",
          url: `${API_BASE_URL}/admin/alumni-member/delete/${dataID}`,
        },
      });
    }),

    createVisitor: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/admin/visitor-member/create`,
          data: data,
        },
      });
    }),
    getVisitorList: flow(function* action(language) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/visitor-member/list`,
          params: {
            language,
          },
        },
      });
    }),
    getVisitorDetail: flow(function* action(visitorID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/visitor-member/detail/${visitorID}`,
        },
      });
    }),
    updateVisitor: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/admin/visitor-member/update/${data.id}`,
          data: data,
        },
      });
    }),
    deleteVisitor: flow(function* action(dataID) {
      return yield self.request({
        axiosParams: {
          method: "DELETE",
          url: `${API_BASE_URL}/admin/visitor-member/delete/${dataID}`,
        },
      });
    }),
    getMemberDetail: flow(function* action(memberID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/member/${memberID}`,
        },
      });
    }),
    getMemberList: flow(function* action(categories, language) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/member/list`,
          params: {
            categories,
            language,
          },
        },
      });
    }),
  }))
  /** 단일 페이지와 관련한 함수 정의: */
  .actions((self) => ({
    getSinglePage: flow(function* action(singlePageID, language) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/single-page/detail/${singlePageID}`,
          params: {
            language,
          },
        },
      });
    }),
  }))
  /** 포럼과 관련한 함수 정의 */
  .actions((self) => ({
    getCalendarEventList: flow(function* action() {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/forum/list/calendar`,
        },
      });
    }),
    getPinnedForumList: flow(function* action(category) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/forum/list/pinned`,
          params: {
            category,
          },
        },
      });
    }),
    createForum: flow(function* action(data) {
      // Change data into formData:
      let formData = new FormData();
      const plainData = {
        title: data.title,
        isSingleDate: data.isSingleDate,
        isPinned: data.isPinned,
        body: data.body,
        singleDate: data.singleDate || "",
        rangeBeginDate: data.rangeBeginDate || "",
        rangeEndDate: data.rangeEndDate || "",
        category: data.category,
        participants: data.participants,
        isLegacy: data.isLegacy,
      };

      const fileData = {
        file1: data.file1,
        file2: data.file2,
        file3: data.file3,
        file4: data.file4,
        file5: data.file5,
      };

      Object.keys(plainData).map((key) => {
        if (plainData[key] !== undefined) formData.append(key, plainData[key]);
      });

      for (const key of Object.keys(fileData)) {
        if (!fileData[key]) continue;
        if (typeof fileData[key] === "string") continue;
        if (!fileData[key][0]) formData.append(key, "");
        else {
          formData.append(key, fileData[key][0], fileData[key][0].name);
        }
      }

      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/forum`,
          data: formData,
        },
      });
    }),
    updateForum: flow(function* action(data) {
      // Change data into formData:
      let formData = new FormData();
      const plainData = {
        title: data.title,
        isSingleDate: data.isSingleDate,
        isPinned: data.isPinned,
        body: data.body,
        singleDate: data.singleDate || "",
        rangeBeginDate: data.rangeBeginDate || "",
        rangeEndDate: data.rangeEndDate || "",
        category: data.category,
        participants: data.participants,
        isLegacy: data.isLegacy,
      };

      const fileData = {
        file1: data.file1,
        file2: data.file2,
        file3: data.file3,
        file4: data.file4,
        file5: data.file5,
      };

      Object.keys(plainData).map((key) => {
        if (plainData[key] !== undefined) formData.append(key, plainData[key]);
      });

      for (const key of Object.keys(fileData)) {
        if (!fileData[key]) continue;
        if (typeof fileData[key] === "string") continue;
        if (!fileData[key][0]) formData.append(key, "");
        else {
          formData.append(key, fileData[key][0], fileData[key][0].name);
        }
      }

      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/forum/${data.id}`,
          data: formData,
        },
      });
    }),
    deleteForum: flow(function* action(forumID) {
      return yield self.request({
        axiosParams: {
          method: "DELETE",
          url: `${API_BASE_URL}/forum/${forumID}`,
        },
      });
    }),
    getForum: flow(function* action(forumID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/forum/detail/${forumID}`,
        },
      });
    }),
    getForumList: flow(function* action(category, page, query, type) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/forum/list`,
          params: {
            category,
            page,
            query,
            type,
          },
        },
      });
    }),
  }))
  .actions((self) => ({
    getPhotoGalleryDetail: flow(function* action(galleryID) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/photo-gallery/${galleryID}`,
        },
      });
    }),
    getPhotoGalleryList: flow(function* action(query, page = 1) {
      return yield self.request({
        axiosParams: {
          method: "GET",
          url: `${API_BASE_URL}/photo-gallery`,
          params: {
            query,
            page
          },
        },
      });
    }),
    createPhotoGallery: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/photo-gallery/create`,
          data: data,
        },
      });
    }),
    updatePhotoGallery: flow(function* action(data) {
      return yield self.request({
        axiosParams: {
          method: "PATCH",
          url: `${API_BASE_URL}/photo-gallery/${data.id}`,
          data: data,
        },
      });
    }),
    deletePhotoGallery: flow(function* action(galleryID) {
      return yield self.request({
        axiosParams: {
          method: "DELETE",
          url: `${API_BASE_URL}/photo-gallery/${galleryID}`,
        },
      });
    }),
    createImage: flow(function* action(image) {
      return yield self.request({
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/image`,
          data: {
            image,
          },
        },
      });
    }),

    validatePermission: flow(function* action(permissionList, history) {
      const permissionCSV = permissionList.join(",");
      const response = yield self.request({
        authorized: true,
        axiosParams: {
          method: "POST",
          url: `${API_BASE_URL}/user/validate`,
          data: {
            permissionCSV: permissionCSV,
          },
        },
      });
      if (response.status !== STATUS.SUCCESS || response.data !== true) {
        history.replace(PATH.LOGIN);
      }
    }),
    login: flow(function* action(username, password) {
      return yield self.request({
        axiosParams: {
          url: `${API_BASE_URL}/user/login`,
          method: "POST",
          data: {
            username: username,
            password: password,
          },
        },
      });
    }),
    request: flow(function* request({
      authorized = false,
      axiosParams = {},
      callback = (responseData) => {
        return responseData;
      },
    }) {
      // axiosParams.timeout = 5000;
      if (authorized)
        axiosParams.headers = {
          Authorization: getRoot(self).token,
        };
      try {
        const response = yield axios(axiosParams);
        return {
          status: "SUCCESS",
          data: callback(response.data),
        };
      } catch (err) {
        // 권한 문제로 생긴 에러일 경우, 강제로 홈으로 리다이렉트 시킵니다:
        if (err.response) {
          if (err.response.status === 403) {
            // Force logout:
            alert("ERROR");
          }
        }
        // 그 외의 경우, 에러 상태를 반환합니다:
        console.log(err);
        return {
          status: "FAILURE",
        };
      }
    }),
  }));

export default Action;
