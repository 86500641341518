import React from "react";
import { LayoutForm2 } from "components/layout";
import Member from "components/member";
import Spinner from "components/spinner";
import {
  ALUMNI_MEMBER_CATEGORY,
  SIDE_PANEL_MENU,
  TABLE_COLUMNS,
} from "app/config";
import { REQUEST_STATUS, PAGE_STATE } from "app/constants";
import useStore from "services/store";
import { observer } from "mobx-react";

const sortByDate = (a, b) => {
  if (a.date < b.date) return 1;
  if (a.date > b.date) return -1;
};

const Members5View = observer(() => {
  const { language, action } = useStore();
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [emeritus, setEmeritus] = React.useState([]);
  const [fullTimes, setFullTimes] = React.useState([]);
  const [researches, setResearches] = React.useState([]);
  const [researchers, setResearchers] = React.useState([]);
  const [visitors, setVisitors] = React.useState({});

  /**
   * 서버에서 '거쳐가신 분' 데이터를 가져오는 함수입니다.
   */
  const initializeData = async () => {
    // 1. 데이터를 요청합니다.
    const response = await action.getAlumniMemberList(language);

    // 2. 요청에 문제가 생기면 에러 상태로 변경하고 함수를 종료합니다.
    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return setPageState(PAGE_STATE.ERROR);
    }

    // 3. 각각의 데이터를 카테고리별로 분리하고, 정렬 후 저장합니다.
    setEmeritus(
      response.data
        .filter((l) => l.category === ALUMNI_MEMBER_CATEGORY.EMERITUS)
        .sort(sortByDate)
    );
    setFullTimes(
      response.data
        .filter(
          (l) => l.category === ALUMNI_MEMBER_CATEGORY.FULL_TIME_PROFESSOR
        )
        .sort(sortByDate)
    );
    setResearches(
      response.data
        .filter((l) => l.category === ALUMNI_MEMBER_CATEGORY.RESEARCH_PROFESSOR)
        .sort(sortByDate)
    );
    setResearchers(
      response.data
        .filter((l) => l.category === ALUMNI_MEMBER_CATEGORY.RESEARCHER)
        .sort(sortByDate)
    );
    setVisitors(
      response.data
        .filter((l) => l.category === ALUMNI_MEMBER_CATEGORY.VISITOR_PROFESSOR)
        .sort(sortByDate)
    );

    // 4. 페이지 상태를 "정상"으로 변경합니다.
    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    initializeData();
  }, [language]);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.members}>
      {pageState === PAGE_STATE.PENDING ? <Spinner /> : null}
      {pageState === PAGE_STATE.NORMAL ? (
        <>
          <Member.Title1
            textMap={{ KOREAN: "정년 퇴임교수", ENGLISH: "Emeritus Professor" }}
          />
          <Member.AlumniTable
            data={emeritus}
            columns={TABLE_COLUMNS.ALUMNI_MEMBERS[language].slice(0, -1)}
          />
          <Member.Title1
            textMap={{
              KOREAN: "퇴직 전임교수",
              ENGLISH: "Previous Full-Time Professor",
            }}
          />
          <Member.AlumniTable
            data={fullTimes}
            columns={TABLE_COLUMNS.ALUMNI_MEMBERS[language].slice(0, -1)}
          />
          <Member.Title1
            textMap={{
              KOREAN: "퇴직 연구교수",
              ENGLISH: "Previous Research Professor",
            }}
          />
          <Member.AlumniTable
            data={researches}
            columns={TABLE_COLUMNS.ALUMNI_MEMBERS[language].slice(0, -1)}
          />
          <Member.Title1
            textMap={{ KOREAN: "퇴직 연구원", ENGLISH: "Previous Researcher" }}
          />
          <Member.AlumniTable
            data={researchers}
            columns={TABLE_COLUMNS.ALUMNI_MEMBERS[language].slice(0, -1)}
          />
          <Member.Title1
            textMap={{
              KOREAN: "퇴직 방문교수",
              ENGLISH: "Previous Visitor Professor",
            }}
          />
          <Member.AlumniTable
            data={visitors}
            columns={TABLE_COLUMNS.ALUMNI_MEMBERS[language].slice(0, -1)}
          />
        </>
      ) : null}
    </LayoutForm2>
  );
});

export default Members5View;
