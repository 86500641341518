import { types } from "mobx-state-tree";

const AdminPaperStore = types
  .model({
    id: types.maybeNull(types.string),
    title: types.optional(types.string, ""),
    year: types.optional(types.string, ""),
    author: types.optional(types.string, ""),
    journal: types.optional(types.string, ""),
    DOI: types.optional(types.string, ""),
    DOILink: types.optional(types.string, ""),
    arXiv: types.optional(types.string, ""),
    cquestNumber: types.optional(types.string, ""),
    publishYear: types.optional(types.string, ""),
  })
  .actions((self) => ({
    set(kvDict) {
      for (const key of Object.keys(kvDict)) {
        self[key] = kvDict[key];
      }
    },
  }));

export default AdminPaperStore;
