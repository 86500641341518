import { PATH } from "app/config";
import LoginView from "./login";

import LandingView from "./landing";
import Overview1View from "./overview1";
import Overview2View from "./overview2";
import Overview3View from "./overview3";
import Overview4View from "./overview4";
import Overview5View from "./overview5";
import Members1View from "./members1";
import Members2View from "./members2";
import Members3View from "./members3";
import Members4View from "./members4";
import Members5View from "./members5";
import Achievements1View from "./achievements1";
import Achievements2View from "./achievements2";
import Achievements3View from "./achievements3";
import Forum1View from "./forum1";
import Forum2View from "./forum2";
import Forum3View from "./forum3";
import Forum4View from "./forum4";
import Forum5View from "./forum5";
import Forum6View from "./forum6";
import ForumGalleryView from "./forumGallery";

import AdminMemberOptionView from "./adminMemberOption";
import AdminMemberListView from "./adminMemberList";
import AdminImageSliderView from "./adminImageSlider";
import AdminForumEditView from "./adminForumEdit";
import AdminForumNewView from "./adminForumNew";
import ForumArticleView from "./forumArticle";
import Collaboration1View from "./collaboration1";
import Collaboration2View from "./collaboration2";
import External1View from "./external1";
import External2View from "./external2";
import External3View from "./external3";
import AdminLandingView from "./adminLanding";
import AdminMemberEditView from "./adminMemberEdit";
import AdminMemberVisitorEditView from "./adminMemberVisitorEdit";
import AdminMemberPreviousEditView from "./adminMemberPreviousEdit";
import AdminSinglePageListView from "./adminSinglePageList";
import AdminSinglePageEditView from "./adminSinglePageEdit";
import AdminEventPageListView from "./adminEventPageList";
import AdminEventPageEditView from "./adminEventPageEdit";
import AdminForumListView from "./adminForumList";
import EventArticleView from "./eventArticle";
import AdminPaperEditView from "./adminPaperEdit";
import AdminPaperListView from "./adminPaperList";
import AdminPhotoListView from "./adminPhotoList";
import LogoutView from "./logout";
import AdminPhotoNewView from "./adminPhotoNew";
import AdminPhotoEditView from "./adminPhotoEdit";

const routes = [
  { path: PATH.OVERVIEW1, component: Overview1View },
  { path: PATH.OVERVIEW2, component: Overview2View },
  { path: PATH.OVERVIEW3, component: Overview3View },
  { path: PATH.OVERVIEW4, component: Overview4View },
  { path: PATH.OVERVIEW5, component: Overview5View },

  {
    path: PATH.LOGIN,
    component: LoginView,
  },

  {
    path: PATH.LANDING,
    component: LandingView,
  },
  {
    path: PATH.MEMBERS1,
    component: Members1View,
  },
  {
    path: PATH.MEMBERS2,
    component: Members2View,
  },
  {
    path: PATH.MEMBERS3,
    component: Members3View,
  },
  {
    path: PATH.MEMBERS4,
    component: Members4View,
  },
  {
    path: PATH.MEMBERS5,
    component: Members5View,
  },
  {
    path: PATH.ACHIEVEMENTS1,
    component: Achievements1View,
  },
  {
    path: PATH.ACHIEVEMENTS2,
    component: Achievements2View,
  },
  {
    path: PATH.ACHIEVEMENTS3,
    component: Achievements3View,
  },
  {
    path: PATH.FORUM1,
    component: Forum1View,
  },
  {
    path: PATH.FORUM2,
    component: Forum2View,
  },
  {
    path: PATH.FORUM3,
    component: Forum3View,
  },
  {
    path: PATH.FORUM4,
    component: Forum4View,
  },
  {
    path: PATH.FORUM5,
    component: Forum5View,
  },
  {
    path: PATH.FORUM6,
    component: Forum6View,
  },
  {
    path: PATH.FORUM_GALLERY,
    component: ForumGalleryView,
  },
  {
    path: PATH.FORUM_ARTICLE,
    component: ForumArticleView,
  },
  {
    path: PATH.COLLABORATION1,
    component: Collaboration1View,
  },
  {
    path: PATH.COLLABORATION2,
    component: Collaboration2View,
  },
  {
    path: PATH.EXTERNAL1,
    component: External1View,
  },
  {
    path: PATH.EXTERNAL2,
    component: External2View,
  },
  {
    path: PATH.EXTERNAL3,
    component: External3View,
  },

  // ADMIN
  { path: PATH.ADMIN_LANDING, component: AdminLandingView },
  { path: PATH.ADMIN_MEMBER_OPTION, component: AdminMemberOptionView },
  { path: PATH.ADMIN_MEMBER_MEMBER_LIST, component: AdminMemberListView },
  { path: PATH.ADMIN_MEMBER_MEMBER_EDIT, component: AdminMemberEditView },
  {
    path: PATH.ADMIN_MEMBER_VISITOR_EDIT,
    component: AdminMemberVisitorEditView,
  },
  {
    path: PATH.ADMIN_MEMBER_PREVIOUS_EDIT,
    component: AdminMemberPreviousEditView,
  },
  {
    path: PATH.ADMIN_EVENT_PAGE_LIST,
    component: AdminEventPageListView,
  },
  { path: PATH.ADMIN_EVENT_PAGE_EDIT, component: AdminEventPageEditView },
  { path: PATH.ADMIN_IMAGE_SLIDER, component: AdminImageSliderView },

  {
    path: PATH.ADMIN_SINGLE_PAGE_LIST,
    component: AdminSinglePageListView,
  },
  { path: PATH.ADMIN_SINGLE_PAGE_EDIT, component: AdminSinglePageEditView },
  { path: PATH.ADMIN_FORUM_LIST, component: AdminForumListView },
  { path: PATH.ADMIN_FORUM_NEW, component: AdminForumNewView },
  { path: PATH.ADMIN_FORUM_EDIT, component: AdminForumEditView },
  { path: PATH.EVENT_ARTICLE, component: EventArticleView },
  { path: PATH.ADMIN_PAPER_LIST, component: AdminPaperListView },
  { path: PATH.ADMIN_PAPER_EDIT, component: AdminPaperEditView },
  { path: PATH.ADMIN_PHOTO_LIST, component: AdminPhotoListView },
  { path: PATH.ADMIN_PHOTO_NEW, component: AdminPhotoNewView },
  { path: PATH.ADMIN_PHOTO_EDIT, component: AdminPhotoEditView },
  { path: PATH.LOGOUT, component: LogoutView },
];

export default routes;
