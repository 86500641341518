export const STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export const REQUEST_STATUS = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export const PAGE_STATE = {
  NORMAL: "NORMAL",
  ERROR: "ERROR",
  PENDING: "PENDING",
};

// export const API_BASE_URL = "http://cquest.sogang.ac.kr:8000";
// // export const API_BASE_URL = "http://localhost:8000";
// // export const API_BASE_URL = "http://192.168.0.5:8000";

// export const TOKEN_REFRESH_TRIGGER = 60 * 5; // seconds

// export const DISPLAY_MODE = {
//   XLARGE: "XLARGE",
//   LARGE: "LARGE",
//   MEIDUM: "MEDIUM",
//   SMALL: "SMALL",
// };

// export const STATUS = {
//   PENDING: "PENDING",
//   SUCCESS: "SUCCESS",
//   FAILURE: "FAILURE",
// };

// export const LANGUAGE = {
//   KOREAN: "KOREAN",
//   ENGLISH: "ENGLISH",
// };

// export const DISPLAY_TYPE = {
//   DESKTOP: "DESKTOP",
//   MOBILE: "MOBILE",
// };

// export const SERIALIZED_DATA_ID = {
//   MEMBER_CORE: "member-core",
//   MEMBER_RESEARCH_PROFESSOR: "member-research-professor",
//   MEMBER_RESEARCHER: "member-researcher",
//   MEMBER_STUDENT: "member-student",
//   MEMBER_VISITOR: "member-visitor",
//   MEMBER_PREVIOUS_FULL_TIME_PROFESSOR: "member-previous-full-time-professor",
//   MEMBER_PREVIOUS_RESEARCH_PROFESSOR: "member-previous-research-professor",
//   MEMBER_PREVIOUS_RESEARCHER: "member-previous-researcher",
//   MEMBER_PREVIOUS_VISITOR_PROFESSOR: "member-previous-visitor-professor",
//   MEMBER_EXTERNAL: "member-external",
//   MEMBER_EMERITUS: "member-emeritus",
//   MEMBER_COMPLETION: "member-completion",
//   ACHIEVEMENT: "achievement",
// };

// export const FORUM_CATEGORY = {
//   CONFERENCE: {
//     name: "CONFERENCE",
//     color: "#d15980",
//     pathID: "FORUM_CONFERENCE",
//     articlePathID: "FORUM_CONFERENCE_ARTICLE",
//   },
//   SEMINAR: {
//     name: "SEMINAR",
//     color: "#323278",
//     pathID: "FORUM_SEMINAR",
//     articlePathID: "FORUM_SEMINAR_ARTICLE",
//   },
//   EXTERNAL: {
//     name: "EXTERNAL",
//     color: "#648b7f",
//     pathID: "FORUM_EXTERNAL",
//     articlePathID: "FORUM_EXTERNAL_ARTICLE",
//   },
//   ANNOUNCEMENT: {
//     name: "ANNOUNCEMENT",
//     color: "#ffd773",
//     pathID: "FORUM_ANNOUNCEMENT",
//     articlePathID: "FORUM_ANNOUNCEMENT_ARTICLE",
//   },
// };
