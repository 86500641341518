import React from "react";
import { LayoutForm1 } from "components/layout";
import Landing from "components/landing";
import Spinner from "components/spinner";
import * as api from "services/api";
import Forum from "components/forum";
import LoginForm from "components/loginForm";

const LoginView = () => {
  React.useEffect(() => {}, []);

  return (
    <LayoutForm1>
      <LoginForm />
    </LayoutForm1>
  );
};

export default LoginView;
