import Header from "components/header";
import TextEditor from "components/new/textEditor";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import TitleRow from "./titleRow";
import ImageUploader from "./imageUploader";
import { toJS } from "mobx";
import useStore from "services/store";
import { REQUEST_STATUS } from "app/constants";
import { message } from "antd";
import DeleteButton from "./deleteButton";
import { useHistory } from "react-router";

/**
 * PhotoEditor입니다.
 */
const Editor = observer(({ photoGalleryArticleModel }) => {
  const { action } = useStore();
  const bodyRef = React.useRef(null);
  const history = useHistory();

  /**
   * 데이터 생성
   */
  const onCreate = async () => {
    const data = preprocess();

    const response = await action.createPhotoGallery(data);

    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return message.error("생성에 실패했습니다.");
    }

    history.goBack();
    return message.success("성공적으로 생성했습니다.");
  };
  const onEdit = async () => {
    const data = preprocess();

    console.log(data);

    const response = await action.updatePhotoGallery(data);

    if (response.status !== REQUEST_STATUS.SUCCESS) {
      return message.error("수정에 실패했습니다.");
    }

    return message.success("성공적으로 수정했습니다.");
  };

  /** POST / PATCH 이전에 데이터를 가공합니다. */
  const preprocess = () => {
    const data = {
      id: photoGalleryArticleModel.id,
      title: photoGalleryArticleModel.title,
      body: bodyRef.current?.getContent(),
      beginsAt: photoGalleryArticleModel.beginsAt
        ? photoGalleryArticleModel.beginsAt.format("YYYY-MM-DD HH:mm:ss")
        : null,
      endsAt: photoGalleryArticleModel.endsAt
        ? photoGalleryArticleModel.endsAt.format("YYYY-MM-DD HH:mm:ss")
        : null,
      imageList: photoGalleryArticleModel.imageList.join(","),
      topImageURL: photoGalleryArticleModel.imageList[0] || null,
    };
    return data;
  };

  return (
    <Container>
      <Header
        title={!photoGalleryArticleModel.id ? "새 아티클 작성" : "아티클 수정"}
        onSave={!photoGalleryArticleModel.id ? onCreate : onEdit}
        onSaveText={!photoGalleryArticleModel.id ? "생성" : "저장"}
      />
      <TitleRow photoGalleryArticleModel={photoGalleryArticleModel} />
      <TextEditor
        initialValue={photoGalleryArticleModel.body}
        editorRef={bodyRef}
        height={200}
      />
      <div style={{ height: 40 }} />
      <ImageUploader
        initialState={photoGalleryArticleModel.imageList.slice().map((url) => ({
          source: url,
          name: url.split("/").slice(-1),
        }))}
        setState={(val) =>
          photoGalleryArticleModel.set({
            imageList: val,
          })
        }
      />
      <div style={{ height: 80 }} />
      <DeleteButton galleryID={photoGalleryArticleModel.id} />
      <div style={{ height: 120 }} />
    </Container>
  );
});

const Container = styled.div``;

export default Editor;
