import React from "react";
import { LayoutForm2 } from "components/layout";
import SinglePage from "components/singlePage";
import { SIDE_PANEL_MENU, SINGLE_PAGE_ID } from "app/config";
import useStore from "services/store";
import { observer } from "mobx-react";
import Spinner from "components/spinner";
import helpers from "helpers";
import { PAGE_STATE } from "app/constants";

const Collaboration2View = observer(() => {
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);
  const [data, setData] = React.useState(null);
  const { language, action } = useStore();

  React.useEffect(() => {
    helpers.request.getSinglePage(
      action,
      SINGLE_PAGE_ID.COLLABORATION2,
      setData,
      setPageState
    );
  }, [language]);

  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.collaboration}>
      {pageState === "PENDING" ? <Spinner /> : null}
      {pageState === "NORMAL" ? <SinglePage.Viewer data={data} /> : null}
    </LayoutForm2>
  );
});

export default Collaboration2View;
