import React from "react";
import { Card, Col, Image, Row, Space } from "antd";
import { grey } from "@ant-design/colors";
import { ExportOutlined } from "@ant-design/icons";
import EmptyMemberProfilePNG from "graphic/emptyMemberProfile.png";

const MemberCard = ({
  base64Image = "",
  image,
  name,
  email,
  fields,
  style,
  onClick,
}) => {
  return (
    <Card style={style} onClick={onClick}>
      <Row>
        <Col xs={24} md={0}>
          <Space direction="vertical" align="center" style={{ width: "100%" }}>
            <Image
              fallback={EmptyMemberProfilePNG}
              preview={false}
              src={base64Image || image || EmptyMemberProfilePNG}
              style={{
                width: 150,
                height: 150,
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </Space>
          <Space align="center" direction="vertical" style={{ width: "100%" }}>
            <div style={{ fontSize: 18, color: grey[8] }}>{name}</div>
            <div style={{ fontSize: 12, color: grey[0], marginTop: -8 }}>
              {email}
            </div>
          </Space>
          <div style={{ height: 30 }} />
          {Object.keys(fields)
            .filter((l) => !l.startsWith("__"))
            .map((key, idx) => (
              <Row style={{ textAlign: "center" }}>
                <Col xs={24}>
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: 12,
                      color: grey[1],
                      marginTop: 8,
                    }}
                  >
                    {key}
                  </span>
                </Col>
                <Col xs={24}>
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: 14,
                      color: grey[6],
                    }}
                  >
                    {Object.keys(fields).includes(`__${key}`) ? (
                      <a href={fields[`__${key}`]}>
                        {fields[key]}
                        <ExportOutlined style={{ paddingLeft: 10 }} />
                      </a>
                    ) : (
                      fields[key]
                    )}
                  </span>
                </Col>
              </Row>
            ))}
        </Col>
        <Col xs={0} md={24}>
          <Space align="start" direction="horizontal">
            <Image
              fallback={EmptyMemberProfilePNG}
              preview={false}
              src={base64Image || image || EmptyMemberProfilePNG}
              style={{
                width: 150,
                height: 150,
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <Space
              align="start"
              direction="vertical"
              style={{ marginLeft: 20 }}
            >
              <Space align="baseline" direction="horizontal">
                <span style={{ fontSize: 24, color: grey[8] }}>{name}</span>
                <span style={{ fontSize: 14, color: grey[0] }}>{email}</span>
              </Space>
              {Object.keys(fields)
                .filter((l) => !l.startsWith("__"))
                .map((key, idx) => (
                  <Space align="start" direction="horizontal" key={idx}>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: 14,
                        color: grey[1],
                        width: 120,
                      }}
                    >
                      {key}
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: 14,
                        color: grey[4],
                      }}
                    >
                      {Object.keys(fields).includes(`__${key}`) ? (
                        <a href={fields[`__${key}`]}>
                          {fields[key]}
                          <ExportOutlined style={{ paddingLeft: 10 }} />
                        </a>
                      ) : (
                        fields[key]
                      )}
                    </span>
                  </Space>
                ))}
            </Space>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default MemberCard;
