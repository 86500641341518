import { ADMIN_PERMISSION, SIDE_PANEL_MENU } from "app/config";
import { LayoutForm2 } from "components/layout";
import Event from "components/event";
import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import useStore from "services/store";

const AdminEventPageEditView = observer(() => {
  const { action } = useStore();
  const history = useHistory();

  React.useEffect(() => {
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      <Event.Edit />
      {/* {state === "PENDING" ? <Spinner /> : null}
    {state === "SUCCESS" ? <SinglePage.Viewer /> : null}
    {state === "FAILURE" ? <></> : null} */}
    </LayoutForm2>
  );
});

export default AdminEventPageEditView;
