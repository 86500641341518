import { Input, message } from "antd";
import Header from "components/header";
import React from "react";
import styled from "styled-components";
import OriginalSunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import * as api from "services/api";
import { STATUS } from "app/constants";
import { useHistory, useParams } from "react-router";
import { PATH } from "app/config";
import DeleteButton from "./deleteButton";
import TextEditor from "components/new/textEditor";

const Edit = () => {
  const history = useHistory();
  const { eventID } = useParams();
  const [pageState, setPageState] = React.useState("PENDING");
  const [isNewArticle] = React.useState(eventID?.toLowerCase() === "new");
  const [title, setTitle] = React.useState("");

  const editorRef = React.useRef(null);
  const [editorState, setEditorState] = React.useState(null);

  React.useEffect(() => {
    if (!isNewArticle) {
      api
        .event__detail(eventID)
        .then((response) => {
          if (response.status === STATUS.SUCCESS) {
            setTitle(response.data.title);
            setEditorState(response.data.body);
          }
        })
        .then(() => {
          setPageState("NORMAL");
        });
    } else {
      setPageState("NORMAL");
    }
  }, []);

  const onSave = () => {
    const finalData = { title, body: editorRef.current?.getContent() };

    if (isNewArticle) {
      api.event__create(finalData).then((response) => {
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 생성했습니다.");
          history.push(
            PATH.EVENT_ARTICLE.replace(":eventID", response.data.id)
          );
        } else {
          message.error("생성에 실패했습니다.");
        }
      });
    } else {
      api.event__update({ eventID, ...finalData }).then((response) => {
        if (response.status === STATUS.SUCCESS) {
          message.success("성공적으로 수정했습니다.");
          history.push(
            PATH.EVENT_ARTICLE.replace(":eventID", response.data.id)
          );
        } else {
          message.error("데이터 수정에 실패했습니다.");
        }
      });
    }
  };

  return (
    pageState === "NORMAL" && (
      <Container>
        <Header
          title={isNewArticle ? "새 이벤트 작성" : "이벤트 수정"}
          onSave={onSave}
          onSaveText={isNewArticle ? "생성" : "저장"}
        />
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="이벤트명"
          maxLength={200}
          style={{ fontSize: "18px", padding: 8, marginBottom: 10 }}
        />
        <TextEditor editorRef={editorRef} initialValue={editorState} />
        {/* <OriginalSunEditor
          width="100%"
          height="500px"
          defaultValue={editorState}
          onChange={(content) => setEditorState(content)}
          setOptions={{
            buttonList: buttonList.complex,
            templates: [],
            callBackSave: onSave,
          }}
          setDefaultStyle="font-size:16px; line-height: 1.5"
        /> */}
        {!isNewArticle && (
          <>
            <div style={{ height: 100 }} />
            <DeleteButton eventID={eventID} />
          </>
        )}
      </Container>
    )
  );
};

const Container = styled.div`
  margin-bottom: 120px;
`;

export default Edit;
