import React from "react";
import styled from "styled-components";

import { observer } from "mobx-react";
import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import useStore from "services/store";
import { Divider, Tag } from "antd";
import helpers from "helpers";
import { API_BASE_URL, PATH } from "app/config";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router";

const GalleryDetail = observer(({ photoGalleryArticleModel }) => {
  const history = useHistory();
  const { token } = useStore();
  return (
    <Container>
      <Title>
        <ArrowLeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => window.history.back()}
        />
        &nbsp;&nbsp;{photoGalleryArticleModel.title}
        &nbsp;&nbsp;
        {token && (
          <SettingOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={() =>
              history.push(
                PATH.ADMIN_PHOTO_EDIT.replace(
                  ":galleryID",
                  photoGalleryArticleModel.id
                )
              )
            }
          />
        )}
      </Title>
      <TagArea>
        {photoGalleryArticleModel.beginsAt && (
          <Tag
            style={{
              fontSize: 11,
              backgroundColor: "#707070",
              color: "white",
              fontSize: 14,
            }}
          >
            &nbsp;
            <ClockCircleOutlined />
            &nbsp;
            {photoGalleryArticleModel.beginsAt ===
            photoGalleryArticleModel.endsAt
              ? `${helpers.date.toFormat1(photoGalleryArticleModel.beginsAt)}`
              : `${helpers.date.toFormat1(
                  photoGalleryArticleModel.beginsAt
                )} ~ ${helpers.date.toFormat1(
                  photoGalleryArticleModel.endsAt
                )}`}
            &nbsp;
          </Tag>
        )}
      </TagArea>
      <Divider />
      <Body>
        <div
          className="mce-content-body"
          dangerouslySetInnerHTML={{ __html: photoGalleryArticleModel.body }}
        ></div>
      </Body>
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={"20%"}
        dynamicHeight={true}
      >
        {photoGalleryArticleModel.imageList.split(",").map((imgSrc, idx) => (
          <div key={idx}>
            <img src={`${imgSrc}`} />
          </div>
        ))}
      </Carousel>
    </Container>
  );
});

const Container = styled.div`
  margin-bottom: 200px;
`;

const Title = styled.div`
  font-size: 20px;
`;

const Body = styled.div`
  margin-bottom: 40px;
`;

const TagArea = styled.div`
  margin-top: 20px;
`;

export default GalleryDetail;
