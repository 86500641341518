import {
  ADMIN_PERMISSION,
  SIDE_PANEL_MENU,
  SINGLE_PAGE_ADMIN_NAME_ID_MAP,
} from "app/config";
import { LayoutForm2 } from "components/layout";
import SinglePage from "components/singlePage";
import Spinner from "components/spinner";
import React from "react";
import * as api from "services/api";
import Header from "components/header";
import { useHistory } from "react-router";
import useStore from "services/store";

const AdminSinglePageListView = () => {
  const history = useHistory();
  const { action } = useStore();
  const [data, setData] = React.useState(null);
  const [pageState, setPageState] = React.useState("PENDING");

  React.useEffect(() => {
    action
      .validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history)
      .then(() => {
        api.singlePage__boolList().then((response) => {
          if (response.status === "SUCCESS") {
            setData(
              response.data.map((item) => {
                item["id"] = item["id"].split("_")[0];
                item["name"] = SINGLE_PAGE_ADMIN_NAME_ID_MAP[item["id"]];

                item["korean"] =
                  item["korean"] === true ? "사용중" : "사용 안함";
                item["english"] =
                  item["english"] === true ? "사용중" : "사용 안함";
                return item;
              })
            );
            setPageState("NORMAL");
          }
        });
      });
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === "PENDING" ? <Spinner /> : null}
      {pageState === "NORMAL" ? (
        <>
          <Header title="단일 페이지 관리" />
          <SinglePage.List data={data} />
        </>
      ) : null}
      {pageState === "ERROR" ? <></> : null}
    </LayoutForm2>
  );
};

export default AdminSinglePageListView;
