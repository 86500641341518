import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, SIDE_PANEL_MENU } from "app/config";
import { observer } from "mobx-react";
import useStore from "services/store";
import { useHistory, useParams } from "react-router";
import { PAGE_STATE, STATUS } from "app/constants";
import Spinner from "components/spinner";
import Header from "components/layout/header";
import Photo from "components/photo";
import { PhotoGalleryArticleModel } from "services/models";
import moment from "moment";
import { toJS } from "mobx";

const AdminPhotoEditView = observer(() => {
  const { action } = useStore();
  const { galleryID } = useParams();
  const history = useHistory();

  const [dataModel, setDataModel] = React.useState(null);
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);

  const initializeData = async () => {
    const response = await action.getPhotoGalleryDetail(galleryID);

    if (response.status !== STATUS.SUCCESS) {
      return;
    }
    let initialData = response.data;

    initialData.id = galleryID;
    initialData.beginsAt = moment(initialData.beginsAt).isValid()
      ? moment(initialData.beginsAt)
      : null;
    initialData.endsAt = moment(initialData.endsAt).isValid()
      ? moment(initialData.endsAt)
      : null;
    initialData.imageList = initialData.imageList.split(",");

    const photoModel = PhotoGalleryArticleModel.create(initialData);
    setDataModel(photoModel);

    setPageState(PAGE_STATE.NORMAL);
  };

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
    initializeData();
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Photo.Editor photoGalleryArticleModel={dataModel} />
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminPhotoEditView;
