import React from "react";
import { LayoutForm1 } from "components/layout";
import { observer } from "mobx-react";
import Event from "components/event";
import Spinner from "components/spinner";
import * as api from "services/api";
import { useLocation, useParams } from "react-router";

const EventArticleView = observer(() => {
  const location = useLocation();
  const { eventID } = useParams();

  const [pageState, setPageState] = React.useState("PENDING");
  const [detailData, setDetailData] = React.useState(null);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    api
      .event__detail(eventID)
      .then((response) => {
        if (response.status === "SUCCESS") {
          setDetailData(response.data);
        }
      })
      .then(() => {
        setPageState("NORMAL");
      });
  }, [location]);

  return (
    <LayoutForm1>
      {pageState === "PENDING" && <Spinner />}
      {pageState === "NORMAL" && <Event.Viewer data={detailData} />}
    </LayoutForm1>
  );
});

export default EventArticleView;
