import React from "react";
import { LayoutForm2 } from "components/layout";
import { ADMIN_PERMISSION, SIDE_PANEL_MENU } from "app/config";
import Forum from "components/forum";
import { observer } from "mobx-react";
import useStore from "services/store";
import { useHistory, useParams } from "react-router";
import { PAGE_STATE, STATUS } from "app/constants";
import Spinner from "components/spinner";
import Header from "components/layout/header";

const AdminForumEditView = observer(() => {
  const { action } = useStore();
  const history = useHistory();
  const initialData = {
    id: null,
    title: "",
    category: "CONFERENCE",
    isSingleDate: true,
    singleDate: null,
    isLegacy: false,
  };
  const [pageState, setPageState] = React.useState(PAGE_STATE.PENDING);

  React.useEffect(() => {
    /** permission이 없을 시 로그인 페이지로 리다이렉트 합니다: */
    action.validatePermission(ADMIN_PERMISSION.EDIT_ARTICLES, history);
    setPageState(PAGE_STATE.NORMAL);
  }, []);
  return (
    <LayoutForm2 sidePanelMenu={SIDE_PANEL_MENU.admin}>
      {pageState === PAGE_STATE.PENDING && <Spinner />}
      {pageState === PAGE_STATE.NORMAL && (
        <>
          <Forum.Editor initialData={initialData} />
        </>
      )}
    </LayoutForm2>
  );
});

export default AdminForumEditView;
