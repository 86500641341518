import Calendar from "./calendar";
import List from "./list";
import Edit from "./edit";
import Viewer from "./viewer";
import Editor from "./editor";
import GalleryDetail from "./galleryDetail";
import GalleryList from "./galleryList";

const Forum = {
  Calendar,
  List,
  Edit,
  Viewer,
  Editor,
  GalleryDetail,
  GalleryList,
};

export default Forum;
