import React from "react";
import styled from "styled-components";

const Viewer = ({ data }) => {
  return (
    <Container>
      <div
        className="single-page-container"
        style={{ display: "block", margin: "0 auto" }}
        dangerouslySetInnerHTML={{ __html: data.body }}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 200px;
  margin-top: 50px;
`;

export default Viewer;
