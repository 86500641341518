import React from "react";
import { Col, Drawer, Layout, Menu, Row } from "antd";
import { grey } from "@ant-design/colors";
import LogoPNG from "graphic/header/logo.png";
import LanguagePNG from "graphic/header/language.png";
import { MenuOutlined } from "@ant-design/icons";
import { PATH } from "app/config";
import { Link, useHistory } from "react-router-dom";
import useStore from "services/store";
import { observer } from "mobx-react";

const { SubMenu } = Menu;
const Header = observer(() => {
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const history = useHistory();
  const { language, setLanguage, token } = useStore();
  const handleClick = (e) => {
    switch (e.key) {
      case "language:korean":
        setLanguage("KOREAN");
        break;
      case "language:english":
        setLanguage("ENGLISH");
        break;

      case "link:blog":
        window.open("https://blog.naver.com/cquest_official", "_blank");
        break;
      case "link:previous":
        window.open("http://cquest_src.sogang.ac.kr", "_blank");
        break;
      default:
        break;
    }
  };
  const getCurrentKey = () => {
    for (const key of [
      "overview",
      "members",
      "achievements",
      "collaboration",
      "forum",
      "external",
    ])
      if (window.location.pathname.startsWith(`/${key}`)) {
        return key;
      }
  };
  return (
    <Layout.Header
      style={{
        zIndex: 999,
        position: "fixed",
        width: "100%",
        background: "#fff",
        height: 100,
        paddingTop: 35,
        borderBottomWidth: 1,
        borderBottomColor: "#efefef",
        borderBottomStyle: "solid",
      }}
    >
      <img
        alt="Logo"
        onClick={() => history.push(PATH.LANDING)}
        src={LogoPNG}
        style={{
          cursor: "pointer",
          position: "absolute",
          width: 200,
          height: 54,
          zIndex: 2,
        }}
      />
      <Drawer
        title="MENU"
        placement="right"
        closable={true}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <Menu>
          <Menu.ItemGroup
            title={language === "ENGLISH" ? "OVERVIEW" : "센터소개"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="overview1">
              <Link to={PATH.OVERVIEW1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "GREETINGS" : "인사말"}
              </Link>
            </Menu.Item>
            <Menu.Item key="overview2">
              <Link to={PATH.OVERVIEW2} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "INTRODUCTION" : "센터소개"}
              </Link>
            </Menu.Item>
            <Menu.Item key="overview3">
              <Link to={PATH.OVERVIEW3} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "PURPOSE" : "센터목적"}
              </Link>
            </Menu.Item>
            <Menu.Item key="overview4">
              <Link to={PATH.OVERVIEW4} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "ORGANIZATION" : "센터조직도"}
              </Link>
            </Menu.Item>
            <Menu.Item key="overview5">
              <Link to={PATH.OVERVIEW5} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "CONTACT" : "센터연락처"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            title={language === "ENGLISH" ? "MEMBERS" : "연구진소개"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="member1">
              <Link to={PATH.MEMBERS1} style={{ color: "inherit" }}>
                {language === "ENGLISH"
                  ? "TENURED RESEARCHERS"
                  : "전임연구인력"}
              </Link>
            </Menu.Item>
            <Menu.Item key="member2">
              <Link to={PATH.MEMBERS2} style={{ color: "inherit" }}>
                {language === "ENGLISH"
                  ? "NON-TENURED RESEARCHERS"
                  : "비전임연구인력"}
              </Link>
            </Menu.Item>
            <Menu.Item key="member3">
              <Link to={PATH.MEMBERS3} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "STUDENTS" : "학생 연구원"}
              </Link>
            </Menu.Item>
            <Menu.Item key="member4">
              <Link to={PATH.MEMBERS4} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "VISITORS" : "방문연구원"}
              </Link>
            </Menu.Item>
            <Menu.Item key="member5">
              <Link to={PATH.MEMBERS5} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "ALUMNI" : "거쳐가신 분"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            title={language === "ENGLISH" ? "ACHIEVEMENTS" : "연구업적"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="achievements1">
              <Link
                to={PATH.ACHIEVEMENTS1}
                style={{ color: "inherit", marginTop: 20 }}
              >
                {language === "ENGLISH" ? "PREPRINT" : "게재논문"}
              </Link>
            </Menu.Item>
            <Menu.Item key="achievements2">
              <Link to={PATH.ACHIEVEMENTS2} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "PUBLISHED" : "출판논문"}
              </Link>
            </Menu.Item>
            <Menu.Item key="achievements3">
              <Link to={PATH.ACHIEVEMENTS3} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "ACKNOWLEDGEMENT" : "사사"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            title={language === "ENGLISH" ? "COLLABORATION" : "대외협력"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="collaboration1">
              <Link to={PATH.COLLABORATION1} style={{ color: "inherit" }}>
                {language === "ENGLISH"
                  ? "INTERNATIONAL COOPERATION"
                  : "국제협력"}
              </Link>
            </Menu.Item>
            <Menu.Item key="collaboration2">
              <Link to={PATH.COLLABORATION2} style={{ color: "inherit" }}>
                {language === "ENGLISH"
                  ? "Academic-Industry-cooperation and Popularization of Science"
                  : "산학협력과 과학대중화"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            title={language === "ENGLISH" ? "FORUM" : "센터활동"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="forum1">
              <Link to={PATH.FORUM1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "CALENDAR" : "센터일정"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum2">
              <Link to={PATH.FORUM2} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "CONFERENCE" : "학회"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum3">
              <Link to={PATH.FORUM3} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "SEMINAR" : "세미나"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum4">
              <Link to={PATH.FORUM4} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "EXTERNAL EVENTS" : "외부행사"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum5">
              <Link to={PATH.FORUM5} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "ANNOUNCEMENT" : "새소식"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum6">
              <Link to={PATH.FORUM6} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "PHOTO GALLERY" : "포토갤러리"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            title={language === "ENGLISH" ? "EXTERNAL" : "관련사이트"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item key="external1">
              <Link to={PATH.EXTERNAL1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "INSTITUTE" : "관련기관"}
              </Link>
            </Menu.Item>
            <Menu.Item key="external2">
              <Link to={PATH.EXTERNAL2} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "JOURNAL" : "학회 및 학술지"}
              </Link>
            </Menu.Item>
            <Menu.Item key="external3">
              <Link to={PATH.EXTERNAL3} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "OTHERS" : "기타 사이트"}
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <div style={{ marginTop: 20 }} />

          <Menu.Item key="youtube">
            <a
              href="https://www.youtube.com/channel/UClW7dMDEY_eFUiLtwD-uZyg"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
              target="_blank"
            >
              {language === "ENGLISH" ? "YouTube" : "유튜브"}
            </a>
          </Menu.Item>

          <Menu.Item key="blog">
            <a
              href="https://blog.naver.com/cquest_official"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
              target="_blank"
            >
              {language === "ENGLISH" ? "BLOG" : "블로그"}
            </a>
          </Menu.Item>
          <Menu.Item key="prev-cquest">
            <a
              href="http://cquest_src.sogang.ac.kr"
              rel="noopener noreferrer"
              style={{ color: "inherit" }}
              target="_blank"
            >
              {language === "ENGLISH" ? "PREVIOUS CQUeST" : "(구)홈페이지"}
            </a>
          </Menu.Item>
          <Menu.ItemGroup
            title={language === "ENGLISH" ? "LANGUAGE" : "언어 변경"}
            style={{ marginTop: 20 }}
          >
            <Menu.Item
              key="language:korean"
              onClick={() => setLanguage("KOREAN")}
            >
              한국어
            </Menu.Item>
            <Menu.Item
              key="language:english"
              onClick={() => setLanguage("ENGLISH")}
            >
              ENGLISH
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      </Drawer>
      <Row>
        <Col xs={24} lg={0} style={{ textAlign: "right" }}>
          <MenuOutlined
            style={{ fontSize: 18, color: `${grey[0]}` }}
            onClick={() => {
              setIsDrawerVisible(true);
            }}
          />
        </Col>
        <Col xs={0} lg={24}>
          <Menu
            className="test"
            mode="horizontal"
            defaultSelectedKeys={() => getCurrentKey()}
            selectable={false}
            style={{
              position: "absolute",
              float: "right",
              top: -50,
              right: 0,
              zIndex: 2,

              width: "100%",
              fontSize: 14,
              textAlign: "right",
              color: "#b6b6b6",
              borderWidth: 0,
              background: "transparent",
            }}
          >
            {
              /* 로그인을 했을 때만 활성화합니다. */
              token ? (
                <Menu.Item
                  key="logout"
                  style={{
                    height: 30,
                    lineHeight: "30px",
                    padding: 0,
                    paddingRight: 20,
                  }}
                  className="menu-disable-bottom-border"
                  onClick={() => {
                    history.push(PATH.ADMIN_LANDING);
                  }}
                >
                  {language === "ENGLISH" ? "ADMIN" : "관리 페이지"}
                </Menu.Item>
              ) : null
            }
            <Menu.Item
              key="youtube"
              style={{
                height: 30,
                lineHeight: "30px",
                padding: 0,
                paddingRight: 20,
              }}
              className="menu-disable-bottom-border"
            >
              <a
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UClW7dMDEY_eFUiLtwD-uZyg"
                style={{ color: "inherit" }}
                target="_blank"
              >
                {language === "ENGLISH" ? "YouTube" : "유튜브"}
              </a>
            </Menu.Item>

            <Menu.Item
              key="blog"
              style={{
                height: 30,
                lineHeight: "30px",
                padding: 0,
                paddingRight: 10,
              }}
              className="menu-disable-bottom-border"
            >
              <a
                rel="noopener noreferrer"
                href="https://blog.naver.com/cquest_official"
                style={{ color: "inherit" }}
                target="_blank"
              >
                {language === "ENGLISH" ? "BLOG" : "블로그"}
              </a>
            </Menu.Item>
            <Menu.Item
              key="prev-cquest"
              style={{
                height: 30,
                lineHeight: "30px",
                padding: 0,
                paddingLeft: 10,
              }}
              className="menu-disable-bottom-border"
            >
              <a
                rel="noopener noreferrer"
                href="http://cquest_src.sogang.ac.kr"
                style={{ color: "inherit" }}
                target="_blank"
              >
                {language === "ENGLISH" ? "PREVIOUS CQUeST" : "(구)홈페이지"}
              </a>
            </Menu.Item>
          </Menu>
          <Menu
            onClick={handleClick}
            mode="horizontal"
            defaultSelectedKeys={() => getCurrentKey()}
            selectable={false}
            style={{
              fontSize: 18,
              textAlign: "right",
              color: `${grey[0]}`,
              borderWidth: 0,
              background: "transparent",
            }}
          >
            <Menu.Item key="placeholder" disabled>
              <div style={{ width: 200 }} />
            </Menu.Item>
            <Menu.Item key="overview">
              <Link to={PATH.OVERVIEW1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "OVERVIEW" : "센터소개"}
              </Link>
            </Menu.Item>
            <Menu.Item key="members">
              <Link to={PATH.MEMBERS1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "MEMBERS" : "연구진소개"}
              </Link>
            </Menu.Item>

            <Menu.Item key="achievements">
              <Link to={PATH.ACHIEVEMENTS1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "ACHIEVEMENTS" : "연구업적"}
              </Link>
            </Menu.Item>
            <Menu.Item key="collaboration">
              <Link to={PATH.COLLABORATION1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "COLLABORATION" : "대외협력"}
              </Link>
            </Menu.Item>
            <Menu.Item key="forum">
              <Link to={PATH.FORUM1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "FORUM" : "센터활동"}
              </Link>
            </Menu.Item>
            <Menu.Item key="external">
              <Link to={PATH.EXTERNAL1} style={{ color: "inherit" }}>
                {language === "ENGLISH" ? "EXTERNAL" : "관련사이트"}
              </Link>
            </Menu.Item>
            <SubMenu
              key="SubMenu"
              icon={
                <img src={LanguagePNG} width="24" height="24" alt="language" />
              }
              style={{ fontSize: 14, verticalAlign: "bottom" }}
            >
              <Menu.Item key="language:korean">한국어</Menu.Item>
              <Menu.Item key="language:english">ENGLISH</Menu.Item>
            </SubMenu>
            {/* <SubMenu key="SubMenu" icon={<ExportOutlined />} title="LINKS">
          <Menu.Item key="link:blog" icon={<EditOutlined />}>
            Official Blog
          </Menu.Item>
          <Menu.Item key="link:previous" icon={<BranchesOutlined />}>
            Previous CQUeST
          </Menu.Item>
        </SubMenu> */}
          </Menu>
        </Col>
      </Row>
    </Layout.Header>
  );
});

export default Header;
