import { API_BASE_URL } from "app/config";
import React from "react";
import styled from "styled-components";
import { Upload, message, Input } from "antd";
import {
  DeleteOutlined,
  FileImageOutlined,
  LinkOutlined,
  LoadingOutlined,
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const ImageItem = ({ state, setState }) => {
  const onDelete = () => {
    setState("image", null);
  };
  return (
    <div
      style={{
        marginBottom: 50,
        display: "flex",
        alignItems: "flex-end",
        position: "relative",
      }}
    >
      <ImageUpload
        image={state.image}
        setImage={(props) => setState("image", props)}
      />
      <Input
        prefix={
          <LinkOutlined
            onClick={() => {}}
            style={{
              color: "#808080",
            }}
          />
        }
        value={state.url}
        onChange={(e) => setState("url", e.target.value)}
        style={{
          width: 300,
          position: "absolute",
          bottom: 20,
          left: 20,
          background: "#fff",
          opacity: 0.8,
        }}
      />
      {state.image && (
        <DeleteOutlined
          onClick={onDelete}
          style={{
            cursor: "pointer",
            color: "#b60005",
            fontSize: 16,
            paddingBottom: 10,
          }}
        />
      )}
    </div>
  );
};

const ImageUpload = ({ image, setImage }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setIsLoading(false);
      });
    }
  };

  return (
    <div>
      <div>
        <Upload
          name="avatar"
          listType="picture-card"
          className="home-image-slider-uploader"
          showUploadList={false}
          customRequest={fakeRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {image ? (
            <img src={`${image}`} alt="f" style={{ width: "100%" }} />
          ) : (
            <div>
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <PictureOutlined style={{ fontSize: 36, color: "#6fb9d6" }} />
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: "#6fb9d6",
                      fontWeight: "bold",
                    }}
                  >
                    이미지 추가
                  </div>
                </>
              )}
            </div>
          )}
        </Upload>
      </div>
    </div>
  );
};

/** Mock Requeset in order to bypass Antd Upload logic */
const fakeRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

/** Get Base64 of uploaded image file */
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/** Filter image file */
const beforeUpload = (file) => {
  const isJpgOrPngOrGif =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif";
  if (!isJpgOrPngOrGif) {
    message.error("JPG, PNG, 또는 GIF 파일만 업로드할 수 있습니다.");
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error("이미지 파일의 크기는 20MB 이내로 해주세요.");
  }
  return isJpgOrPngOrGif && isLt20M;
};

export default ImageItem;
