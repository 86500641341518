import React from "react";
import { Row, Col, Divider } from "antd";

import Forms from "./forms";
import ImageUpload from "./imageUpload";
import MemberCard from "components/member/cardList/memberCard";
import Description from "./description";

const MemberEditor = ({
  category,
  setCategory,
  image,
  setImage,
  priority,
  setPriority,
  koreanName,
  setKoreanName,
  englishName,
  setEnglishName,
  email,
  setEmail,
  koreanFieldsKey,
  setKoreanFieldsKey,
  englishFieldsKey,
  setEnglishFieldsKey,
  koreanFieldsValue,
  setKoreanFieldsValue,
  englishFieldsValue,
  setEnglishFieldsValue,
  initialKoreanFields,
  initialEnglishFields,
}) => {
  return (
    <div style={{ width: 900 }}>
      <Description />
      <Divider />
      <Row>
        <Col xs={8}>
          <ImageUpload image={image} setImage={setImage} />
        </Col>
        <Col xs={16}>
          <Forms
            category={category}
            setCategory={setCategory}
            koreanName={koreanName}
            setKoreanName={setKoreanName}
            englishName={englishName}
            setEnglishName={setEnglishName}
            priority={priority}
            setPriority={setPriority}
            email={email}
            setEmail={setEmail}
            koreanFieldsKey={koreanFieldsKey}
            setKoreanFieldsKey={setKoreanFieldsKey}
            koreanFieldsValue={koreanFieldsValue}
            setKoreanFieldsValue={setKoreanFieldsValue}
            englishFieldsKey={englishFieldsKey}
            setEnglishFieldsKey={setEnglishFieldsKey}
            englishFieldsValue={englishFieldsValue}
            setEnglishFieldsValue={setEnglishFieldsValue}
            initialKoreanFields={initialKoreanFields}
            initialEnglishFields={initialEnglishFields}
          />
        </Col>
      </Row>
      <Divider />
      <div style={{ height: 40 }} />
      <Row>
        <Col xs={24}>
          <MemberCard
            base64Image={image}
            name={koreanName}
            email={email}
            fields={mergeKeyValues(koreanFieldsKey, koreanFieldsValue)}
          />
          <MemberCard
            base64Image={image}
            name={englishName}
            email={email}
            fields={mergeKeyValues(englishFieldsKey, englishFieldsValue)}
          />
        </Col>
      </Row>
    </div>
  );
};

const mergeKeyValues = (keys, values) => {
  const fields = {};
  for (const keyItem of Object.keys(keys)) {
    fields[keys[keyItem]] = values[keyItem];
  }
  return fields;
};
export default MemberEditor;
