import { getRoot } from "mobx-state-tree";
import { REQUEST_STATUS, PAGE_STATE } from "app/constants";

/**
 * 단일 페이지 데이터를 가져온 후, 데이터를 저장하고 페이지 상태를 변경하는 함수입니다.
 */
const getSinglePage = async (action, singlePageID, setData, setPageState) => {
  // 1. 현 페이지의 language 값을 가져옵니다.
  const language = getRoot(action).language;

  // 2. request를 보내고, response에 따라 데이터를 저장하고 페이지 상태를 변경합니다.
  const response = await action.getSinglePage(singlePageID, language);
  if (response.status === REQUEST_STATUS.SUCCESS) {
    setData(response.data.data); // (추후 최적화 필요)
    setPageState(PAGE_STATE.NORMAL);
  } else {
    setPageState(PAGE_STATE.ERROR);
  }
};

const request = {
  getSinglePage,
};

export default request;
